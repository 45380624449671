import React, { Component } from "react";
// import App1Template from '../../templates/App1/App1Template';
import EditorSidebar from "../../components/EditorSidebar/EditorSidebar";
import colors from "../../colors";
import { Button, Modal, Popconfirm } from "antd";
import { ArrowLeftOutlined, BackwardFilled, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import {} from "redux/actions";
import { updateTemplateData } from '../../redux/actions/index';
import { SiteRenderer } from "mvptester-templates";
import { saveTemplateData } from "../../firebase/templateData";
import AddNewSectionModal from "../../components/modals/AddNewSectionModal/AddNewSectionModal";
import SaveButton from "./SaveButton";

class EditSitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      showSidebarContents: false,
      currentSectionName: "",
      currentSectionUid: ''
    };
  }


  toggleSidebar = () => {
    let current = this.state.sidebarOpen;
    this.setState({ sidebarOpen: !current });
    setTimeout(() => {
      this.setState({ showSidebarContents: !current });
    }, 100);
  };

  moveSection = (section, direction) => {
    const { templateData } = this.props;
    let newList = [...templateData.sections];
    let sectionIndex='none';
    newList.forEach((s, index) => {
      if(s.uid === section.uid) sectionIndex = index;
    })
    if(direction==='up'){
      if(sectionIndex !== 'none' && sectionIndex !== 0){
        newList.splice(sectionIndex-1, 0, newList.splice(sectionIndex, 1)[0]);
      }
    }else{
      if(sectionIndex !== 'none' && sectionIndex !== newList.length-1){
        newList.splice(sectionIndex+1, 0, newList.splice(sectionIndex, 1)[0]);
      }
    }
    this.props.updateTemplateData({
      ...templateData,
      sections: newList
    })
  }

  onEditSectionButtonPress = (section, action) => {
    const { templateData } = this.props;
    let newList = [...templateData.sections];

    if (action === "edit") {
      this.setState({ currentSectionName: section.name, currentSectionUid: section.uid });
      this.toggleSidebar();
    }
    if (action === "move_up") this.moveSection(section, 'up');
    if (action === "move_down") this.moveSection(section, 'down');
    if (action === "delete") {
      newList = newList.filter((s, index) => {
        return(s.uid !== section.uid);
      })
      this.props.updateTemplateData({
        ...templateData,
        sections: newList
      })
    }
    if (action === "insert_section_below") {
      this.setState({
        isAddSectionModalVisible: true,
        addSectionCallerUid: section.uid
      });
    }

    if (action === "paddingTopIncrease") {
      this.props.updateTemplateData({
        ...templateData,
        [section.uid]: {
          ...templateData[section.uid],
          paddingTop: templateData[section.uid].paddingTop ? templateData[section.uid].paddingTop+10 : 10
        }
      })
    }
    if (action === "paddingBottomIncrease") {
      this.props.updateTemplateData({
        ...templateData,
        [section.uid]: {
          ...templateData[section.uid],
          paddingBottom: templateData[section.uid].paddingBottom ? templateData[section.uid].paddingBottom+10 : 10
        }
      })
    }
    if (action === "paddingTopDecrease") {
      this.props.updateTemplateData({
        ...templateData,
        [section.uid]: {
          ...templateData[section.uid],
          paddingTop: templateData[section.uid].paddingTop ? templateData[section.uid].paddingTop-10 : 0
        }
      })
    }
    if (action === "paddingBottomDecrease") {
      this.props.updateTemplateData({
        ...templateData,
        [section.uid]: {
          ...templateData[section.uid],
          paddingBottom: templateData[section.uid].paddingBottom ? templateData[section.uid].paddingBottom-10 : 0
        }
      })
    }

    
  };

  onClickPageOverlay = () => {
    this.toggleSidebar();
  };

  onSave = () => {
    this.setState({ isSaving: true });
    saveTemplateData().then(done => {
      this.setState({ isSaving: false });
    });
  };

  onSaveAndBack = () => {
    this.setState({ isSavingAndBack: true });
    saveTemplateData().then(done => {
      this.setState({ isSavingAndBack: false });
      this.props.history.push(this.props.match.url.replace("/edit", ""));
    });
  }

  onCloseSectionModal = () => {
    this.setState({isAddSectionModalVisible: false});
  }

  render() {
    const { sidebarOpen, showSidebarContents, currentSectionName, isAddSectionModalVisible, addSectionCallerUid } = this.state;

    // console.log('tem data', this.props.templateData)
    return (
      <div style={styles.wrapper}>
        <AddNewSectionModal visible={isAddSectionModalVisible} onCancel={this.onCloseSectionModal} addSectionCallerUid={addSectionCallerUid} />
        {/* <div style={styles.contentsRow}> */}
          <div style={styles.left} class={sidebarOpen ? "sidebar" : "sidebar-closed"}>
            <div
              class={showSidebarContents ? "" : "hideme"}
              style={{ flex: 1, display: "flex", height: "100%", overflowY: "auto", overflowX: "hidden" }}
            >
              {showSidebarContents ? (
                <EditorSidebar
                  currentSectionName={currentSectionName}
                  currentSectionUid={this.state.currentSectionUid}
                />
              ) : null}
            </div>
          </div>
          <div class={sidebarOpen ? "right-side-small" : "right-side-big"} style={{ height: "100vh", overflow: "auto", position: 'relative' }}>
            <div style={styles.topBar}>
              <Button type='link' style={{marginRight: 10}} icon={<ArrowLeftOutlined />} onClick={this.onSaveAndBack} loading={this.state.isSavingAndBack}>Save & Back</Button>
              <SaveButton onClick={this.onSave} loading={this.state.isSaving} />
              <Button icon={<SettingOutlined />} onClick={() => this.onEditSectionButtonPress({name: 'siteInfo'}, "edit")} style={{marginLeft: 20}}>General Site Settings</Button>
            </div>
            <div style={styles.siteWrapper}>
              <SiteRenderer onEditSectionButtonPress={this.onEditSectionButtonPress} templateData={this.props.templateData} siteData={this.props.siteData} />
            </div>
            {sidebarOpen ? <div style={styles.pageOverlay} onClick={this.onClickPageOverlay} /> : null}
          </div>
        {/* </div> */}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    backgroundColor: 'black'
    // overflow: 'hidden'
  },
  // contentsRow: {
  //   display: "flex",
  //   flexDirection: "row",
  //   flex: 1,
  //   overflow: "auto",
  //   // position: 'relative',
  //   // height: '100%',
  //   zIndex: 3
  // },
  left: {
    display: "flex",
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderRightColor: "#ddd",
    // position: 'relative',
    height: "100%"

    // position: 'fixed'
  },
  right: {
    flex: 0.7,
    height: "100%",
    display: 'flex',
    // overflow: 'auto',
    // position: 'relative'
  },
  siteWrapper: {
    position: "relative",
    margin: 25,
    marginTop: 60,
    borderRadius: 5,
    boxShadow: "0 0 12px 8px rgba(0,0,0,.1)",
    border: '1px solid grey',
    overflow: "auto",
    height: "88%",
    boxSizing: 'border-box',
    backgroundColor: '#fff'
    // flex: 1,
  },
  pageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.5)",
    cursor: "pointer",
    zIndex: 53
  },
  topBar: {
    position: 'absolute',
    top: 0,
    width: "100%",
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData,
  siteData: state.siteData,
  editsPending: state.editsPending
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(mapStateToProps, mapActionsToProps)(EditSitePage);
