import React, { Component } from 'react';
import colors from '../../colors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

let types = [
  {
    label: 'In Progress',
    value: 'in_progress'
  },
  {
    label: 'Complete',
    value: 'complete'
  },
]

class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelect = route => {
    this.props.history.push(route)
  }

  render() {
    const { onSelect, selected } = this.props;
    return(
      <div style={styles.wrapper}>
        <small style={styles.adminTitle}>{this.props.config.data.siteNameDis} ADMIN</small>
        <div style={styles.pillWrapper}>
          <NavItem onClick={() => this.onSelect('/admin/orders/in_progress')} selected={selected==='in_progress'} label={'In Progress'} />
          <NavItem onClick={() => this.onSelect('/admin/orders/complete')} selected={selected==='complete'} label={'Complete'} />
        </div>
        <div style={{height: 7}}></div>
        <div style={styles.pillWrapper}>
          <NavItem onClick={() => this.onSelect('/admin/designers')} selected={selected==='designers'} label={'Designers'} />
        </div>
      </div>
    );
  }
}


const NavItem = ({selected, label, onClick}) => {
  return(
  <span style={selected ? styles.selectedItem : styles.navItem} onClick={onClick}>{label}</span>
  );
}

const navItemStyle = {
  cursor: 'pointer',
  marginRight: 10,
  padding: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 3,
  color: '#fff',
  fontWeight: 'bold',
  borderWidth: 1,
  borderColor: 'transparent'
};

const styles = {
  adminTitle: {
    color: '#fff',
    fontSize: 12,
    position: 'absolute',
    top: 2
  },
  wrapper: {
    backgroundColor: '#1890FF',
    padding: 15,
    paddingTop: 25,
    position: 'relative'
  },
  selectedItem: {
    ...navItemStyle,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  navItem: {...navItemStyle},
  pillWrapper: {
    display: 'flex',
    flexDirection: 'row',
  }
};
const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(AdminNavbar));