import React, { Component } from "react";
import colors from "../../colors";
import { Menu, Dropdown, Breadcrumb, Button } from "antd";
import { DownOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import Navbar from "components/Navbar";
import firebase from "firebase";
import { connect } from "react-redux";
import { currentUserReducer } from "../../redux/reducers";
// import { update } from 'redux/actions';
import webpageImg from "assets/img/webpage2.png";
import Pill from "./Pill";
import linkToSubdomain from "../../helpers/linkToSubdomain";

const navItems = [
  {
    label: "All Sites",
    url: "/mysites",
  },
];

class DashboardNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editSiteClick = () => {
    this.props.history.push(this.props.match.url + "/edit");
  };

  onClickOrders = () => {
    linkToSubdomain('orders')
  }

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //
      });
  };

  nav = (url) => {
    this.props.history.push(url);
  };

  onClickSignup = () => {
    window.location = this.props.config.data.stripePaymentLink;
  }

  render() {
    const { email } = this.props.loggedInUser;
    const { selectedSection, onSelectSection, templateData, membershipInfo } = this.props;
    const { isMembershipActive, canSignup } = membershipInfo;

    const logoutMenu = (
      <Menu >
        <div style={{padding: 15, paddingTop: 4, opacity: .6, fontSize: 12}}>{this.props.loggedInUser.email}</div>
        <Menu.Item>
          <a onClick={this.logout}>Log out</a>
        </Menu.Item>
      </Menu>
    );
    const showSiteTabs = this.props.match.url.includes("/site/");
    const siteNameStyle = {
      fontWeight: "700",
      fontFamily: "Montserrat",
      fontSize: 20,
    };
    return (
      <div style={styles.wrapper}>
        <div style={styles.top}>
          <div style={{ flex: 0.4 }} />
          <div style={{ flex: 0.2, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <img src={this.props.config.data.headerLogoUrl} style={styles.logo} />
          </div>
          <div style={styles.topRight}>
            {navItems.map((item) => {
              return (
                <span style={styles.topNavItem} onClick={item.url ? () => this.nav(item.url) : item.onClick}>
                  {item.label}
                </span>
              );
            })}
                <span style={styles.topNavItem} onClick={this.onClickOrders}>
                  Orders
                </span>

            <Dropdown overlay={logoutMenu}>
              <span style={styles.topNavItem}>
                
                <MoreOutlined style={{margin: 5}}/>
              </span>
            </Dropdown>
            {canSignup ? 
            <Button type='primary' onClick={this.onClickSignup}>Sign Up!</Button>
            :null}

          </div>
        </div>
        {showSiteTabs ? (
          <div style={styles.bottom}>

            <div style={styles.bottom1}>
              <div style={styles.siteNameRow}>
                {/* <img src={(this.props.siteData.data || {}).faviconUrl || webpageImg} style={styles.siteIcon} /> */}
                <div>
                  <span style={siteNameStyle}>{this.props.siteData.siteName || "New Site"}</span>
                  {this.props.siteData.usesCustomDomainName ? null : <div style={styles.siteLink}>{`https://${this.props.siteData.uniqueSiteName || "yoursite"}.${this.props.config.data.siteNameValue}.com`}</div>}
                </div>

              </div>
            </div>

            <div style={styles.bottom2}>
              <Button type='primary' icon={<EditOutlined />} size='small' onClick={this.editSiteClick} style={{ marginRight: 12 }}>
                Edit Site
              </Button>
              <Pill label="General" onClick={() => onSelectSection("general")} isSelected={selectedSection === "general"} />
              <Pill label="Domain" onClick={() => onSelectSection("domain")} isSelected={selectedSection === "domain"} />
              {/* <Pill label="Analytics" onClick={() => onSelectSection("analytics")} isSelected={selectedSection === "analytics"} /> */}
              <Pill label="Media" onClick={() => onSelectSection("media")} isSelected={selectedSection === "media"} width={60}/>
              {/* <Pill label="Emails" onClick={() => onSelectSection("emails")} isSelected={selectedSection === "emails"} /> */}
              <Pill label="Settings" onClick={() => onSelectSection("settings")} isSelected={selectedSection === "settings"} />
              <Pill label="Support" onClick={() => onSelectSection("support")} isSelected={selectedSection === "support"} />
            </div>

          </div>
        ) : null}
        {/* {isMembershipActive ? null : <div style={styles.trialText}>{this.props.membershipInfo.timeLeftText} left on trial</div>} */}
      </div>
    );
  }
}


const styles = {
  wrapper: {
    backgroundColor: "#fff",
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: colors.boxShadow,
    // backgroundColor: colors.lightBG,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: colors.border,
    position: 'relative'
  },
  top: {
    height: 60,
    // marginBottom: 10,
    // backgroundColor: '#f5f5f5',
    width: "100%",
    // boxShadow: '0 0 2px 2px rgba(0,0,0,.05)',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 50,
  },
  bottom: {
    marginTop: -30,
  },
  logo: {
    height: 30,
    marginTop: -10,
    // marginTop: -10
  },
  topRight: {
    flex: 0.4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  topNavItem: {
    marginRight: 30,
    cursor: "pointer",
    zIndex: 7
  },


  bottom1: {
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
  },
  bottom2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 6,
    marginBottom: 10,
    // marginLeft: -4
  },
  siteNameRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
  },
  editLink: {
    marginLeft: 12,
    color: colors.main,
    cursor: "pointer",
  },
  siteIcon: {
    height: 32,
    width: 32,
    marginRight: 10,
    boxShadow: '0 0 9px 3px rgba(0,0,0,.07)',
  },
  trialText: {
    color: colors.lightText,
    position: 'absolute',
    bottom: 5,
    right: 15,
    fontSize: 12
  },
  siteLink: {
    fontSize: 12,
    // opacity: '.7',
    color: colors.lightText,
    marginTop: -6

  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  currentUser: state.currentUser,
  siteData: state.siteData,
  templateData: state.templateData,
  config: state.config,
  membershipInfo: state.membershipInfo
});
const mapActionsToProps = {};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(DashboardNavbar));
