export default {
  info: 'You will recieve an email alert when this form is submitted, and an order will appear under your "quotes"',
  editorInputs: [
    {
      label: "Dark Mode",
      type: "bool",
      dataKey: "darkMode",
    },
    {
      label: "Title",
      type: "text",
      dataKey: "title",
    },
    {
      label: "Description",
      type: "text",
      dataKey: "desc",
      inputProps: {
        multiline: true,
        rows: 3,
      },
    },
    {
      label: "Show address input?",
      type: "bool",
      dataKey: "askForAddress",
    },
    {
      label: "Submit Button Description",
      type: "text",
      dataKey: "submitNote",
    },
    {
      label: 'Placeholder for "Additional Notes" section',
      type: "text",
      dataKey: "additionalNotesPlaceholder",
    },
  ],
};
