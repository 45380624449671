import React from 'react';
import { Input } from 'antd';

const Section = ({label, value, onChangeText, inputStyle, width, multiline, children}) => {
  const styles = {
    wrapper: {
      width: width || '100%'

    },
    label: {
  
    },
    input: {

    }
  };
  const inputContent = (
    multiline ? 
      <Input.TextArea  value={value} onChange={e => onChangeText(e.target.value)} rows={2} />
      :
      <Input size='small' style={styles.input} value={value} onChange={e => onChangeText(e.target.value)} />
  )
  return(
    <div style={styles.wrapper}>
      <div style={styles.label}>{label}</div>
      {children ? children : inputContent}
    </div>
  );
}


export default Section;