import React, { Component } from 'react';

class OtherDomainDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper}>
        other asdasd
      </div>
    );
  }
}

const styles = {

};

export default OtherDomainDirections;