export default {
  // title: 'You will recieve an email when this form is submitted',
 editorInputs: [
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Youtube Video ID",
      type: "string",
      dataKey: "videoId",
      inputProps: {
        placeholder: 'wgH6reUEMpf'
      },
      info: 'This can be copy/pasted from the end of the youtube URL, the random charecters that look something like "laW8rtUEMyc" - Just copy everything that comes after "https://www.youtube.com/watch?v="'
    },
  ],
};
