import store from '../redux/store';
import { db, firebase } from './index';
import * as actions from '../redux/actions';
import { v4 as uuid } from 'uuid';
import * as types from '../redux/types';
import imageCompression from 'browser-image-compression';

export const addMediaItem = function(file, opts={}){
  const { path, imgType, name } = opts;
  return new Promise(async (resolve, reject) => {
    let state = store.getState();
    const randomImageName = uuid();

    const compressOptions = {
      maxSizeMB: .7,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    const compressedFile = await imageCompression(file, compressOptions);


    // indicate image uploading

    var storageRef = firebase.storage().ref();
    let imagePath = `${path || 'general/'}${randomImageName}`;
    var fileRef = storageRef.child(imagePath);
    fileRef.put(compressedFile).then(function(snapshot) {
      console.log(snapshot)
      // done uploading!
      let encodedImagePath = encodeURIComponent(imagePath);
      const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/mvptester-84a0c.appspot.com/o/';
      let newItem = {
        url: `${baseUrl}${encodedImagePath}?alt=media`,
        path: imagePath,
        id: randomImageName,
        createdAt: Date.now()
      };
      if(opts.isFavicon) newItem.isFavicon = true;
      if(name) newItem.name = name;
      if(imgType) newItem.imgType = imgType;

      // add to firestore media

      let newMedia = [newItem, ...state.media];
      db.collection('media').doc().set({
        ...newItem,
        userId: state.currentUser.id
      })

      // add to redux media
      store.dispatch({
        type: types.UPDATE_MEDIA_ITEMS,
        payload: newMedia
      })
      resolve(newItem)
    });

  })
}

export const deleteMediaItem = async function (path) {
  let state = store.getState();
  var storageRef = firebase.storage().ref();
  let newMedia = [...state.media];
  newMedia = newMedia.filter((x) => x.path !== path);
  store.dispatch({
    type: types.UPDATE_MEDIA_ITEMS,
    payload: newMedia,
  });
  let mediaDocId = undefined;
  let files = await db.collection("media").where("path", "==", path).get();
  files.forEach((f) => (mediaDocId = f.id));
  if (mediaDocId) {
    db.collection('media').doc(mediaDocId).delete().then(() => {
      // media record deleted!
      var fileRef = storageRef.child(path);
      fileRef.delete().then(function () {
          // File deleted successfully
      })
      .catch(function (error) {
        // error, file  not deleted
      });
    })
    .catch((err) => {
      // media record not deleted
    });
  }
};


export const loadMyMedia = function(){
  return new Promise((resolve, reject) => {
    let state = store.getState();
    if(state.loggedInUser.loggedIn){


      db.collection("media").where('userId', '==', state.currentUser.id).get().then(docRefs => {
        let newMediaItems = []
        docRefs.forEach(doc => {
          let data = doc.data();
          newMediaItems.push(data);
        })
        newMediaItems = newMediaItems.sort((a, b) => {
          let a_val = a.createdAt || 0;
          let b_val = b.createdAt || 0;
          return a_val > b_val ? -1 : 1;
        })
        store.dispatch({
          type: types.UPDATE_MEDIA_ITEMS,
          payload: newMediaItems
        })

      })

      // db.collection('media').doc(state.currentUser.id).get().then(docRef => {
      //   let media = docRef.data().media;
      //   // console.log('got media', media)
      //   store.dispatch({
      //     type: types.UPDATE_MEDIA_ITEMS,
      //     payload: media
      //   })

      //   // dispatch media to redux
      // })
  
    }
  })
}
