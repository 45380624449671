import React, { Component } from "react";
import { Button } from "antd";
import colors from "../colors";

class SaveBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onSave, loading, title, disabled } = this.props;
    return (
      <div style={styles.wrapper}>
        <div style={styles.title}>{title}</div>
        <Button
          type="primary"
          style={styles.button}
          onClick={onSave}
          loading={loading}
          disabled={disabled}
          size='large'
        >
          Save
        </Button>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: 15,
    // backgroundColor: colors.lightBG,
    borderRadius: 3
  },
  button: {
    width: 100
  },
  title: {
    fontSize: 24,
  }
};

export default SaveBar;
