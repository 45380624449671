import React, { Component } from 'react';
import { Slider } from 'antd';
import colors from 'colors';

class NumberSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, onChange, label, inputProps={} } = this.props;
    return(
      <div style={styles.sliderRow}>
      <div style={styles.label}>{label || ''}</div>
      <div style={styles.sliderWrapper}>
        <Slider {...inputProps} defaultValue={50} min={inputProps.min || 0} max={inputProps.max || 100} value={value} onChange={onChange} tooltipVisible={false} />
      </div>
    </div>
    );
  }
}

const styles = {
  sliderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sliderWrapper: {
    flex: 1
  },
  label: {
    fontSize: 14,
    color: colors.lightText,
    marginRight: 15
  },
};

export default NumberSlider;