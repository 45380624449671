import React, { Component } from "react";
import { connect } from "react-redux";
import InputText from "../../InputText";
import ColorPicker from "../../ColorPicker";
import colors from "../../../colors";
import EditSectionClass from "./shared/EditSectionClass";
import LogoSection from "./LogoSection";
import TopBanner from "./shared/TopBanner";
import EditorInputSection from "../../input_sections/EditorInputSection";

class SiteInfo extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeColor = e => {
    let colorVal = e.target.value;
    this.updateTemplateData({
      ...this.props.templateData,
      styles: {
        ...this.props.templateData.styles,
        mainColor: colorVal
      }
    });
  };

  onChangeUserEmail = e => {
    this.updateTemplateData({
      ...this.props.templateData,
      userEmail: e.target.value
    });
  }

  onChangeDocumentTitle = e => {
    this.updateTemplateData({
      ...this.props.templateData,
      documentTitle: e.target.value
    });
  }

  render() {
    const { templateData } = this.props;
    return (
      <div style={styles.wrapper}>
        <TopBanner title='Site Info' />

        <div style={{ height: 20 }} />
        <ColorPicker value={templateData.styles.mainColor} onChange={this.onChangeColor} label='Main Color' />
        <div style={{ height: 20 }} />
        {/* <InputText
          label={"Your Email"}
          inputProps={{
            onChange: this.onChangeUserEmail,
            value: templateData.userEmail,
            type: 'email',
            placeholder: 'name@email.com'
          }}
        />
        <small style={{color: colors.lightText}}>So we can save your progress!</small> */}
                         <EditorInputSection compact={false} label={"Page Title (appears in the browser tab)"} info={'The text you see in the browser tab at the top of your computer screen'}>
                <InputText
                  // label={label}
                  inputProps={{
                    value: templateData.documentTitle,
                    type: '',
                    placeholder: '',
                    onChange: this.onChangeDocumentTitle,
                  }}
                />
              </EditorInputSection>
        <div style={{height: 10}}></div>
        <LogoSection />

      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex: 1
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(SiteInfo);
