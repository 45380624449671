export default {
  editorInputs: [
    {
      label: 'Dark Mode',
      dataKey: 'darkMode',
      type: 'bool'
    },
    {
      label: "",
      dataKey: "items",
      type: "items",
      header: "",
      items: [
        {
          label: "Text",
          dataKey: "text",
          type: "text",
        },
        {
          label: "Details Shown on Hover",
          dataKey: "hoverText",
          type: "text",
          inputProps: {
            multiline: true,
            rows: 2,
          },
        },
        {
          label: "Image",
          type: "image",
          dataKey: "imageUrl",
          inputProps: {
            compact: true,
          },
        },
      ],
    },
  ],
};
