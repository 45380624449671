import React, { Component } from 'react';
import colors from '../colors';
import { connect } from 'react-redux';
import { Button } from 'antd';
import firebase from "firebase";
import { updateMembershipInfo } from 'redux/actions/dispatchers';
import { isMobile } from 'react-device-detect';

class SignupBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignupBanner: false,
      daysLeft: 0,
      hoursLeft: 0,
      visible: false
    };
  }

  componentDidMount(){
    this.loadTrialDays();

  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.currentUser.id !== this.props.currentUser.id){
      this.loadTrialDays()
    }
  }

  loadTrialDays = () => {
    updateMembershipInfo()
    setTimeout(() => this.setState({visible: true}), 3000);
    
  }


  onClose = () => {
    this.setState({visible: false})
    setTimeout(() => {
      this.setState({showSignupBanner: false})
    }, 1000);
  }

  onClickSignup = () => {
    let url = this.props.config.data.stripePaymentLink;
    let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
    if(isLocalDev) url = 'https://buy.stripe.com/test_28o28y2hS2Pzc4E9AA';
    window.location = url;
  }

  onLogout = () => {
  
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //
      });
   
  }

  render() {
    const {  visible } = this.state;
    const { daysLeft, hoursLeft, showSignupBanner, isMembershipActive, subscriptionHasBeenCancelled, isTrial, accessHasExpired} = this.props.membershipInfo;
    if(!showSignupBanner) return null;
    if(isMembershipActive && !subscriptionHasBeenCancelled && !isTrial) return null;
    if(!this.props.currentUser.id) return null;

    const styles = {
      wrapper: {
        position: 'fixed',
        padding: 20,
        backgroundColor: colors.main,
        top: visible ? 0 : -200,
        transition: '.3s',
        // height: 150,
        width: '100vw',
        boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
        zIndex: 99999,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      text: {
        color: '#fff',
        fontWeight: 'bold',
        marginRight: 15,
        textAlign: 'center',
        flex :1
      },
      right: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: isMobile ? 10 : 0
      },
      left: {
        flex: isMobile ? undefined : 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      trialExpiredWrapper: {
        position: 'absolute',
        backgroundColor: 'white',
        height: '100vh',
        width: '100vw',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 999999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
      expiredCard: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
        borderRadius: 3,
        width: isMobile ? '90%' : 450
      },
      trialExpiredTitle: {
        fontSize: 20,
        // textAlign: 'center'
      },
      message: {
        // textAlign: 'center',
        marginBottom: 15
      },
      logout: {
        cursor: 'pointer',
        color: colors.lightText,
        position: 'absolute',
        top: 15,
        right: 15
      },
      later: {
        padding: 10,
        cursor: 'pointer',
        color: '#fff',
        opacity: .7
      }
    };

    const timeRemaining = daysLeft < 1 ? `${hoursLeft} hours ` : `${daysLeft} day${daysLeft > 1 ? 's' : ''}`;

    if(accessHasExpired){
      return(
        <div style={styles.trialExpiredWrapper}>
          <div style={styles.logout} onClick={this.onLogout}>
            log out
          </div>
          <div style={styles.expiredCard}>
            <div style={styles.trialExpiredTitle}>
              Your {this.props.config.data.siteNameDis} {isTrial ? 'free trial' : 'subscription'} has expired!
            </div>
            <div style={styles.message}>Sign up for a subscription now to continue using {this.props.config.data.siteNameDis}</div>
              <Button type='primary' onClick={this.onClickSignup}>Sign Up Now</Button>
            
          </div>
        </div>
      )
    }

    return(
      <div style={styles.wrapper}>
        <div style={styles.left}>
    
          <div style={styles.text}>You have {timeRemaining} remaining on your {isTrial ? 'free trial' : 'subscription'}. Sign up now to avoid any down time!</div>

        </div>
        <div style={styles.right}>
          <Button onClick={this.onClickSignup} >Sign Up!</Button>
          <div style={{width: 15}} />
          <div style={styles.later} onClick={this.onClose}>later</div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  currentUser: state.currentUser,
  config: state.config,
  membershipInfo: state.membershipInfo
});
const mapActionsToProps = {

};
export default connect(mapStateToProps, mapActionsToProps)(SignupBanner);