import { WindowsFilled } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class NoRouteFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return(
      <div style={styles.wrapper}>
        <div style={styles.message}>Page Not Found</div>
        <div style={styles.subtitle}>Please check and confirm the page URL is correct, or <a href={window.location.origin}>click here</a> to go back to the main page.</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 150

  },
  message: {
    fontSize: 20
  }
};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(NoRouteFound);