import React from 'react';

const PageContentContainer = props => {
  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      overflowY: 'auto'
      // alignItems: 'center',
    },
    inside: {
      width: '100%',
      maxWidth: props.maxWidth || 1000,
      padding: 20,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxHeight: '100%'
      // backgroundColor: 'red',
    }
  };
  return(
    <div style={styles.wrapper}>
      <div style={styles.inside}>{props.children}</div>
    </div>
  );
}



export default PageContentContainer;