import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "redux/actions";
import MediaScroll from "../../../components/media/MediaScroll";
import colors from "../../../colors";
import { Button, Popconfirm, Popover, Tooltip } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import PageContentContainer from "../../../components/containers/PageContentContainer";
import { firebase, db } from "../../../firebase";
import { addMediaItem, deleteMediaItem } from "../../../firebase/media";
import MediaTypeDropdown from "../../../components/media/MediaTypeDropdown";

const defaultImageUrl = require("assets/img/default.jpg");

class MediaTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMedia: {},
      selectedIndex: 0
    };
  }

  componentDidMount() {}

  onSelectImage = index => {
    this.setState({ selectedIndex: index });
  };

  onAddMedia = () => {
    this.refs.fileUploader.click();
  };

  onFileChanged = data => {
    let file = data.target.files[0];
    this.setState({isAddingMedia: true});
    addMediaItem(file, {}).then(done => {
      this.setState({isAddingMedia: false})
    })
  };

  onChangeMediaType = data => {};

  delete = () => {
    let image = this.props.media[this.state.selectedIndex];
    if(image){
      deleteMediaItem(image.path);
    }
  }

  // download = async () => {
  //   let image = this.props.media[this.state.selectedIndex];


  //   if(image){
  //     var storageRef = firebase.storage().ref();

  //     var imgRef = storageRef.child(image.path);


  //     imgRef.getDownloadURL().then(function(url) {
  //       fetch(url).then(res => {
  //         let filename = 'abns.jpg'
        
  //         var blob = new Blob([res], { type: 'image/jpg' });
  //         var blobUrl = URL.createObjectURL(blob);
  //         var downloadLink = document.createElement("a");
  //         downloadLink.href = blobUrl;
  //         downloadLink.download = filename;
  //         document.body.appendChild(downloadLink);
  //         downloadLink.click();
  //         document.body.removeChild(downloadLink);
  //       })
  //     }).catch(function(error) {


  //     });





  //   }
  // }

  render() {
    const { selectedIndex } = this.state;


    let selectedMediaItem = this.props.media[selectedIndex] || {};
    const deleteConfirmTitle = <div style={{maxWidth: 300}}>Are you sure you want to delete this image? It will be removed from any websites its on.</div>

    return (
      <PageContentContainer maxWidth={1200}>
        <input
          type="file"
          id="file"
          ref="fileUploader"
          style={{ display: "none" }}
          onChange={this.onFileChanged}
        />
        <div style={styles.wrapper}>
          <div style={styles.left}>
            <MediaScroll
              onSelect={this.onSelectImage}
              selectedIndex={selectedIndex}
              images={this.props.media}
              onAdd={this.onAddMedia}
              isAdding={this.state.isAddingMedia}
            />
          </div>
          <div style={styles.right}>
            <div style={styles.rightImageContainer}>
              <img src={selectedMediaItem.url} style={styles.mainImage} />
            </div>
            <div style={styles.rightDataContainer}>
              {/* <Button icon='download' onClick={this.download}>Download Image</Button> */}

              {/* <div style={styles.itemData}>
                <span style={{ marginRight: 10 }}>Type:</span>
                <MediaTypeDropdown
                  onChange={this.onChangeMediaType}
                  value={selectedMediaItem.imgType}
                />
              </div> */}
              <Popconfirm title={deleteConfirmTitle} placement='bottom' onConfirm={this.delete} okText='DELETE' okButtonProps={{type: 'danger'}}>
                <Button type="danger">Delete Image</Button>
              </Popconfirm>
              <div style={{height: 15}} />
              <Tooltip title='Right click the image, and select "Save Image As..."' placement='bottom'>
                <Button icon={<DownloadOutlined />} onClick={this.download}>Download</Button>
              </Tooltip>

              {/* <TextArea
                rows={4}
                style={styles.notes}
                value={selectedMediaItem.description}
              />
              <div style={styles.saveButtonRow}>
                <Button type="primary">Save</Button>
              </div> */}
            </div>
          </div>
        </div>
      </PageContentContainer>
    );
  }
}

const styles = {
  rightImageContainer: {},
  mainImage: {
    // maxWidth: 300,
    // flex: 1,
    // maxWidth: "100%",
    // maxWidth: 300,
    minWidth: 60,
    maxWidth: 400,
    border: `1px solid ${colors.border}`
  },
  left: {},
  right: {
    display: "flex",
    flexDirection: "row"
  },
  rightImageContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    position: "relative",
    flex: 1,
    width: 420,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  rightDataContainer: {
    paddingLeft: 10,
    minWidth: 250,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: "flex",
    flexDirection: "row"
  },
  saveButtonRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 20
  },
  itemData: {
    marginBottom: 15
  },
  notes: {
    width: "100%"
  }
};

const mapStateToProps = state => ({
  media: state.media
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(MediaTab);
