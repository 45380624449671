import React, { Component } from 'react';
import thumbnails from './thumbnails';
let defaultImage = require('../../../assets/img/sections/default.png');

class SectionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let item = this.props.item;
    let imgSrc = thumbnails[item.name];
    if(!imgSrc) imgSrc = defaultImage

    return(
      <div style={styles.wrapper} className='pageSectionCard' onClick={this.props.onClick}>
        <img src={imgSrc} style={styles.img} />
        <div style={styles.bottom}>
          <div style={styles.label}>{item.label}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    borderRadius: 4,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '31%',
    cursor: 'pointer',
    marginRight: '1.9%'

  },
  bottom: {
    padding: 10
  },
  img: {
    width: '100%',
    height: 130,
    objectFit: 'contain'
  },
  label: {
    fontWeight: 'bold'
  }
};

export default SectionCard;