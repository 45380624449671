import { Checkbox, Input, Popconfirm } from 'antd';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import colors from '../../../../colors';
import Section from './Section';
import dragIcon from '../../../../assets/img/drag.png';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

class ItemBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangePrice = (id, val) => {
    if(/^[$£€]{0,1}[0-9]*\.?[0-9]{0,2}$/.test(val) || !val){
      this.props.commonProps.onChange(id, 'price', val)
    }
  }

  render() {
    const { item, commonProps, dragHandleProps, itemSelected  } = this.props;
    const { isEdit, onChange, onDelete, hideHasBeenInvoiced } = commonProps;

    let wrapperStyle = {
      // marginBottom: 30,
      borderRadius: 3,
      backgroundColor: '#fff',
      // boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
      padding: 10,
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      backgroundColor: '#fff',
      borderTop: itemSelected ? '2px solid '+colors.main : '2px solid transparent',
      borderBottom: itemSelected ? '2px solid '+colors.main : '2px solid transparent',
      borderRight: itemSelected ? '2px solid '+colors.main : '2px solid transparent',
      borderLeft: `2px solid ${colors.main}`,
      boxShadow:  '0 0 9px 3px rgba(0,0,0,.05)' ,
    };

    if(isEdit){
      return(
        <div style={wrapperStyle} class='disableHighlight'>
          <div style={styles.left}>
            <Section value={item.title} label='Title' onChangeText={x => onChange(item.id || item.serviceTypeKey, 'title', x)} width={isMobile ? '100%' : '77%'} />
            <Section value={item.price} label='Price' onChangeText={x => this.onChangePrice(item.id || item.serviceTypeKey, x)} width={isMobile ? '100%' : '20%'} />
            <Section value={item.desc} label='Description' multiline={true} onChangeText={x => onChange(item.id || item.serviceTypeKey, 'desc', x)} />
            {hideHasBeenInvoiced ? null : 
            <div style={styles.hasBeenInvoicedWrapper}>
              Has been invoiced? 
              <Checkbox checked={item.hasBeenInvoiced} onChange={e => onChange(item.id || item.serviceTypeKey, 'hasBeenInvoiced', e.target.checked)} style={{marginLeft: 5}}/>
            </div>
            }
          </div>
          <div style={styles.right}>
            <div style={styles.dragHandle} {...dragHandleProps} class='disableHighlight'>
              <div style={styles.dragCover} class='disableHighlight'></div>
              <img src={dragIcon} style={styles.dragIcon} />
            </div>
            <div style={styles.trash}>
            <Popconfirm title={'Are you sure you want to delete this item?'} placement='left' onConfirm={() => onDelete(item.id || item.serviceTypeKey)} okText='DELETE' okButtonProps={{type: 'danger'}}>
              <DeleteOutlined style={{fontSize: 20, margin: 5}} />
            </Popconfirm>
            </div>
          </div>
        </div>
      );
    }else{
      return(
        <div style={styles.readonlyWrapper}>
          <div style={styles.readonlyLeft}>
          <div style={styles.readonlyTitle}>{item.title}</div>
          <div style={styles.readonlyDesc}>{item.desc}</div>

          </div>
          <div style={styles.readonlyRight}>
          <div style={styles.readonlyPrice}>{item.price}</div>
          </div>
        </div>
      )
    }

  }
}

const styles = {

  left: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragIcon: {
    height: 30,
  },
  trash: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
    // backgroundColor: 'red',
  },
  dragHandle: {
    paddingLeft: 20,
    paddingRight: 10,
    cursor: 'move',
    display: 'flex',
    flex: 1,
    // alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    position: 'relative'
  },
  dragCover: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    // backgroundColor: 'red',
  },
  hasBeenInvoicedWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  
  // --- styles for non-editable item ---
  readonlyWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    borderBottomStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 15,
    paddingLeft: 0,
    paddingTop: 5,
    // marginBottom: 15
  },
  readonlyLeft: {
    flex: 1
  },
  readonlyRight: {
    paddingLeft: 20
  },
  readonlyTitle: {
    fontWeight: 'bold'
  },
  readonlyDesc: {
    fontSize: 12,
    color: colors.lightText
  },
  readonlyPrice: {
    fontWeight: 'bold'
  }

};

export default ItemBox;