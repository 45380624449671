import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "redux/actions";
import { Button, Switch, Modal, Alert, Input } from "antd";
import PageContentContainer from "../../../components/containers/PageContentContainer";
import DashboardTabClass from "../DashboardTabClass";
import { updateSiteData, updateCurrentUser } from "redux/actions";
import colors from "../../../colors";
import { db } from '../../../firebase';
import DashboardInputSection from "../../../components/input_sections/DashboardInputSection";
import { updateMembershipInfo } from "../../../redux/actions/dispatchers";

class SettingsTab extends DashboardTabClass {
  constructor(props) {
    super(props);
    this.state = {
      confirmText: ''
    };
  }

  handleDeleteButtonClick = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  onActiveChange = val => {
    if (val) {
      // mark site active
      this.onChangeSiteDataItem('siteDisabled', false)
    } else {
      // disable site
      this.onChangeSiteDataItem('siteDisabled', true)
    }
    setTimeout(() => {
      this.save()
    }, 100);
  };

  handleCancelModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleCancelSubscriptionButtonClick = () => {
    this.setState({isCancelModalVisible: true})
  }

  confirmDelete = () => {
    this.setState({isDeleting: true})
    db.collection('sites').doc(this.props.siteData.id).delete().then(() => {
      this.setState({isDeleting: false})
      this.props.history.push('/mysites');
    })
  };

  confirmCancel = async () => {
    this.setState({isCancelling: true})
    global.firebaseApi('/cancelSubscription').then(async res => {
      this.handleCloseSubscriptionModal();
      alert("Subscription cancelled. Your access will end on "+res.endsAtStr);
      let newUser = (await db.collection("users").doc(this.props.currentUser.id).get()).data();
      this.props.updateCurrentUser(newUser);
      this.setState({isCancelling: false})
      updateMembershipInfo()
    })
  };

  handleCloseSubscriptionModal = () => {
    this.setState({isCancelModalVisible: false})
  }

  render() {
    const { siteDisabled } = this.props.siteData;

    return (
      <PageContentContainer maxWidth={700}>
        <div style={styles.wrapper}>

          <DashboardInputSection label='Site Active?' description={siteDisabled ? 'Site is hidden. ' : 'Site is active.'} info='Temporarily hide your site, and display a blank page to visitors.'>
            <Switch checked={siteDisabled ? false : true} onChange={this.onActiveChange} />
          </DashboardInputSection>

          <DashboardInputSection label='Delete Site' info='Permanently delete your website'>
            <Button type="danger" onClick={this.handleDeleteButtonClick}>Delete</Button>
          </DashboardInputSection>
          {!this.props.currentUser.subscriptionHasBeenCancelled && this.props.currentUser.membershipLevel ?
            <DashboardInputSection label=''>
              <Button danger type="text" onClick={this.handleCancelSubscriptionButtonClick}>Cancel Subscription</Button>
            </DashboardInputSection> 
          : null}

          <Modal
            title="Delete Site"
            visible={this.state.isConfirmDeleteModalVisible}
            // onOk={this.confirmDelete}
            // okButtonProps={{
            //   type: 'danger'
            // }}
            footer={null}
            onCancel={this.handleCancelModal}
          >
            <Alert message="Tip: You can always temporarily disable your site using the option above." type="info" showIcon style={{marginBottom: 20}} />
            <div style={styles.descriptionText}>Are you sure you want to permanently delete this website? Type "permanently delete" below to confirm.</div>
            <Input onChange={x => this.setState({confirmText: x.target.value})} value={this.state.confirmText} placeholder='permanently delete' />
            <Button
              onClick={this.confirmDelete}
              type="danger"
              style={{ marginTop: 20, width: "100%" }}
              loading={this.state.isDeleting}
              disabled={this.state.confirmText.toLowerCase()==='permanently delete' ? false : true}
            >
              DELETE
            </Button>
          </Modal>

          <Modal
            title="Cancel Subscription"
            visible={this.state.isCancelModalVisible}
            // onOk={this.confirmDelete}
            // okButtonProps={{
            //   type: 'danger'
            // }}
            footer={null}
            onCancel={this.handleCloseSubscriptionModal}
          >
            {/* <Alert message="Tip: You can always temporarily disable your site using the option above." type="info" showIcon style={{marginBottom: 20}} /> */}
            <div style={styles.descriptionText}>Are you sure you want to cancel your subscription to {this.props.config.data.siteNameDis}? Type "cancel subscription" below to confirm</div>
            <Input onChange={x => this.setState({confirmText: x.target.value})} value={this.state.confirmText} placeholder='cancel subscription' />
            <Button
              onClick={this.confirmCancel}
              type="danger"
              style={{ marginTop: 20, width: "100%" }}
              loading={this.state.isCancelling}
              disabled={this.state.confirmText.toLowerCase()==='cancel subscription' ? false : true}
            >
              Confirm Cancellation
            </Button>
          </Modal>
        </div>
      </PageContentContainer>
    );
  }
}



const styles = {
  wrapper: {
    // display: 'flex',
    // flexDirection: 'column',
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30
    // alignItems: "center"
  },
  label: {
    marginRight: 50,
    // fontWeight: "bold",
    fontFamily: 'Montserrat'
  },
  rowContents: {
    // display: 'flex',
    // flexDirection: 'column',
  },
  descriptionText: {
    marginBottom: 5
  },
  messageInput: {
    marginLeft: 10,
  },
  disabledSwitchRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // marginBottom: 5
  }
};

const mapStateToProps = state => ({
  siteData: state.siteData,
  config: state.config,
  currentUser: state.currentUser
});
const mapActionsToProps = {
  updateSiteData,
  updateCurrentUser
};
export default connect(mapStateToProps, mapActionsToProps)(SettingsTab);
