import React, { Component } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Tooltip } from "antd";
import colors from "../../colors";

class EditorInputSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { info, description, label, compact } = this.props;

    const styles = {
      wrapper: {
        marginBottom: compact ? 10 : 25,
        marginTop: compact ? 8 : 20,
        display: "flex",
        flexDirection: "column",
      },
      top: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
      },
      bottom: {
        marginTop: 5,
        paddingLeft: 10
      },
      description: {
        color: colors.lightText,
        fontSize: 12,
        marginLeft: 8
      },
      content: {
        maxWidth: 300
      },
      label: {
        fontFamily: 'Montserrat'
      }
    };

    return (
      <div style={styles.wrapper}>
        <div style={styles.top}>
          {info ? (
            <Popover content={<div style={styles.content}>{info}</div>} placement='right' >
              <InfoCircleOutlined style={{ marginRight: 5 }} />
            </Popover>
          ) : null}
          <div style={styles.label}>{label}</div>
          {description ? <div style={styles.description}>{description}</div> : null}
        </div>
        <div style={styles.bottom}>{this.props.children}</div>
      </div>
    );
  }
}



export default EditorInputSection;
