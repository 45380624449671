import store from '../redux/store';
import { db } from './index';

const saveSiteData = function(){
  return new Promise((resolve, reject) => {
    let state = store.getState();
    if(state.loggedInUser.loggedIn){
      if(state.siteData.id){
        // this is an EXISTING logged in user with an existing site, just saving its data
        console.log(state.siteData)
        db.collection("sites").doc(state.siteData.id).update(state.siteData)
        .then(docRef => resolve())
        .catch(err => reject(err));
      }
    }else{
    
      
    }
  })
}

export {
  saveSiteData
}