import { Button, Table, Tabs, DatePicker, Radio, Input } from "antd";
import React, { Component } from "react";
import OrdersNavbar from "../../../components/orders/OrdersNavbar";
import tableCols from "./tableCols";
import DateTimeInput from "../../../components/DateTimeInput";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import OrderDashboardClass from "./OrderDashboardClass";
import JobMap from "../../../components/JobMap/JobMap";
const { TabPane } = Tabs;

class WebDashboard extends OrderDashboardClass {
  renderFilters = () => {
    const { activeTabKey } = this.state;
    if (["scheduled", "pendingPayment", "complete", "canceled"].includes(activeTabKey)) {
      return (
        <>
          <Radio.Button value="any">Any Date</Radio.Button>
          <Radio.Button value="today">Today</Radio.Button>
          <Radio.Button value="tomorrow">Tomorrow</Radio.Button>
          {/* <Radio.Button value="thisweek">This Week</Radio.Button> */}
          <Radio.Button value="singledate">Single Date</Radio.Button>
          <Radio.Button value="daterange">Date Range</Radio.Button>
        </>
      );
    }
  };

  render() {
    const { activeTabKey, filterState, dateType, viewType } = this.state;
    let showSingleDate = ["singledate", "today", "tomorrow"].includes(dateType);
    let showDateRange = ["thisweek", "daterange"].includes(dateType);
    let colsToUse = tableCols[activeTabKey];
    let orders = this.getFilteredOrders();

    let table = (
          <Table
            columns={colsToUse}
            dataSource={orders}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => this.onClickOrder(record), // click row
                style: { cursor: "pointer" },
              };
            }}
            // scroll={{y: '100%'}}
            style={{height: '100%', overflow: 'auto', paddingBottom: 50}}
          />
        
    );
    // table = <div style={styles.table}>
      
    // </div>

    let map = (
      <JobMap orders={orders} onClickOrder={this.onClickOrder} />
    )

    let pageContent = viewType === 'map' ? map : table;

    const searchBar = (
      <Input
        prefix={<SearchOutlined />}
        style={{ width: 200, marginLeft: 10 }}
        onChange={(e) => this.setState({ searchText: e.target.value })}
        value={this.state.searchText}
      />
    );

    const mapViewSelect = (
      <Radio.Group onChange={this.onChangeViewType} value={this.state.viewType} style={{ marginLeft: 10 }}>
        <Radio.Button value="list">List</Radio.Button>
        <Radio.Button value="map">Map</Radio.Button>
      </Radio.Group>
    )

    let filters = (
      <div style={styles.topFilterWrapper}>
        <div style={styles.topLeft}>
          <Radio.Group onChange={this.onChangeDateType} value={this.state.dateType} style={{ marginRight: 10 }}>
            {this.renderFilters()}
          </Radio.Group>
          {showSingleDate ? (
            <DateTimeInput dateOnly={true} onChange={(x) => this.onChangeFilterStateItem("dateFilter", x)} value={filterState.dateFilter} />
          ) : null}
          {showDateRange ? (
            <DateTimeInput daterange={true} onChangeDateRange={(x) => this.onChangeFilterStateItem("daterange", x)} value={filterState.daterange} />
          ) : null}
          {searchBar}
          {mapViewSelect}
        </div>
        <div style={styles.topRight}>
          <Link to={`/site/${this.props.match.params.siteId}/neworder`}>
            <Button>+ New Order</Button>
          </Link>
        </div>
      </div>
    );

    return (
      <div style={styles.wrapper}>
        <OrdersNavbar onSelectSection={this.onSelectSection} activeTabKey={this.state.activeTabKey} />
          <div style={styles.pageContents}>
            <Tabs activeKey={activeTabKey} onChange={this.onChangeTab} style={styles.tabs}>
              <TabPane
                style={styles.tab}
                tab={
                  <div style={styles.quotesTitle}>
                    <div>Quotes</div>
                    {this.state.numberOfNewQuotes > 0 ? 
                      <div style={styles.notification}>
                        <div style={styles.notificationText}>{this.state.numberOfNewQuotes}</div>
                      </div>
                    :null}
                  </div>
                }
                key="quotes">
                <>
                  {filters}
                  {pageContent}
                </>
              </TabPane>
              <TabPane style={styles.tab} tab="Unscheduled" key="unscheduled">
                {filters}
                {pageContent}
              </TabPane>
              <TabPane style={styles.tab} tab="All Scheduled Orders" key="scheduled">
                <>
                  {filters}
                  {pageContent}
                </>
              </TabPane>
              <TabPane style={styles.tab} tab="Pending Payment" key="pendingPayment">
                <>
                  {filters}
                  {pageContent}
                </>
              </TabPane>
              <TabPane style={styles.tab} tab="Complete" key="complete">
                <>
                  {filters}
                  {pageContent}
                </>
              </TabPane>
              <TabPane style={styles.tab} tab="Canceled" key="canceled">
                <>
                  {filters}
                  {pageContent}
                </>
              </TabPane>
            </Tabs>
          
          </div>
        
      </div>
    );
  }
}

const styles = {
  wrapper: {
    flexDirection: 'column',
    height: '100vh',
    display: 'flex',
  },
  pageContents: {
    margin: 20,
    flex: 1,
    // position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

  },
  tabs: {
    // height: '100%',
    flex: 1,
    // display: 'flex',
    // margin: 20,

  },

  tab: {
    flex: 1,
    position: 'relative'
    // display: 'flex',


  },
  topFilterWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  topLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  notification: {
    height: 16,
    width: 16,
    borderRadius: 50,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FF3B30',
    position: 'absolute',
    top:-4,
    right:-16
  },
  notificationText: {
    color: '#fff',
    fontSize: 10
  },
  quotesTitle: {
    position: 'relative',

  }
};

export default WebDashboard;
