import React, { Component } from "react";
import { colorToHsl } from "tsparticles/Utils";
import colors from "../../colors";

const Pill = ({ isSelected, onClick, label, width }) => {

  const pillStyle = {
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    marginRight: 12,
    cursor: "pointer",
    width: width || 70,
    textAlign: 'center'
  };

  let styles = {
    selectedPill: {
      ...pillStyle,
      backgroundColor: colors.lightBG,
      // color: "#fff",
      fontWeight: 'bold',
      opacity: 1
    },
    pill: {
      ...pillStyle,
      color: colors.lightText
    },
  }

  return (
    <span onClick={onClick} style={isSelected ? styles.selectedPill : styles.pill}>
      {label}
    </span>
  );
};

export default Pill;