export default [
  {
    label: 'Other',
    value: 'other'
  },
  {
    label: 'Phone',
    value: 'phone'
  },
  {
    label: 'Computer',
    value: 'computer'
  },
]