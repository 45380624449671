import React, { Component } from "react";
import Routes from "./screens";
import firebase from "./firebase"; // leave this here, or firebase doesnt run
import SplashScreen from "./components/SplashScreen";
import { connect } from "react-redux";
import  { loadConfig } from './config/index';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount(){
    loadConfig();
  }

  isLoading = () => {
    // i also handle loading in routes index, this is just the initial load for making sure logged in

    let isLoading = false;
    if (this.props.loggedInUser.isLoading) isLoading = true;
    if (this.props.currentUser.isLoading) isLoading = true;
    if (this.props.config.isLoading) isLoading = true;
    return isLoading;
  };

  render() {
    if (this.isLoading()){
      return <SplashScreen />;
    }else{
      return <Routes />;
    }
  }
}

const styles = {
  wrapper: {
    // width: 900,
    // float: 'right',
    position: "relative"
  }
};
const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  currentUser: state.currentUser,
  config: state.config
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(App);
