import React, { Component } from 'react';
import colors from '../../../colors';

class PreviewLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, value } = this.props;
    return(
      <div style={styles.wrapper}>
        <div style={styles.label}>{label}</div>
        <div style={styles.value}>{value}</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3
  },
  label: {
    color: colors.lightText,
    width: 150
  },
  value: {

  }
};

export default PreviewLineItem;