import React, { Component } from 'react';
import colors from '../colors';
import InputText from './InputText';
import { SketchPicker } from 'react-color';
import { Popover } from 'antd';

class ColorPicker extends Component {

  onChangeColorText = e => {
    const allowed = '0123456789abcdefABCDEF';
    let text = e.target.value;
    text = text.replace("#", "");
    text = text.replace("#", "");
    text = text.split('');
    text = text.map(item => {
      if(allowed.includes(item)) return item;
    })
    text = text.join('');
    if (text.length > 6) return;
    this.props.onChange({target:{value: '#'+text}}); // fake event so its compatible with that function
  };

  render() {
    const { value, onChange, label } = this.props;
    let valueToUse = '#ffffff';
    if(typeof value === 'string'){
      if(value.includes('#')){
        valueToUse = value;
      }
    }
    return(
      <div style={styles.colorRow}>
      <InputText
        label={label}
        inputProps={{
          addonBefore: '#',
          value: valueToUse,
          onChange: this.onChangeColorText,
          style: {width: 170},
          addonAfter: (
            <Popover placement='bottom' content={
              <SketchPicker
              color={ valueToUse }
              onChangeComplete={ x => onChange({target: {value: x.hex}})}
            />
            }>
              <div style={{...styles.color, backgroundColor: valueToUse}} ></div>
              </Popover>
          )
        }}
      />
      {/* <input
          type="color"
          onInput={onChange}
          value={value}
          style={styles.colorInput}
          ref={x => this.mainColorPicker = x}
        /> */}

    </div>
    );
  }
}

const styles = {
  colorRow: {

  },
  colorInput: {
    display: 'none',
    visibility: 'hidden'
  },
  color: {
    height: 20,
    width: 20,
    cursor: 'pointer'
    // borderRadius: 3
  }
};

export default ColorPicker;