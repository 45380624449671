import React, { Component } from "react";
import colors from "../../colors";
import { ClipLoader } from "react-spinners";
const defaultImage = require("assets/img/default.jpg");

class MediaScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onSelect, selectedIndex, images, onAdd, width, isAdding, disableScroll } = this.props;

    const mediaWrapperStyle = {
      width: width || 228,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      height: "100%",
      overflowY: disableScroll ? undefined : "auto",
      maxHeight: "70vh",
      // backgroundColor: 'red',
    };

    let loader = (
      <div style={styles.loaderWrapper}>
        <ClipLoader size={20} color={colors.lightText} />
        <div style={styles.loaderText}>Uploading Image...</div>
      </div>
    );

    return (
      <div style={styles.wrapper}>
        <div style={styles.title}>My Media ({images.length})</div>
        {onAdd ? (
          isAdding ? (
            loader
          ) : (
            <div className="linkItem" style={styles.link} onClick={onAdd}>
              + add
            </div>
          )
        ) : null}
        <div style={mediaWrapperStyle}>
          {images.map((item, index) => {
            return <MediaCard imageUrl={item.url} onClick={() => onSelect(index)} isSelected={selectedIndex === index ? true : false} />;
          })}
        </div>
      </div>
    );
  }
}

const MediaCard = ({ isSelected, imageUrl, onClick }) => {
  return (
    <div style={isSelected ? styles.cardWrapperSelected : styles.cardWrapper} onClick={onClick}>
      <img src={imageUrl || defaultImage} style={styles.cardImage} />
    </div>
  );
};

const cardStyle = {
  width: 100,
  // height: 100,
  maxHeight: 150,
  padding: 3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  marginRight: 0,
  marginBottom: 5,
  border: "2px solid transparent",
  cursor: "pointer",
  // position: 'relative'
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    textAlign: "center",
    marginBottom: 5,
  },
  title: {
    textAlign: "center",
  },

  cardWrapper: {
    ...cardStyle,
  },
  cardWrapperSelected: {
    ...cardStyle,
    border: "2px solid " + colors.main,
  },
  cardImage: {
    width: 100,
    // height: 100,
    flexShrink: 0,
  },

  loaderWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
  },
  loaderText: {
    color: colors.lightText,
    marginLeft: 5,
  },
};

export default MediaScroll;
