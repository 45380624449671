import React, { Component } from "react";
import colors from "../../../../colors";
import { Checkbox } from "antd";

class ScreenshotSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected, onSelect } = this.props;
    return (
      <div style={styles.wrapper}>
        <div style={styles.label}>SCREENSHOT TYPE</div>
        <div style={styles.boxRow}>
          <Box
            selected={selected === "phone"}
            onSelect={() => onSelect("phone")}
            label="Phone"
            imgSrc={require("assets/img/phone.png")}
          />
          <Box
            selected={selected === "computer"}
            onSelect={() => onSelect("computer")}
            label="Computer"
            imgSrc={require("assets/img/computer.png")}
          />
          {/* <Box
            selected={selected === "phoneComputer"}
            onSelect={() => onSelect("phoneComputer")}
            label="Phone & Computer"
            imgSrc={require("assets/img/phone_computer.png")}
          /> */}
          <Box
            selected={selected === "plain"}
            onSelect={() => onSelect("plain")}
            label="Other"
            imgSrc={require("assets/img/dotted_square.png")}
          />
        </div>
        
      </div>
    );
  }
}

const Box = ({ onSelect, label, imgSrc, selected }) => {
  return (
    <div style={selected ? styles.selectedBox : styles.box} onClick={onSelect}>
      <img src={imgSrc} style={styles.icon} width={40} />
      <div style={styles.boxLabel}>{label}</div>
    </div>
  );
};

const boxStyle = {
  borderWidth: 1,
  borderStyle: "solid",
  width: 80,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  cursor: 'pointer',
  backgroundColor: "#fff",
  padding: 5,
  borderRadius: 4,
  borderWidth: 2

};
const styles = {
  wrapper: {
    backgroundColor: "#f7f7f7",
    padding: 10
  },
  label: {
    marginBottom: 5,
    fontSize: 12,
    letterSpacing: 1.1,
    opacity: .7
  },
  icon: {},
  boxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%"
  },
  boxLabel: {
    textAlign: "center",
    lineHeight: 1,
    fontSize: 12,
    marginTop: 7
  },
  box: {
    ...boxStyle,
    borderColor: colors.border,
    opacity: .6
  },
  selectedBox: {
    ...boxStyle,
    borderColor: colors.main,
    opacity: 1,
  }
};

export default ScreenshotSelectBox;
