export default {
  editorInputs: [
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Subtitle",
      type: "string",
      dataKey: "subtext",
      inputProps: {
        multiline: true,
        rows: 2
      }
    },
    {
      label: "Section Height",
      type: "slider",
      dataKey: "heightVH",
      inputProps: {
        min: 30,
        max: 100,
      },
    },
    {
      label: "Background Color",
      type: "color",
      dataKey: "backgroundColor",
    },
    {
      label: "Link to 'Request Service' button?",
      type: "bool",
      dataKey: "hasRequestServiceCTA",
      info: 'Show a button that will link to your "Request Service" form.',
    },
    // {
    //   label: "Request Service Button Color",
    //   type: "color",
    //   dataKey: "requestServiceCTAButtonColor",
    //   info: '',
    //   dependsOn: 'hasRequestServiceCTA'
    // },
    {
      label: "Request Service Button Text",
      type: "text",
      dataKey: "requestServiceCTAButtonText",
      info: '',
      dependsOn: 'hasRequestServiceCTA',
    },
   
  ],
};
