export const UPDATE_TEMPLATE_DATA = 'UPDATE_TEMPLATE_DATA';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_SITE_DATA = 'UPDATE_SITE_DATA';
export const UPDATE_MEDIA_ORDER = 'UPDATE_MEDIA_ORDER';
export const UPDATE_MEDIA_ORDERS = 'UPDATE_MEDIA_ORDERS';

export const UPDATE_MEDIA_ITEMS = 'UPDATE_MEDIA_ITEMS';

export const UPDATE_CONFIG_DATA = 'UPDATE_CONFIG_DATA';
export const UPDATE_APP_TYPE = 'UPDATE_APP_TYPE';

export const UPDATE_EDITS_PENDING = 'UPDATE_EDITS_PENDING';

export const SET_MISC = 'SET_MISC';


export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_MEMBERSHIP_INFO = 'UPDATE_MEMBERSHIP_INFO';