export default {
  editorInputs: [
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Subtitle",
      type: "string",
      dataKey: "subtext",
    },
    {
      label: "Screenshot URL",
      type: "image",
      dataKey: "screenshotUrl",
    },
    {
      label: "Section Height",
      type: "slider",
      dataKey: "heightVH",
      inputProps: {
        min: 30,
        max: 100,
      },
    },
    // {
    //   label: "Link to 'Request Service' button?",
    //   type: "bool",
    //   dataKey: "hasRequestServiceCTA",
    //   info: 'Show a button that will link to your "Request Service" form.',
    // },
  ],
};
