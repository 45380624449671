import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTemplateData } from 'redux/actions';
import EditableItemCard from '../../../EditableItemCard';
import InputText from 'components/InputText';
import AddButtonRow from '../../AddButtonRow';
import EditSectionClass from '../shared/EditSectionClass';
import FileUploadSection from '../../../media/FileUploadSection';
import { Button, Input, Switch } from 'antd';
import EditorInputSection from '../../../input_sections/EditorInputSection';

class EditPhotoGallery extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeInsta = (e) => {
    this.setState({insta: e.target.value})
  }

  onImport = () => {

  }


  render() {
    const { templateData, sectionData } = this.props;
    return(
      <div style={styles.wrapper}>
        {/* <div style={styles.importTitle}>Import Photos from Instagram</div>
        <InputText inputProps={{
          onChange: e => this.onChangeInsta(e),
          value: this.state.insta
        }} />
        <Button onClick={this.onImport} type='primary' disabled={!this.state.insta}>Import</Button> */}
        <EditorInputSection label={'Dark Mode'} >
          <Switch checked={sectionData.darkMode} onChange={val => this.editData({darkMode: val})} />
        </EditorInputSection>
        {sectionData.items.map((item, index, key) => {
          return(
            <EditableItemCard onClose={() => this.onCloseTestimonial(index)}>
                <FileUploadSection
                  // {...(f.inputProps || {})}
                  // label={f.label}
                  onUrlSelected={(url) => this.editData({ imageUrl: url }, index, 'items')}
                  currentFile={{ url: item.imageUrl }}
                />

            </EditableItemCard>
          )
        })}
        <AddButtonRow label='Add Photo' onClick={() => this.addItem('items')} />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'scroll',
    padding: 10,
  },
  linkInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(mapStateToProps, mapActionsToProps)(EditPhotoGallery);