// this thing has alot of options of words
import shortid from 'shortid'
const { uniqueNamesGenerator, adjectives, colors, animals, names } = require("unique-names-generator");
// doesnt need to be unique, just default name for user to identify their site.
// TODO probably want to change this to be spesific to the type of site (landscaping, etc.)
export default function () {
  let randomNumber = Math.round(Math.random() * (99999 - 1003) + 1003);

  return(`website-${shortid.generate()}`);


  // let randomName = uniqueNamesGenerator({
  //   dictionaries: [colors, names], 
  // }); // big-donkey
  // randomName += '_site';
  // return randomName;
}
