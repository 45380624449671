import { Tag } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { colors } from "unique-names-generator";

const mainCols = [
  {
    title: "Name",
    dataIndex: "customerName",
    key: "customerName",
    sorter: (a, b) => a.customerName > b.customerName ? -1 : 1
  },
  {
    title: "Address",
    dataIndex: "customerAddress",
    key: "customerAddress",
    render: (addressObject = {}) => {
      return <div>{addressObject.formatted_address}</div>;
    },
  },
  {
    title: "Scheduled For",
    dataIndex: "scheduledFor",
    key: "scheduledFor",
    render: (data) => {
      return <div>{dayjs(data).format("MMM DD, YYYY hh:mm a")}</div>;
    },
    sorter: (a,b) => a.scheduledFor - b.scheduledFor

  },
];

const quoteCols = [
  {
    title: "Name",
    dataIndex: "customerName",
    key: "customerName",
    sorter: (a, b) => a.customerName > b.customerName ? -1 : 1

  },
  {
    title: "Address",
    dataIndex: "customerAddress",
    key: "customerAddress",
    render: (addressObject = {}) => {
      return <div>{addressObject.formatted_address}</div>;
    },
  },
  {
    title: "Submitted At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data) => {
      return <div>{dayjs(data).format("MMM DD, YYYY hh:mm a")}</div>;
    },
    sorter: (a,b) => a.createdAt - b.createdAt
  },
  {
    title: "",
    dataIndex: "hasBeenViewed",
    key: "hasBeenViewed",
    render: (data) => {
      return(data ? null : <Tag color='green'>NEW</Tag>)
    }
  },
];

export default {
  quotes: quoteCols,
  unscheduled: quoteCols,
  scheduled: mainCols,
  pendingPayment: mainCols,
  complete: mainCols,
  canceled: mainCols,
};

const styles = {
  newPill: {
    padding: 4,
    backgroundColor: '#34C759',
    width: 120,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold'
  }
}
