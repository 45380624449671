import dayjs from "dayjs";
import React, { Component } from "react";
import invoiceIconBlue from "../../../assets/img/invoice_blue.png";
import invoiceIcon from "../../../assets/img/invoice.png";
import colors from "../../../colors";
import Dinero from "dinero.js";

class InvoiceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  render() {
    const { isHover } = this.state;
    const { invoice = {} } = this.props;
    let isInvoicePaid = invoice.status === 'paid';

    // const voidOverlay = (
    //   <div style={styles.overlayWrapper}>
    //     <div style={styles.voidOverlayText}></div>
    //   </div>
    // )
    return (
      <div
        style={{ ...styles.wrapper, marginTop: isHover ? -4 : 0, opacity: invoice.status === 'void' ? .5 : 1 }}
        onClick={this.props.onClick}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        <img src={invoiceIcon} style={styles.invoiceIcon} />
        {isInvoicePaid ? (
          <div style={{ ...styles.invoiceLabel, color: colors.green, fontWeight: "bold" }}>PAID</div>
        ) : (
          <div style={{ ...styles.invoiceLabel, color: colors.lightText }}>{invoice.status.toUpperCase()}</div>
        )}
        <div style={styles.price}>${Dinero({ amount: invoice.amount_due }).toFormat("0,0.00")}</div>
        <div style={styles.date}>Generated {dayjs(new Date(invoice.created * 1000)).format("MMM DD, YYYY hh:mm a")}</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    borderRadius: 5,
    boxShadow: "0 0 6px 2px rgba(0,0,0,.05)",
    position: "relative",
    padding: 10,
    height: 120,
    width: 220,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
    marginBottom: 20,
    cursor: "pointer",
    transition: ".2s",
  },
  invoiceLabel: {
    position: "absolute",
    top: -1,
    textAlign: "center",
    width: "100%",
    color: colors.lightText,
    letterSpacing: "2px",
  },
  invoiceIcon: {
    position: "absolute",
    top: -5,
    left: -5,
    height: 32,
    filter: "brightness(0) invert(.05)",
    opacity: .8
  },
  price: {
    fontSize: 20,
  },
  date: {
    color: colors.lightText,
    fontSize: 12,
  },
};

export default InvoiceCard;
