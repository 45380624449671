import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddButtonRow = ({label, onClick}) => {
  return(
  <div style={styles.addButtonRow}>
    <Button onClick={onClick} icon={<PlusOutlined />}>{label}</Button>
  </div>
  );
}

const styles = {
  addButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
};

export default AddButtonRow;