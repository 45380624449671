import React, { Component } from 'react';
import colors from '../../colors';
import { Button } from 'antd';
import {ClipLoader} from 'react-spinners';
import ChooseMediaModal from '../modals/ChooseMediaModal';
import { addMediaItem } from '../../firebase/media';

class FileUploadSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isDragging: true });
  };

  onMouseLeave = e => {
    this.setState({ isDragging: false });
    e.stopPropagation();
    e.preventDefault();
  };

  onDrop = e => {
    e.stopPropagation();
    e.preventDefault();
    console.log(e);
    if(e.dataTransfer && e.dataTransfer.files.length != 0){
      var files = e.dataTransfer.files //Array of filenames
      let file = files[0];
      this.addFile(file)
    }
    return false;
  };

  addFile = file => {
    this.setState({isUploading: true})
    addMediaItem(file).then(finishedFile => {
      this.props.onUrlSelected(finishedFile.url)
      this.setState({isUploading: false})
    })
  }

  onChooseFromMyMedia = () => {
    this.setState({isMediaModalVisible: true})
  }

  onSelectMyMediaItem = item => {
    this.props.onUrlSelected(item.url)
  };

  render() {
    const { currentFile={}, fileType, compact } = this.props;
    const { isDragging, isUploading } = this.state;
    const existingLogoRowStyle = {
      // backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      borderRadius: 3,
      marginTop: 10,

    };

    const necessaryItemsToRender = (
      <>
        <input
          type="file"
          accept={fileType || "image"}
          ref={x => (this.fileInput = x)}
          style={styles.fileInput}
          onChange={e => this.addFile(e.target.files[0])}
        ></input>
        <ChooseMediaModal
          visible={this.state.isMediaModalVisible}
          onCancel={() => this.setState({isMediaModalVisible: false})}
          onSelectItem={this.onSelectMyMediaItem}
        />
      </> 
    )

    if(compact){
      return(
        <div style={styles.compactWrapper}> 
          {necessaryItemsToRender}
          <div style={styles.compactLabel}>{this.props.label}</div>

          <div style={styles.compactRow}>
            <img src={currentFile.url}  height={60} />
            <div style={styles.compactRight}>
            {isUploading ? 
              <>
                <div style={{marginBottom: 10, color: colors.lightText}}>Uploading...</div>
                <ClipLoader color={colors.border} />
              </>
              : 
              <>
                <Button size='small' onClick={this.onChooseFromMyMedia} style={styles.link}>Choose From My Media</Button>
                <div style={{height: 4}}></div>
                <Button size='small' onClick={() => this.fileInput.click()} style={styles.link}>Upload From Computer</Button>
              </>
            }
            </div>
          </div>
        </div>
      )
    }

    return(
      <div style={styles.wrapper}> 
        {necessaryItemsToRender}
      <div style={styles.topRow}>
        <div style={styles.label}>{this.props.label}</div>
          {currentFile.url ? 
          <div style={existingLogoRowStyle}>
            <img src={currentFile.url}  height={100} />
          </div>
          : <div />}
      </div>
        <div
          style={
            isDragging
              ? { ...styles.dropzone, borderColor: colors.green }
              : styles.dropzone
          }
          onDrop={this.onDrop}
          onDragOver={this.onDragOver}
          onMouseLeave={this.onMouseLeave}
          onDragLeave={this.onMouseLeave}
          onDragLeaveCapture={this.onMouseLeave}
        >
          {isUploading ? 
          <>
            <div style={{marginBottom: 10, color: colors.lightText}}>Uploading...</div>
            <ClipLoader color={colors.border} />
          </>
          : 
          <div>
            Drag & drop 
            <br />
            or
            <br />
            <div style={styles.buttonRow}>
              <Button size='small' onClick={this.onChooseFromMyMedia} style={styles.link}>Choose From My Media</Button>
              <div style={{width: 20}}></div>
              <Button size='small' onClick={() => this.fileInput.click()} style={styles.link}>Upload From Computer</Button>
            </div>
          </div>
          }
        </div>
        
      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex: 1,
    // paddingTop: 20,
  },

  dropzone: {
    // width: '100%',
    height: 140,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 3,
    color: colors.lightText,
    // fontSize: 20,
    marginTop: 40,
    textAlign: "center",
    margin: 10,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "transparent",
    flex: 1
  },
  fileInput: {
    visibility: "hidden",
    display: "none"
  },
  link: {
    marginTop: 5
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    flex: .4,
    
  },

  // COMPACT
  compactWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  compactRight: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 5
  },
  compactLabel: {
    color: colors.lightText
  },
  compactRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
};

export default FileUploadSection;