export default {
  editorInputs: [
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Subtitle",
      type: "string",
      dataKey: "subtitle",
    },
    {
      label: "",
      dataKey: "items",
      type: "items",
      header: "",
      items: [
        {
          label: "Image",
          type: "image",
          dataKey: "imageUrl",
          info: 'Tip: right click the webpage you want to show off, and select "Take a screenshot > save full page". This component works best when the screenshot is a long rectangle of the entire webpage.',
          inputProps: {
            compact: true,
          },
        },
        {
          label: "Label",
          type: "string",
          dataKey: "label",
        },
        {
          label: "Link",
          type: "string",
          dataKey: "link",
        },
      ],
    },
  ],
};
