import React from 'react';
import colors from '../../../../colors';

const TopBanner = ({title, text}) => {
  return(
    <div style={styles.wrapper}>
      {title ? <h2 style={styles.title}>{title}</h2> : null}
      {text ? <div style={styles.text}>{text}</div> : null}
    </div>
  );
}

const styles = {
  wrapper: {
    // backgroundColor: colors.lightBG
    
  },
  title: {
    textAlign: 'center'
  },
  text: {
    textAlign: 'center'

  }
};

export default TopBanner;