import React, { Component } from 'react';
import AdminNavbar from './AdminNavbar';

class AdminDesignersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <AdminNavbar onSelect={this.onSelectNavItem} selected={this.state.selectedNavItem} />

        AdminDesignersPage
      </div>
    );
  }
}

const styles = {

};

export default AdminDesignersPage;