import { Menu } from "antd";
import React, { Component } from "react";
import colors from "../../../colors";

class ServiceMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, onClick } = this.props;

    let insideStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      // maxWidth: 300,
      padding: 10,
      cursor: 'pointer',
      backgroundColor: this.state.hover ? colors.lightBG : 'transparent'
    };

    return (
        <div
          style={insideStyle}
          onClick={onClick}
          onMouseEnter={ () => this.setState({hover: true})}
          onMouseLeave={() => this.setState({hover: false})}
        >
          <div style={styles.left}>
            <div style={styles.title}>{item.title}</div>
            <div style={styles.desc}>{item.desc}</div>
          </div>
          <div style={styles.right}>
            <div style={styles.price}>{item.price}</div>
          </div> 
        </div>
    );
  }
}

const styles = {
  title: {
    fontWeight: 'bold'
  },
  desc: {
    color: colors.lightText,
    fontSize: 14,
    lineHeight: 1.2
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  right: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  price: {

  }
};

export default ServiceMenuItem;
