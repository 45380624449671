import React, { Component } from "react";
import PageTemplate from "./PageTemplate";
import { Table, Divider, Radio } from "antd";

class MyJobsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobFilter: "in_progress"
    };
  }

  onRowClick = data => {
    this.props.history.push(`/designer/jobs/view/${data.id}`);
  };

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
        // render: (text, record) => <div>1472</div>
      },
      {
        title: "# items",
        key: "#items",
        render: (text, record) => <div>2</div>
      },
      {
        title: "Due",
        dataIndex: "address",
        key: "address",
        render: (text, record) => <div>12/03/2019 (2 days)</div>
      },


      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <a>Invite {record.name}</a>
      //       <Divider type="vertical" />
      //       <a>Delete</a>
      //     </span>
      //   ),
      // },
    ];

    const data = [
      {
        id: "1",
        name: "John Brown",
        age: 32,
        address: "New York No. 1 Lake Park",
        tags: ["nice", "developer"]
      },
      {
        id: "2",
        name: "Jim Green",
        age: 42,
        address: "London No. 1 Lake Park",
        tags: ["loser"]
      },
      {
        id: "3",
        name: "Joe Black",
        age: 32,
        address: "Sidney No. 1 Lake Park",
        tags: ["cool", "teacher"]
      }
    ];
    return (
      <div style={styles.wrapper}>
        <PageTemplate>
          <h1>My Jobs</h1>
          <Radio.Group
            defaultValue="in_progress"
            buttonStyle="solid"
            onChange={e => this.setState({ jobFilter: e.target.value })}
            value={this.state.jobFilter}
          >
            <Radio.Button value="in_progress">In Progress</Radio.Button>
            <Radio.Button value="pending_approval">
              Pending Approval
            </Radio.Button>
            <Radio.Button value="finished">Finished</Radio.Button>
            <Radio.Button value="complete_and_paid">
              Complete & Paid
            </Radio.Button>
          </Radio.Group>
          <br />
          <br />
          <div>Total: 69</div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onRowClick={this.onRowClick}
          />
        </PageTemplate>
      </div>
    );
  }
}

const styles = {};

export default MyJobsPage;
