import React, { Component } from "react";
import { connect } from "react-redux";
import { updateTemplateData } from "redux/actions";
import EditableItemCard from "../../../EditableItemCard";
import InputText from "components/InputText";
import AddButtonRow from "../../AddButtonRow";
import EditSectionClass from "../shared/EditSectionClass";
import { Alert, Button, Switch, Space } from "antd";
import EditorInputSection from "../../../input_sections/EditorInputSection";
import { LinkOutlined } from "@ant-design/icons";

class EditPricingWithDetails extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }
  changeItem = (key, val) => {
    this.editData({ [key]: val });
  };

  goToServices = () => {
    let url = `https://orders.${this.props.config.data.siteNameValue}.com/site/${this.props.siteData.id}/myservices`;
    window.location = url
  }

  render() {
    const { templateData, sectionData } = this.props;
    return (
      <div style={styles.wrapper}>



        <EditorInputSection label={'Dark Mode'} >
          <Switch checked={sectionData.darkMode} onChange={val => this.editData({darkMode: val})} />
        </EditorInputSection>

        <InputText
          label={"Title"}
          inputProps={{
            onChange: (e) => this.changeItem("title", e.target.value),
            value: sectionData.title,
          }}
        />

        <InputText
          label={"Description"}
          inputProps={{
            onChange: (e) => this.changeItem("subtext", e.target.value),
            value: sectionData.subtext,
          }}
        />

        <div style={{height: 15}} />

        <Alert
          message={
            <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column',}}>
              {/* <div>The pricing items are populated from "My Services" - you can set these in the order management section of the app.</div> */}
              <div>To add your services here, go to "My Services" in the order management section of the app.</div>
              <Button type='ghost' icon={<LinkOutlined />} size='small' style={{marginTop: 10}} onClick={this.goToServices}>Go to  "My Services"</Button>
              </div>
          }
          type="warning"
          showIcon
        />
        {/* <Button icon={<LinkOutlined />}>Go To "My Services"</Button> */}

      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: "scroll",
    padding: 10,
  },
  linkInputWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

const mapStateToProps = (state) => ({
  templateData: state.templateData,
  siteData: state.siteData,
  config: state.config
});
const mapActionsToProps = {
  updateTemplateData,
};
export default connect(mapStateToProps, mapActionsToProps)(EditPricingWithDetails);
