import { Button, Divider } from 'antd';
import {firebase} from '../../firebase';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import closeIcon from '../../assets/img/close.png';
import { connect } from 'react-redux';

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompletleyClosed: true,
      isShownOpen: false
    };
  }

  linkTo = (params) => {
    this.props.history.push(`/site/${this.props.match.params.siteId}/orders?navid=${shortid.generate().substring(0,4)}${params}`);
    // navid is so if they link to a tab, then then click a different tab, then open the menu and click the original tab again, we will know we should navigate back to that tab
    // otherwise we dont know they clicked a link, since the URL wouldnt change
    this.props.onClose();
  }

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //
      });
  };

  onClickSignup = () => {
    window.location = this.props.config.data.stripePaymentLink;
  }


  render() {
    const { isOpen } = this.props;

    const styles = {
      wrapper: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        // bottom: 0,
        right: 0,
        // left: 0,
        backgroundColor: '#fff',
        width: isOpen ? '100vw' : '0vw',
        height: isOpen ? '100vh' : '100vh',
        transition: '.1s',
        padding: isOpen ? 20 : 0,
        zIndex: 99999,
        maxHeight: '100vh',
        overflow: 'hidden'
      },
      top: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      contents: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flex: 1
      },
      closeWrapper: {
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      closeIcon: {
        height: 30,
        width: 30,
        opacity: .8
      },
      link: {
        fontSize: 18,
        marginBottom: 15,
        cursor: 'pointer',
        color: '#40a9ff'
      },
      notification: {
        height: 16,
        width: 16,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FF3B30',
        marginLeft: 10,
        marginBottom: 15
      },
      notificationText: {
        color: '#fff',
        fontSize: 10
      },
      quotesWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }
    
    };
    return(
      <div style={styles.wrapper}>
        {isOpen ? 
        <>
          <div style={styles.top}>
            <div style={styles.topLeft}></div>
            <div style={styles.topRight}>
            <div style={styles.closeWrapper}>
                  <img src={closeIcon } style={styles.closeIcon} onClick={this.props.onClose} />
                </div>
            </div>
          </div>
          <div style={styles.contents}>
            <div style={styles.quotesWrapper}>
              <div style={styles.link} onClick={() => this.linkTo('&tab=quotes')}>Quotes</div>
              {this.props.misc.numberOfNewQuotes ? 
                <div style={styles.notification}>
                  <div style={styles.notificationText}>{this.props.misc.numberOfNewQuotes}</div>
                </div>
              :null}
            </div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=unscheduled')}>Unscheduled</div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=scheduled&datetype=today')}>Today</div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=scheduled&datetype=tomorrow')}>Tomorrow</div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=scheduled')}>All Scheduled Orders</div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=pendingPayment')}>Pending Payment</div>
            <div style={styles.link} onClick={() => this.linkTo('&tab=complete')}>Complete</div>
            {/* <div style={styles.link} onClick={() => this.linkTo('&tab=allordersearch')}>Search All Orders</div> */}
            <div style={styles.link} onClick={() => this.linkTo('&tab=canceled')}>Canceled</div>
            {/* <div style={styles.bottom}></div> */}
            <Divider />
            <Button type='primary' style={{marginBottom: 10}} onClick={this.onClickSignup}>Sign Up for Membership</Button>
            <Link style={styles.link} to={`/site/${this.props.match.params.siteId}/myservices`}>My Services</Link>
            <Link style={styles.link} to={`/site/${this.props.match.params.siteId}/settings`}>Settings</Link>
            <Link style={styles.link} to={`/`}>All Sites</Link>
            <div style={styles.link} onClick={this.logout}>Log Out</div>

            <small style={styles.bottomMessage}></small>
          
          </div>
        </>
        :null}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  config: state.config,
  misc: state.misc
});
const mapActionsToProps = {
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(MobileMenu));