import React, { Component } from "react";
import TutorialStep from "./TutorialStep";
import googleAddToCart from "../../../../assets/img/tutorials/google_addtocart.png";
import googleClickDomain from "../../../../assets/img/tutorials/google_click_domain.png";
import googleDnsClick from "../../../../assets/img/tutorials/google_dns_click.png";
import googleDnsConfig from "../../../../assets/img/tutorials/google_dns_config.png";
import { connect } from 'react-redux';

class GoogleDomainsTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <TutorialStep
          number="1."
          title="Choose Your Domain Name"
          description={
            <>
              Go to{" "}
              <a href="https://domains.google/" target="_blank">
                Google Domains
              </a>
              , and enter the domain name you would like to use.
            </>
          }
        />

        <TutorialStep
          number="2."
          title="Purchase the domain name"
          description={
            <>
              Once you've found the domain name you want, select "Add to Cart", and then go to your cart. You will then be prompted to purchase the
              domain name
            </>
          }
          imgSrc={googleAddToCart}
        />

        <TutorialStep
          number="3."
          title="Select Your Domain"
          description={
            <>
              After completing the purchase, go back to{" "}
              <a href="https://domains.google/" target="_blank">
                Google Domains
              </a>{" "}
              and select "Manage" for your domain.{" "}
            </>
          }
          imgSrc={googleClickDomain}
        />

        <TutorialStep
          number="4."
          title="Find the Configuration"
          description={<>Click "DNS" on the left side, and scroll down to the section "Custom Resource Records"</>}
          imgSrc={googleDnsClick}
        />
        <TutorialStep
          number="5."
          title="Add Your Records"
          description={
            <>
              Enter your records into the "Custom Resource Records". Be sure to scroll up on this page to find your custom records, do NOT actually
              enter 123.123.123.123
            </>
          }
          imgSrc={googleDnsConfig}
        />
        <TutorialStep
          number="6."
          title="Done!"
          description={
            <>
              Domain name registrars can take anywhere from 5 minutes to 48 hours to update your settings. Check back later, and your website should
              be showing on your new domain name! If your website is still not showing after 48 hours, you can contact support@{this.props.config.data.supportEmailDomain}.com for help.
            </>
          }
        />
      </>
    );
  }
}

const styles = {};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(GoogleDomainsTutorial);
