import * as types from '../types';
import { updateEditsPending } from './dispatchers';

export function updateTemplateData(x){
  updateEditsPending({templateData: true})
  return {
    type: types.UPDATE_TEMPLATE_DATA,
    payload: x
  }
}

export function updateSiteData(x){
  return {
    type: types.UPDATE_SITE_DATA,
    payload: x
  }
}

export function updateLoggedInUser(x){
  return {
    type: types.UPDATE_LOGGED_IN_USER,
    payload: x
  }
}

export function updateCurrentUser(x){
  return {
    type: types.UPDATE_CURRENT_USER,
    payload: x
  }
}

export function updateConfig(x){
  return {
    type: types.UPDATE_CONFIG_DATA,
    payload: x
  }
}


export function updateAppType(x){
  return {
    type: types.UPDATE_APP_TYPE,
    payload: x
  }
}

export function updateLoading(x){
  return {
    type: types.UPDATE_LOADING,
    payload: x
  }
}



