export default {
  info: 'You will recieve an email when this form is submitted',
 editorInputs: [
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Subtitle",
      type: "string",
      dataKey: "subtitle",
    },
  ],
};
