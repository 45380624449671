import { updateEditsPending } from "../redux/actions/dispatchers";
import store from "../redux/store";
import { db } from "./index";

const saveTemplateData = function () {
  return new Promise((resolve, reject) => {
    let state = store.getState();

    if (state.siteData.id) {
      // this is an EXISTING logged in user with an existing site, just saving its data
      db.collection("sites")
        .doc(state.siteData.id)
        .update({
          data: state.templateData,
        })
        .then((docRef) => {
          updateEditsPending({templateData: false})
          resolve()
        })
        .catch((err) => reject(err));
    }
  });
};

export { saveTemplateData };
