import { Button, Input, Popconfirm, Upload, Modal, message, Select} from "antd";
import React, { Component } from "react";
import OrdersNavbar from "../../../components/orders/OrdersNavbar";
import { db } from "../../../firebase";
import { connect } from "react-redux";
import DataItem from "../../../components/orders/DataItem";
import { isAndroid, isMobile } from "react-device-detect";
import { addMediaItem } from "../../../firebase/media";
import { ClipLoader } from "react-spinners";
import { ArrowRightOutlined, CalendarOutlined, CheckOutlined, ClockCircleOutlined, CloseOutlined, CreditCardOutlined, EditOutlined, MailOutlined, MessageOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import colors from "../../../colors";
import mapIcon from "../../../assets/img/map.png";
import documentIcon from "../../../assets/img/document.png";
import dayjs from "dayjs";
import PopupPill from "../../../components/PopupPill";
import Loader from "../../../components/Loader";
import AddressAutoComplete from "../../../components/AddressAutocomplete";
import ItemBox from "../MyServicesPage/ItemBox";
import DraggableList from "react-draggable-list";
import SelectServiceModal from "../../../components/modals/SelectServiceModal";
import DateTimeInput from "../../../components/DateTimeInput";
import AcceptAndScheduleModal from "../../../components/modals/AcceptAndScheduleModal";
import { v4 as uuid } from 'uuid';
import DashboardSquare from "../../../components/DashboardSquare/DashboardSquare";
import SquareSection from "../../../components/DashboardSquare/SquareSection";
import InvoiceSection from "./InvoiceSection";
import AddToCalendarModal from "../../../components/modals/AddToCalendarModal";

const defaultOrder = {
  customerAddress: {},
  photos: [],
  requestedServices: [],
  showSelectServiceModal: false,
  customerPhone: ''
}

class ViewOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      existingInvoicesLoading: true,
      order: defaultOrder,
      existingInvoices: []

    };
  }

  componentDidMount() {
    this.loadOrderData();
  }

  markOrderViewed = () => {
    if(this.props.isNewOrder) return;
    if(!this.state.order.hasBeenViewed){
      db.collection('orders').doc(this.props.match.params.orderId).update({
        hasBeenViewed: true
      })
    }
  }



  loadOrderData = () => {
    if(this.props.isNewOrder){
      this.setState({
        loading: false,
      })
    }else{
      let orderId = this.props.match.params.orderId;
      db.collection("orders")
        .doc(orderId)
        .get()
        .then((docRef) => {
          let order = docRef.data();
          this.setState({
            order: order,
            loading: false,
          }, () => {
            this.markOrderViewed();
          });
        });
    }
  };

  changeOrder = (key, val) => {
    this.setState({
      order: {
        ...this.state.order,
        [key]: val,
      },
    });
  };

  sendEmail = () => {
    document.location.href = "mailto:" + this.state.order.customerEmail;
  };

  callPhone = () => {
    document.location.href = "tel:" + this.state.order.customerPhone;
  };

  textPhone = () => {
    document.location.href = "sms:" + this.state.order.customerPhone;
  };

  openMaps = () => {
    if (isMobile) {
      if (isAndroid) {
        document.location.href = `https://www.google.com/maps/search/?api=1&query=${this.state.order.customerAddress.formatted_address}`;
      } else {
        document.location.href = `http://maps.apple.com/?q=${this.state.order.customerAddress.formatted_address}`;
      }
    } else {
      window.open(`http://maps.google.com/?q=${this.state.order.customerAddress.formatted_address}`, "_blank");
    }
  };

  uploadPhoto = (data) => {
    const { file, fileList } = data;
    this.setState({ photoUploading: true });

    addMediaItem(file, { path: "orders/" }).then((mediaItemData) => {
      let antFile = {
        ...mediaItemData,
        uid: mediaItemData.id,
        ...file,
        mediaItemData: mediaItemData,
      };
      this.setState({
        photoUploading: false,
        order: {
          ...this.state.order,
          photos: [...this.state.order.photos, antFile],
        },
      });
    });
  };

  onCreateNewOrder = () => {
    const { order } = this.state;
    const { customerEmail, customerPhone, requestedServices, customerName } = this.state.order;
    if(!customerName) return alert("Please enter a customer name first");
    if(!customerPhone && !customerEmail) return alert("Please enter either a customer email or phone first");
    if(requestedServices.length === 0) return alert("Please enter at least 1 service for this order.");
    let idToUse = uuid();
    this.setState({isCreatingNewOrder: true})
    db.collection('orders').doc(idToUse).set({
      id: idToUse,
      stage: 'active',
      siteId: this.props.siteData.id,
      userId: this.props.siteData.userId,
      createdAt: Date.now(),
      hasBeenViewed: true,
      ...this.state.order
    }).then(docRef => {
      this.setState({isCreatingNewOrder: false});
      this.props.history.push(`/site/${this.props.match.params.siteId}/orders`);
      message.success("Order Created!")
    })
  }


  onClickRemovePhoto = (data) => {
    return new Promise(resolve => {
      Modal.confirm({
        title: 'Are you sure you want to delete this photo ?',
        onOk: () => {
          resolve(true);
          this.setState({
            order: {
              ...this.state.order,
              photos: this.state.order.photos.filter(x => x.id !== data.id)
            },
          });
        },
        onCancel: () =>{
          resolve(false);
        }
      })
    })
  }

  handlePreview = async (file) => {
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  onEdit = () => {
    this.setState({
      isEdit: true,
    });
  };

  onAccept = () => {
    this.setState({
      isAcceptAndScheduleModalVisible: true,
      acceptAndScheduleModalType: 'accept'
    })
  };

  onSaveChanges = () => {
    if(this.state.loading) return ;
    return new Promise(resolve => {
      this.setState({ isSaving: true });
      db.collection("orders")
        .doc(this.state.order.id)
        .update(this.state.order)
        .then(() => {
          this.setState({
            isSaving: false,
            isEdit: false,
            loading: false,
          }, () => {
            resolve()
          });
        });
    })
  };

  resetSaveMyNotesTimeout = () => {
    if (this.state.showDidSaveNotesMessage) this.setState({ showDidSaveNotesMessage: false });
    if (this.removeSavedNotesMessageTimeout) clearTimeout(this.removeSavedNotesMessageTimeout);
    if (this.saveMyNotesTimeout) clearTimeout(this.saveMyNotesTimeout);
    this.saveMyNotesTimeout = setTimeout(() => {
      this.confirmSaveMyNotes();
    }, 1200);
  };

  confirmSaveMyNotes = () => {
    db.collection("orders")
      .doc(this.state.order.id)
      .update({
        myNotes: this.state.order.myNotes,
      })
      .then((docRef) => {
        this.setState({ showDidSaveNotesMessage: true });
        this.removeSavedNotesMessageTimeout = setTimeout(() => {
          this.setState({ showDidSaveNotesMessage: false });
        }, 7000);
      });
  };

  onChangeMyNotes = (e) => {
    this.resetSaveMyNotesTimeout();
    this.changeOrder("myNotes", e.target.value);
  };

  onRejectJob = () => {
    db.collection('orders').doc(this.state.order.id).update({
      stage: 'canceled',
      systemNotes: (this.state.order.systemNotes || '')+"-this order was rejected on "+new Date().toString()
    }).then(docRef => {
      // TODO navigate away
      this.loadOrderData();
      message.info('Job Rejected.');
      this.props.history.push(`/site/${this.props.match.params.siteId}/orders`)
    })
  }

  onSelectService = (s) => {
    this.setState({
      order: {
        ...this.state.order,
        requestedServices: [s || this.getDefaultServiceData(), ...this.state.order.requestedServices]
      }
    })
  }

  onChangeService = (itemId, property, val) => {
    let newServiceList = this.state.order.requestedServices.map((x, xIndex) => {
      if(x.id === itemId){
        return({
          ...x,
          [property]: val
        })
      }else{
        return x
      }
    })
    this.setState({
      order: {
        ...this.state.order,
        requestedServices: newServiceList
      }
    })
  }

  onServiceListChange = newList => {
    return new Promise(resolve => {
      this.setState({
        order: {
          ...this.state.order,
          requestedServices: newList
        }
      }, () => resolve())
    })
  }

  onDeleteServiceItem = itemId => {
    this.setState({
      order: {
        ...this.state.order,
        requestedServices: this.state.order.requestedServices.filter(x => x.id !== itemId)
      }
    })
  }

  onChangeLineItems = async (list) => {
    // invoice modal uses this tp update line items when they are invoiced
    await this.onServiceListChange(list);
    this.onSaveChanges();
  }

  onScheduleJobFromModal = date => {
    this.onSaveChanges().then(() => {
      let newJob = {stage: 'active'};
      if(date) newJob.scheduledFor = date;
      db.collection('orders').doc(this.state.order.id).update(newJob).then(res => {
        // this.setState({isSchedulingJob: false})
        this.loadOrderData();
        message.success("Job Accepted!")
      })
    })
  }

  onChangeScheduledFor = ts => {
    if(!ts) return this.changeOrder('scheduledFor', null)
    this.changeOrder('scheduledFor', ts)
    // console.log('ts', ts)
  }

  moveToPendingPayment = () => {
    this.setState({isMovingOrder: true})
    this.onSaveChanges().then(() => {
      db.collection('orders').doc(this.state.order.id).update({stage: 'pendingPayment'}).then(res => {
        this.loadOrderData();
        message.success("Done!");
        this.setState({isMovingOrder: false})
      })
    })
  }

  markCompleteAndPaid = () => {
    this.setState({isMovingOrder: true})
    this.onSaveChanges().then(() => {
      db.collection('orders').doc(this.state.order.id).update({stage: 'complete'}).then(res => {
        this.loadOrderData();
        message.success("Order Complete!");
        this.setState({isMovingOrder: false})
      })
    })
  }


  render() {
    let { isNewOrder } = this.props;
    let { order, isEdit, loading, previewVisible, previewImage } = this.state;
    if(isNewOrder) isEdit = true;
    
    let canSendInovice = ['active', 'pendingPayment'].includes(order.stage);


    const uploadButton = (
      <div>
        {this.state.photoUploading ? <ClipLoader color={colors.lightText} size={25} /> : <PlusOutlined size={25} />}
        <div className="ant-upload-text">{this.state.photoUploading ? "Uploading..." : "Upload"}</div>
      </div>
    );



    const customerInfo = (
      <DashboardSquare title='Customer Info'>
       {/* <DataItem label=" " /> */}
       <SquareSection>
        <DataItem
          isEdit={isEdit}
          value={order.customerName}
          onChangeText={(t) => this.changeOrder("customerName", t)}
          label="Name"
        />
      </SquareSection>
        {/* <div style={{height: 10, width: 10, backgroundColor: 'red',}} /> */}
        <SquareSection>
          <DataItem
            isEdit={isEdit}
            value={(order.customerPhoneCountryCode ? '+'+order.customerPhoneCountryCode+' ': '')+order.customerPhone}
            onChangeText={(t) => this.changeOrder("customerPhone", t)}
            regex={/^\d{0,10}$/}
            label="Phone"
            children={
              <div style={styles.phoneRow}>
                <Input onChange={(e) => this.changeOrder('customerPhoneCountryCode', e.target.value)} value={order.customerPhoneCountryCode} placeholder='1' style={styles.input} style={{width: 80, marginRight: 10}} prefix='+' />
                <Input onChange={(e) => this.changeOrder('customerPhone', e.target.value)} value={order.customerPhone} style={styles.input} />
              </div>
            }
            buttons={
              isMobile
                ? [
                    {
                      label: "Call",
                      onClick: this.callPhone,
                      icon: <PhoneOutlined />,
                    },
                    {
                      label: "Text",
                      onClick: this.textPhone,
                      icon: <MessageOutlined />,
                    },
                  ]
                : []
            }
          />
        </SquareSection>

          <SquareSection>
            <DataItem
              isEdit={isEdit}
              value={order.customerEmail}
              onChangeText={(t) => this.changeOrder("customerEmail", t)}
              label="Email"
              buttons={[
                {
                  label: "Send Email",
                  onClick: this.sendEmail,
                  icon: <MailOutlined />,
                },
              ]}
            />
          </SquareSection>

          <SquareSection>
            <DataItem
              isEdit={isEdit}
              value={order.customerAddress.formatted_address}
              // onChangeText={(t) => this.changeOrder("customerEmail", t)}
              label="Address"
              buttons={[
                {
                  label: "Open in Maps",
                  onClick: this.openMaps,
                  icon: <img src={mapIcon} style={{ height: 18, width: 18, marginRight: 6 }} />,
                },
              ]}
            >
              <AddressAutoComplete onSelectAddress={x => this.changeOrder('customerAddress', x)} value={order.customerAddress.dis} />
            </DataItem>
          </SquareSection>

      </DashboardSquare>
    )

    const orderInfo = (
      <DashboardSquare title='Order Info'>
        <AddToCalendarModal
          visible={this.state.calendarModalVisible}
          onCancel={() => this.setState({calendarModalVisible: false})}
          order={order}
        />
        <SquareSection>
          <DataItem
          label='Scheduled For'
          value={order.scheduledFor ? dayjs(new Date(order.scheduledFor)).format("MMM DD, YYYY hh:mm a") : 'not scheduled'}
          isEdit={isEdit}
          buttons={order.scheduledFor ? [{
              label: 'Add to Calendar',
              icon: <CalendarOutlined />,
              onClick: () => this.setState({calendarModalVisible: true})
            }] : undefined}
          >
            <DateTimeInput 
              value={order.scheduledFor}
              onChange={this.onChangeScheduledFor}
            />
          </DataItem>
        </SquareSection>

      <SquareSection>
        <DataItem
          isEdit={false}
          value={order.customerAdditionalNotes}
          // onChangeText={(t) => this.changeOrder("customerEmail", t)}
          label="Notes from Customer"
        />
      </SquareSection>

        <SquareSection width={'100%'}>
          <DataItem
            isEdit={isEdit}
            // onChangeText={(t) => this.changeOrder("customerEmail", t)}
            label="Photos"
            alwaysShowChildren={true}
          >
            {loading ? null : (
              <>
                <Upload
                  disabled={isEdit ? false : true}
                  customRequest={this.uploadPhoto}
                  listType="picture-card"
                  fileList={this.state.order.photos.map((x) => ({ ...x, uid: x.id }))}
                  onPreview={this.handlePreview}
                  // onChange={this.onChangePhotos}
                  onRemove={this.onClickRemovePhoto}
                >
                  {order.photos.length >= 25 || !isEdit ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={() => this.setState({ previewVisible: false })}>
                  <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
              </>
            )}
          </DataItem>
        </SquareSection>


        <SquareSection width='100%'>
          <DataItem
            isEdit={isEdit}
            label="Requested Services"
            alwaysShowChildren={true}
          >
            {isEdit ? 
            <div style={styles.addServiceButtonRow}>
              <Button size='small' onClick={() => this.setState({showSelectServiceModal: true})}>+ Add Service</Button>
            </div>
            : null}
            <SelectServiceModal
              onSelectService={this.onSelectService} 
              visible={this.state.showSelectServiceModal}
              close={() => this.setState({showSelectServiceModal: false})}
            />
              <DraggableList
                itemKey={"id"}
                template={ItemBox}
                list={order.requestedServices}
                onMoveEnd={this.onServiceListChange}
                constrainDrag={true}
                padding={isEdit ? 20 : 10}
                autoScrollMaxSpeed={0}
                commonProps={{
                  onChange: (itemId, property, val) => this.onChangeService(itemId, property, val),
                  onDelete: itemId => this.onDeleteServiceItem(itemId),
                  isEdit: isEdit
                }}
              />
          </DataItem>
        </SquareSection>

        <SquareSection width={'100%'}>
          <DataItem alwaysShowChildren={true} label="My Order Notes" desc="(Customer can not see these)">
            <PopupPill show={this.state.showDidSaveNotesMessage} message="Saved Notes!" />
            <Input.TextArea value={this.state.order.myNotes} onChange={this.onChangeMyNotes} rows={10} />
          </DataItem>
        </SquareSection>


        {isNewOrder ? null : 
          <DataItem
            isEdit={false}
            value={dayjs(new Date(order.createdAt)).format("MMM DD, YYYY hh:mm a")}
            // onChangeText={(t) => this.changeOrder("customerEmail", t)}
            label="Order Created At"
          />
        }
      </DashboardSquare>
    )

    const pageContent = (
      <>
        
        {customerInfo}
        {orderInfo}
        {isNewOrder || order.stage == 'quote' ? null :
          <InvoiceSection
            orderId={this.props.match.params.orderId}
            order={this.state.order}
            orderLoading={this.state.loading}
            onChangeLineItems={this.onChangeLineItems}
          />
        }
      </>
    );

    const shouldShowStageDropdown = ['active', 'pendingPayment', 'complete', 'canceled'].indexOf(order.stage) > -1;

    return (
      <div style={styles.wrapper}>
        <OrdersNavbar  />
        <AcceptAndScheduleModal
          visible={this.state.isAcceptAndScheduleModalVisible} 
          onCancel={() => this.setState({isAcceptAndScheduleModalVisible: false})}
          onSchedule={this.onScheduleJobFromModal}
          isScheduleOnly={this.state.acceptAndScheduleModalType === 'schedule'}
         />

        <div style={styles.page}>
          <div style={styles.pageTop}>
            <div style={styles.topLeft}>
              <div style={styles.viewOrderTitle}>
                <img src={documentIcon} style={styles.documentIcon} />
                {this.props.isNewOrder ? 'Create New Order' : 'View Order'}
              </div>
            </div>

            <div style={styles.topRight}>
            {shouldShowStageDropdown && isEdit ? 
              <Select style={styles.stageDropdown} value={order.stage} onChange={x => this.changeOrder('stage', x)}>
                <Select.Option value='active'>Active</Select.Option>
                <Select.Option value='pendingPayment'>Pending Payment</Select.Option>
                <Select.Option value='complete'>Paid</Select.Option>
                <Select.Option value='canceled'>Canceled</Select.Option>
              </Select>
              :null}

              {order.stage === 'active' && !isEdit ? 
                <>
                  <Button size={isMobile ? "small" : undefined} onClick={this.moveToPendingPayment} icon={<ArrowRightOutlined />}  loading={this.state.isMovingOrder} disabled={isEdit}>
                    Move to "Pending Payment"
                  </Button>
                  <div style={{ width: 12 }} />
                </>
              :null}

              {order.stage === 'pendingPayment' && !isEdit ? 
                <>
                  <Button size={isMobile ? "small" : undefined} onClick={this.markCompleteAndPaid} icon={<CheckOutlined />}  loading={this.state.isMovingOrder} disabled={isEdit}>
                    Mark Complete & Paid
                  </Button>
                  <div style={{ width: 12 }} />
                </>
              :null}

              {isNewOrder ? 
              <>
                <Button size={isMobile ? "small" : undefined} type="primary" onClick={this.onCreateNewOrder} loading={this.state.isCreatingNewOrder}>
                  Create Order
                </Button>
              </>
              :
              <>
                {isEdit ? (
                  <Button size={isMobile ? "small" : undefined} type="primary" onClick={this.onSaveChanges} loading={this.state.isSaving}>
                    Save
                  </Button>
                ) : (
                  <Button size={isMobile ? "small" : undefined} onClick={this.onEdit} icon={<EditOutlined />}>
                    Edit
                  </Button>
                )}
              </>
              }

              {/* {canSendInovice ? 
              <>
                <div style={{ width: 12 }} />
                <Button size={isMobile ? "small" : undefined} onClick={this.onSendInvoiceButtonClick} icon={<CreditCardOutlined/>}  disabled={isEdit}>
                  Send Invoice
                </Button>
                </>
              :null} */}

              {order.stage === 'quote' ? 
              <>
              <div style={{ width: 12 }} />
                <Button size={isMobile ? "small" : undefined} onClick={this.onAccept} icon={<CheckOutlined />}  disabled={isEdit}>
                  Accept Job
                </Button>
              <div style={{ width: 12 }} />
              <Popconfirm
                placement="leftBottom"
                title={<div style={{maxWidth: 320}}>This will move the job to your "Cancelled" orders. The customer will NOT be notified. Are you sure? </div>}
                onConfirm={this.onRejectJob}
                okText="Reject"
                okButtonProps={{type: 'danger'}}
                cancelText="No"
              >
              <Button type='dashed' danger size={isMobile ? "small" : undefined} disabled={isEdit}  >
                Reject
              </Button>
              </Popconfirm>
                </>
              :null}
            </div>
          </div>
          <div style={styles.pageContent}>{loading ? <Loader loading={true} /> : pageContent}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    position: "relative",
    overflow: "hidden",
  },
  pageTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    // borderBottom: "1px solid " + colors.lightBG,
    // marginBottom: 15,
    paddingBottom: 3,
    flexWrap: "wrap",
    boxShadow: "0 4px 2px -2px rgba(0,0,0,.05)",
  },
  topLeft: {
    // flex: 0.25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
  },
  topCenter: {
    flex: 0.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  topRight: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "flex-end",
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "flex-end",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  page: {
    width: "100%",
    // height: '100%',
    maxWidth: 800,
    padding: 10,
    paddingTop: 0,
    // flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    paddingRight: 8,
    paddingTop: 10,
    paddingBottom: 10
  },
  pageTitle: {
    fontSize: 22,
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 20,
  },
  viewOrderTitle: {
    fontSize: 20,
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  documentIcon: {
    height: 30,
    marginRight: 7,
  },
  backButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  backText: {},
  addServiceButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10
  },

  collapseTitle: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  stageDropdown: {
    width: 200,
    marginRight: 10
  },
  phoneRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  }
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
  loggedInUser: state.loggedInUser,
  currentUser: state.currentUser
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(ViewOrderPage);
