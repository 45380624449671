export default {
 editorInputs: [
    {
      label: "Dark Mode",
      type: "bool",
      dataKey: "darkMode",
    },
    {
      label: "Title",
      type: "string",
      dataKey: "title",
    },
    {
      label: "Description",
      type: "string",
      dataKey: "desc",
      inputProps: {
        rows: 4
      }
    },
    {
      label: "Image",
      type: "image",
      dataKey: "imageUrl",
      inputProps: {
        compact: true,
      },
    },
    {
      label: "Reversed?",
      type: "bool",
      dataKey: "reversed",
      info: 'Swap the text to the right side, and the image to the left side.',
    },
    {
      label: "Link to 'Request Service' button?",
      type: "bool",
      dataKey: "hasRequestServiceCTA",
      info: 'Show a button that will link to your "Request Service" form.',
    },
    {
      label: "Request Service Button Color",
      type: "color",
      dataKey: "requestServiceCTAButtonColor",
      info: '',
      dependsOn: 'hasRequestServiceCTA'
    },
    {
      label: "Request Service Button Text",
      type: "text",
      dataKey: "requestServiceCTAButtonText",
      info: '',
      dependsOn: 'hasRequestServiceCTA',
    },
    {
      label: "Background Shape?",
      type: "bool",
      dataKey: "hasBgShape",
      info: 'Add a triangular shape to the background for better looks',
    },
  ],
};
