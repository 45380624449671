import React, { Component } from 'react';
import { Progress, Button } from 'antd';
import { withRouter } from 'react-router';

// sections
import SiteInfo from './sections/SiteInfo';
import LogoSection from './sections/LogoSection';
// import ChoosePageSections from './sections/ChoosePageSections';
import IconFeatures from './sections/IconFeatures';
import EditScreenshot2 from './sections/EditScreenshot2';
import Pricing from './sections/Pricing';
import EditTestimonials from './sections/EditTestimonials/index';
import EditSignupBanner from './sections/EditSignupBanner';
import colors from '../../colors';
import { getSectionByName } from 'mvptester-templates';
import { connect } from 'react-redux';
import EditNavbar from './sections/EditNavbar';
import EditorRenderer from './EditorRenderer';
import editor_definitions from './editor_definitions';
import EditPricingWithDetails from './sections/EditPricingWithDetails/EditPricingWithDetails';
import EditPhotoGallery from './sections/EditPhotoGallery/EditPhotoGallery';

// THIS IS LEGACY / or for super custom editors. use editor_definitions instead now. 
// these keys should coorespond to dataKey in sectionsIndex.js in templates codebase
const defaultDataKeyToEditorsMapping = {
  navbar: <EditNavbar />,
  siteInfo: <SiteInfo />,
  testimonials: <EditTestimonials />,
  features: <IconFeatures />,
  screenshot2: <EditScreenshot2 />,
  pricing: <Pricing />,
  emailCollection: <EditSignupBanner />,
  pricingWithDetails: <EditPricingWithDetails />,
  photoGallery: <EditPhotoGallery />,

};

class EditorSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { currentSectionName, currentSectionUid, isNewSite } = this.props;
    const { steps, isSaving } = this.state;
    let dataKeyToUse = getSectionByName(currentSectionName).defaultDataKey;
    if(!dataKeyToUse) dataKeyToUse = currentSectionName

    let EditorElement = defaultDataKeyToEditorsMapping[dataKeyToUse];
    if(!EditorElement) EditorElement = <EditorRenderer />;

    return(
      <div style={styles.wrapper}>
        <div style={styles.middle}>
          {React.cloneElement(EditorElement, {
            uid: currentSectionUid,
            sectionData: this.props.templateData[currentSectionUid],
            editorData: editor_definitions[dataKeyToUse] || {items: []}
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    zIndex: 3,
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 55,
    // position: 'absolute'
  },
  top: {
    padding: 20,
    paddingBottom: 0
  },
  middle: {
    flex: 1,
    display: 'flex',
    padding: 10,
    paddingBottom: 0,
    position: 'relative',
    // height: '100%',
    // maxHeight: '100%'
  },
  bottom: {
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#f7f7f7'
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
    width: '100%'
  },
  bottomContainerEdit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingTop: 10
  },
  nextButton: {
    width: 150
  },
  stepTitle: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: 'Montserrat'
  },
  stepDescription: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'

    // fontSize: 18
    
  },
  backButton: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    color: colors.text
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(EditorSidebar);

// export default withRouter(EditorSidebar);