import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import DashboardSidenav from "../../components/DashboardSidenav";
import SiteCard from "../../components/SiteCard";
import PageContentContainer from "../../components/containers/PageContentContainer";
import { db } from "../../firebase";
// import template_state from '../../redux/template_state';
import { updateSiteData, updateTemplateData, updateLoading } from 'redux/actions';
import { generateTemplate } from "mvptester-templates";
import shortid from "shortid";
import generateSiteName from "../../helpers/generateSiteName";
import { isMobile } from "react-device-detect";
import SplashMobileWarning from "../../components/SplashMobileWarning";
import { ClipLoader } from "react-spinners";
import Loader from "../../components/Loader";
import linkToSubdomain from "../../helpers/linkToSubdomain";

class MySitesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      loading: true
    };
  }

  onClickSite = site => {
    this.props.updateLoading({siteData: true}); // this will get set to false by <URLListener /> once it loads the site
    if(this.props.config.appType === 'orders'){
      this.props.history.push(`/site/${site.id}/orders`);
    }else{
      this.props.history.push("/site/" + site.id);
    }
  };

  componentDidMount() {
    this.getSites();
    // clear any old site data
    this.props.updateSiteData({});
    this.props.updateTemplateData({});
  }

  getSites = () => {
    db.collection("sites")
      .where("userId", "==", this.props.currentUser.id )
      .get()
      .then(res => {
        this.setState({
          sites: res.docs.map(x => {
            return { ...x.data(), id: x.id };
          }),
          loading: false
        });
      });
  };

  addNewSite = () => {
    if(this.state.sites.length>4) return alert("You are limited to 5 websites per account. If you need to create more than this for some reason, please contact support@"+this.props.config.data.supportEmailDomain+'.com')
    this.setState({loadingNewSite: true});
    let siteName = generateSiteName();
    db.collection("sites").add({
      data: generateTemplate(this.props.config.data.templateName), 
      userId: this.props.currentUser.id,
      siteName: siteName,
      builderDomain: this.props.config.data.siteNameValue,
      companyEmail: this.props.currentUser.email,
      uniqueSiteName: shortid.generate().toLowerCase(),
      services: {
        list: []
      }

    })
    .then(docRef => {
      let { id } = docRef;
      this.setState({loadingNewSite: false})
      this.props.history.push(`/site/${id}`)
    })
    .catch(function(error) {
      this.setState({loadingNewSite: false})
    });
  }

  render() {
    const { config } = this.props;
    const { loading } = this.state;

    return (
      <div style={styles.wrapper}>
        {config.appType === 'orders' ? null : <DashboardNavbar />}
        
        <PageContentContainer maxWidth={1150}>
          <h1 style={styles.title}>{config.appType === 'orders' ? 'Select the site' : 'My Sites'}</h1>
          <hr />
          <div style={styles.siteCardRow}>
            {loading ? <Loader loading/> : 
            <>
              {this.state.sites.map(site => {
                return (
                  <SiteCard
                    color={site.data.styles.mainColor}
                    title={site.siteName || "New Site"}
                    faviconUrl={site.faviconUrl}
                    onClick={() => this.onClickSite(site)}
                  />
                );
              })}
              {config.appType === 'orders' ? null :  <SiteCard newSite={true} onClick={this.addNewSite} loading={this.state.loadingNewSite}/> }
              {config.appType === 'orders' && this.state.sites.length===0 && !loading ? 
              <div style={styles.noSitesWrapper}>
                <div style={styles.noSitesMessage}>You haven't created a website yet. Please visit <a href={linkToSubdomain('app')}>the website builder</a> and create a site first.</div>
              </div>
              :null}
            </>
            }
          </div>
        </PageContentContainer>
      </div>
    );
  }
}

const styles = {
  siteCardRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    margin: 10,
    paddingTop: 10,
    flexWrap: 'wrap',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontFamily: 'Montserrat',
    marginLeft: 10,
    // marginBottom: 30
  },
  noSitesMessage: {
    textAlign: 'center',
    maxWidth: 400
  },
  noSitesWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  currentUser: state.currentUser,
  config: state.config
});
const mapActionsToProps = {
  updateSiteData,
  updateTemplateData,
  updateLoading
};
export default connect(mapStateToProps, mapActionsToProps)(MySitesPage);
