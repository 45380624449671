import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';

class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDateValue = (dateToUse) => {
    let ts = dateToUse;
    if(!ts) return undefined;
    let date = new Date(ts);
    let str = dayjs(date).format("YYYY-MM-DD");
    return str
  }

  getRangeValue = () => {
    return([
      this.getDateValue(this.props.value[0]),
      this.getDateValue(this.props.value[1])
    ])
  }

  getTimeValue = () => {
    let ts = this.props.value;
    if(!ts) return undefined;
    let date = new Date(ts);
    let str = dayjs(date).format("HH:mm");
    return str
  }

  // this is necessary because certain browsers cannot parse the date str if i just build it from the values returned form the inputs
  buildDate(dateStr, timeStr){
    let d = new Date();
    if (!dateStr && !timeStr) return undefined;

    if((dateStr || '').includes('-')){
      let dateArr = dateStr.split('-');
      let year = parseInt(dateArr[0])
      let month = parseInt(dateArr[1]-1)
      let day = parseInt(dateArr[2]);
      d.setFullYear(year);
      d.setMonth(month);
      d.setDate(day)
    }

    if((timeStr || '').includes(':')){
      let h = timeStr.split(':')[0];
      let m = timeStr.split(':')[1];
      d.setHours(h);
      d.setMinutes(m);
    }

    return d.getTime()
  }

  onChangeDate = (data) => {
    if(!data) return this.props.onChange(null);
    if(!data.target.value) return this.props.onChange(null);

    let newDate = this.buildDate(data.target.value, this.getTimeValue());
    this.props.onChange(newDate);
  }

  onChangeDateRange = (val1, val2) => {
    let newRange = [this.props.value[0], this.props.value[1]];
    if(!val1 && !val2) return this.props.onChangeDateRange([null, null]);
    let d1 = this.buildDate(val1, null);
    if(d1) newRange[0] = d1;
    let d2 = this.buildDate(val2, null);
    if(d2) newRange[1] = d2;
    this.props.onChangeDateRange(newRange);
  }

  onChangeTime = (data) => {
    if(data.target.value){
      let newDate = this.buildDate(this.getDateValue(this.props.value), data.target.value);
      this.props.onChange(newDate);
    }else{
      this.props.onChange(null);
    }
  }

  render() {
    const { dateOnly, daterange } = this.props;

    if(daterange){
      return(
        <div style={styles.wrapper}>
          <Input type='date' style={styles.date} value={this.getRangeValue()[0]} onChange={e => this.onChangeDateRange(e.target.value, null)} key='rangeinput1' />
          <div style={styles.arrowWrapper}>
            <ArrowRightOutlined />
          </div>
          <Input type='date' style={styles.date} value={this.getRangeValue()[1]} onChange={e => this.onChangeDateRange(null, e.target.value)} key='rangeinput2' />
        </div>
      )
    }
    return(
      <div style={styles.wrapper}>
        <Input type='date' style={styles.date} value={this.getDateValue(this.props.value)} onChange={this.onChangeDate} />
        {dateOnly ? null :
        <>
          <div style={{width: 15}} />
          <Input type='time' style={styles.time} value={this.getTimeValue()} onChange={this.onChangeTime} />
        </>
        }
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  date: {
    minHeight: 32,
    width: 170
    // flex: .6

  },
  time: {
    minHeight: 32,
    width: 140
    // flex: .4

  },
  arrowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 7,
    paddingRight: 7
  }
};  

export default DateTimeInput;