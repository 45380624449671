import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logo-header.png";
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebaseApp from '../api';
import firebase from "firebase";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {} from "redux/actions";
import colors from '../colors';
// import 'firebase/auth';
// const uiConfig = {
//   // Popup signin flow rather than redirect flow.
//   // signInFlow: 'popup',
//   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/dashboard',
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     // firebase.auth.FacebookAuthProvider.PROVIDER_ID
//     firebase.auth.EmailAuthProvider.PROVIDER_ID
//   ]
// };

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      forgotPasswordEmail: ''
    };
  }

  onClickForgotPassword = () => {
    this.setState({ forgotPasswordModalVisible: true });
  };

  handleLoginClick = () => {
    const { email, password } = this.state;
    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        var ec = error.code;
        if (ec.includes("invalid-email")) return alert("Invalid email");
        if (ec.includes("wrong-password")) return alert("Invalid password");
        if (ec.includes("user-not-found")) return alert("No account found for " + email);
        alert("Login error. Please make sure your email and password are correct, and try again.");
      });
  };

  checkEnter = (e) => {
    if (e.key === "Enter") this.handleLoginClick();
  };

  closeForgotPasswordModal = () => {
    this.setState({forgotPasswordModalVisible: false});
  }

  onSubmitForgotPassword = () => {
    if(this.state.forgotPasswordEmail.includes('@')){
      this.setState({forgotPasswordLoading: true});
      firebase.auth().sendPasswordResetEmail(this.state.forgotPasswordEmail).then(() => {
        this.setState({forgotPasswordLoading: false});
        alert(`If a user with the email ${this.state.forgotPasswordEmail} exists, we just emailed a link to reset the password. Please allow a few minutes for the email to arrive.`)
      }).catch(function(error) {
        this.setState({forgotPasswordLoading: false});
        alert(`No user found with email: ${this.state.forgotPasswordEmail}. Please double check the email, and make sure this is the same email address you used to sign up. If you are still having trouble, you can contact support@bluecollarpages.com for help`)
      });
    }
  }

  render() {
    const { loading } = this.state;
    // return(
    //   <div>
    //     <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    //   </div>
    // )
    let signupUrl = `https:///app.${this.props.config.data.siteNameValue}.com/signup`;

    return (
      <div style={styles.wrapper}>
        <Modal
          okButtonProps={{
            disabled: this.state.forgotPasswordEmail.includes('@') ? false : true
          }}
          okText='Submit'
          title="Forgot Password"
          visible={this.state.forgotPasswordModalVisible}
          onOk={this.onSubmitForgotPassword}
          onCancel={this.closeForgotPasswordModal}
          // width={1000}
          bodyStyle={styles.modalBody}
        >
          <div style={{marginBottom: 10}}>Enter your email address, and we will send you a link to change your password</div>
          <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} onChange={e => this.setState({forgotPasswordEmail: e.target.value})} value={this.state.forgotPasswordEmail} />
        </Modal>

        <div style={styles.form}>
          <div style={styles.logoRow}>
            <img src={this.props.config.data.bigLogoUrl} style={styles.logo} />
          </div>
          {this.props.config.appType === 'orders' ?  <div style={styles.orderTitle}>ORDER MANAGEMENT PANEL</div> : null}
          <h2 style={{ textAlign: "center" }}>Log In</h2>
          <div style={styles.inputs}>
            <Input
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
              style={{ marginBottom: 15 }}
              onChange={(e) => this.setState({ email: e.target.value })}
              value={this.state.email}
              autoCapitalize={'off'}
            />
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              style={{ marginBottom: 15 }}
              onChange={(e) => this.setState({ password: e.target.value })}
              value={this.state.password}
              onKeyDown={this.checkEnter}
            />
            <Button type={"primary"} block onClick={this.handleLoginClick} loading={loading}>
              Submit
            </Button>
            <br />
            <br />
            <br />
            <div style={styles.bottomRow}>
              <a role='button' onClick={this.onClickForgotPassword}>
                Forgot Password?
              </a>
              <a href={signupUrl} target="_blank">
                Sign Up
              </a>
            </div>
          </div>
         
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 100,
  },
  form: {
    // maxWidth: 300,
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
  },
  logo: {
    height: 40,
    marginBottom: 30,
  },
  logoRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  orderTitle: {
    color: colors.lightText,
    letterSpacing: 1,
    fontSize: 20,
    marginBottom: 10
  },
  inputs: {
    maxWidth: 300
  }
};

const mapStateToProps = (state) => ({
  config: state.config,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(LoginPage);
