import React, { Component } from 'react';
import { Spin } from 'antd';
import logo from 'assets/img/logo.png';

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({show: true})
    }, 800);
  }

  render() {
    if(!this.state.show) return null
    return(
      <div style={styles.wrapper}>
        <div style={styles.inside}>
          <img src={logo} style={styles.image} />
            <Spin />
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flex :1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh'
  },
  inside: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 80,
    marginBottom: 30
  }
};

export default SplashScreen;