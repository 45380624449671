import React, { Component } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebaseApp from '../api';
import firebase from "firebase";
import { db } from '../firebase';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";

// import 'firebase/auth';
// const uiConfig = {
//   // Popup signin flow rather than redirect flow.
//   // signInFlow: 'popup',
//   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/dashboard',
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     // firebase.auth.FacebookAuthProvider.PROVIDER_ID
//     firebase.auth.EmailAuthProvider.PROVIDER_ID
//   ]
// };
class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  handleSubmitClick = () => {
    const { email, password, password2 } = this.state;
    if(!email.includes('@') ){
      return alert("Invalid email address. Please double check the email address and try again.")
    }
    // if (password !== password2) return alert("Passwords do not match. Please re-type your passwords in both boxes, and try again.")
    if(password.length<5) return alert("Invalid password. Please use a longer password");

    this.setState({loading: true})

    firebase.auth().createUserWithEmailAndPassword(email, password).catch(error => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setState({loading: false})
      alert("Error: "+error.message)
      // ...
    });


  };



  render() {
    // return(
    //   <div>
    //     <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    //   </div>
    // )

    return (
        <div style={styles.form}>
          {/* <div style={styles.logoRow}>
            <img src={this.props.config.data.headerLogoUrl} style={styles.logo} />
          </div> */}
          <h2 style={styles.title}>Start Your Free Trial</h2>
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            style={{ marginBottom: 15 }}
            onChange={e => this.setState({ email: e.target.value })}
            value={this.state.email}
          />
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Choose password"
            style={{ marginBottom: 15 }}
            onChange={e => this.setState({ password: e.target.value })}
            value={this.state.password}
          />
          {/* <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Confirm Password"
            style={{ marginBottom: 15 }}
            onChange={e => this.setState({ password2: e.target.value })}
            value={this.state.password2}
          /> */}
          <Button type={"primary"} block onClick={this.handleSubmitClick} loading={this.state.loading} disabled={this.state.loading}>
            START FREE TRIAL
          </Button>
          {/* <br />
          <br />
          <br />
          <div style={styles.bottomRow}>
            <span>Or, <Link to="/login">Log In</Link></span>
          </div> */}
        </div>
    );
  }
}

const styles = {

  form: {
    maxWidth: isMobile ? '100%' : 400,
    padding: isMobile ? 20 : 30,
    margin: 15,
    borderRadius: 4,
    boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
    backgroundColor: '#fff',
    borderRadius: 3
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    display: "flex"
  },

  logoRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Montserrat'
  }
};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SignupForm);
