import React, { Component } from "react";
import mediaTypes from "./mediaTypes";
import { Select } from "antd";
const { Option } = Select;

class MediaTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { value, onChange } = this.props;
    if(!value) value = mediaTypes[0].value
    return (
      <Select
        defaultValue={mediaTypes[0].value}
        style={{ width: 120 }}
        onChange={onChange}
        value={value}
      >
        {mediaTypes.map(item => {
          return <Option value={item.value}>{item.label}</Option>;
        })}
      </Select>
    );
  }
}

const styles = {};

export default MediaTypeDropdown;
