import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTemplateData } from 'redux/actions';
import EditableItemCard from '../../../EditableItemCard';
import InputText from 'components/InputText';
import AddButtonRow from '../../AddButtonRow';
import EditSectionClass from '../shared/EditSectionClass';
import { Switch } from 'antd';
import EditorInputSection from '../../../input_sections/EditorInputSection';

class EditTestimonials extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (e, index, key) => {
    let newItems = [...this.props.sectionData.items]
    newItems[index][key] = e.target.value;
    this.editData({items: newItems})
  }

  onCloseTestimonial = (index) => {
    let newItems = [...this.props.sectionData.items];
    newItems.splice(index, 1)
    this.editData({items: newItems});
  }

  addTestimonial = () => {
    let newItems = [...this.props.sectionData.items];
    newItems.push({})
    this.editData({items: newItems});
  }

  render() {
    const { templateData, sectionData } = this.props;
    return(
      <div style={styles.wrapper}>
        <EditorInputSection label={'Dark Mode'} >
          <Switch checked={sectionData.darkMode} onChange={val => this.editData({darkMode: val})} />
        </EditorInputSection>
        <InputText
          label={"Title"}
          inputProps={{
            onChange: (e) => this.editData({title: e.target.value}),
            value: sectionData.title,
          }}
        />
            <div style={{height: 30}} />

        {sectionData.items.map((t, index, key) => {
          return(
            <EditableItemCard onClose={() => this.onCloseTestimonial(index)}>
              <InputText
              label={"Name"}
              inputProps={{
                onChange: e => this.onChange(e, index, 'name'),
                value: t.name,
              }}
            />
            <InputText
              label={"Quote Text"}
              inputProps={{
                onChange: e => this.onChange(e, index, 'text'),
                value: t.text,
                rows: 4
              }}
            />

            <div style={styles.linkInputWrapper}>
            <InputText
              label={"Link Name"}
              inputProps={{
                onChange: e => this.onChange(e, index, 'linkText'),
                value: t.linkText,
              }}
              wrapperStyle={{flex: .49}}
            />
            <InputText
              label={"Link URL"}
              inputProps={{
                onChange: e => this.onChange(e, index, 'link'),
                value: t.link,
              }}
              wrapperStyle={{flex: .49}}
            />
            </div>

            </EditableItemCard>
          )
        })}
        <AddButtonRow label='Add Testimonial' onClick={this.addTestimonial} />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'scroll',
    padding: 10,
  },
  linkInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(mapStateToProps, mapActionsToProps)(EditTestimonials);