import { Alert, Button, Popconfirm, Input, message, Select } from "antd";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import InputText from "../../../components/InputText";
import DashboardInputSection from "../../../components/input_sections/DashboardInputSection";
import OrdersNavbar from "../../../components/orders/OrdersNavbar";
import { db, firebase } from "../../../firebase";
import { connect } from "react-redux";
import { updateCurrentUser } from "redux/actions";
import DashboardSquare from "../../../components/DashboardSquare";
import SquareSection from "../../../components/DashboardSquare/SquareSection";
import colors from "../../../colors";
import Dinero from 'dinero.js'
import dayjs from "dayjs";
import { updateMembershipInfo } from "../../../redux/actions/dispatchers";
import { currencies, phoneCodes } from "./country_data";
const { Option } = Select;

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stripeAccountInfo: {},
      subscription: {},
      paymentMethod: {},
      stripeAccountInfoLoading: true,
      payouts: [],
      subscriptionInfoLoading: true,
    };
  }

  async componentDidMount() {
    await this.loadOnboardingStatus();

    this.onboardingInterval = setInterval(() => {
      this.loadOnboardingStatus();
    }, 1000*15);

    if(this.props.currentUser.stripeSetupComplete){
      global.firebaseApi(`/getMyStripeAccountInfo`).then(res => {
        this.setState({stripeAccountInfo: res.account, stripeAccountInfoLoading: false})
      })
    }

    this.setState({ loading: false });
    this.loadPayouts();
    this.loadSubscriptionInfo()
  }

  componentWillUnmount = () => {
    if(this.onboardingInterval) clearInterval(this.onboardingInterval);
  }

  loadOnboardingStatus = () => {
    return new Promise(async resolve => {
      let onboardingRes = {};
      if (!this.props.currentUser.stripeSetupComplete) {
        onboardingRes = await global.firebaseApi("/getStripeOnboardingStatus"); // stripeSetupComplete will be checked and updated on the backend before this resolves
        let newUser = (await db.collection("users").doc(this.props.currentUser.id).get()).data();
        this.props.updateCurrentUser(newUser);
      }
      this.setState({ onboardingStatus: onboardingRes.status }, () => {
        resolve()
      });
    })

  }

  loadPayouts = () => {
    Dinero.defaultCurrency=this.props.currentUser.currency || 'USD';
    this.setState({loadingPayouts: true})
    global.firebaseApi(`/getAllPayouts`).then(res => {
      console.log('payouts res', res)
      let inTransit = Dinero();
      let toBePaid = Dinero();
      let otherCurrenciesUsed = [];
      res.payouts.data.forEach(p => {
        if(p.currency !== (this.props.currentUser.currency || 'usd').toLowerCase()){
          if(otherCurrenciesUsed.indexOf(p.currency)<0) otherCurrenciesUsed.push(p.currency)
        }else{
          if(p.status === 'in_transit') inTransit = inTransit.add(Dinero({amount: p.amount}));
        }
      })
      res.balance.pending.forEach(bal => {
        if(bal.currency !== (this.props.currentUser.currency || 'usd').toLowerCase()){
          if(otherCurrenciesUsed.indexOf(bal.currency)<0) otherCurrenciesUsed.push(bal.currency)
        }else{
          toBePaid = toBePaid.add(Dinero({ amount: bal.amount }))
        }
      })
      this.setState({
        payouts: res.payouts,
        inTransit: inTransit.toFormat('$0,0.00'),
        toBePaid: toBePaid.toFormat('$0,0.00'),
        loadingPayouts: false,
        hasMultipleCurreny: otherCurrenciesUsed.length>0 ? true : false,
        otherCurrenciesUsed
      })
    })
  }

  onClickSetupPayments = async () => {
    // backend creates an account if necessary, or just creates a  link for existing accts
    this.setState({loadingSetupStripe: true})
    global.firebaseApi("/getStripeOnboardingLink", {
        body: {
          currentUrl: window.location + "",
        },
      })
      .then((res) => {
        this.setState({loadingSetupStripe: false})
        window.location = res.onboardingLink;
      });
  };

  loadSubscriptionInfo = () => {
    if(this.props.membershipInfo.isMembershipActive){
      global.firebaseApi('/getSubscriptionInfo').then(res => {
        console.log('subscription res', res)
        this.setState({
          subscription: res.subscription,
          paymentMethod: res.paymentMethod,
          subscriptionInfoLoading: false
        })
      })

    }
  }

  onChangeCurrency = async (val) => {
    this.props.updateCurrentUser({...this.props.currentUser, currency: val});
    await db.collection("users").doc(this.props.currentUser.id).update({currency: val});
    this.loadPayouts()
  }

  getPaymentMethodVal = () => {
    const { paymentMethod } = this.state;
    console.log("pm", paymentMethod)
    try{
      return(`${paymentMethod.card.brand.toUpperCase()} ****${paymentMethod.card.last4}`)
    }catch(e){
      // console.warn("Payment method getting error: ", e)
    }
  }

  confirmUnsubscribe = () => {
    this.setState({subscriptionInfoLoading: true});
    global.firebaseApi('/cancelSubscription').then(async res => {
      let newUser = (await db.collection("users").doc(this.props.currentUser.id).get()).data();
      this.props.updateCurrentUser(newUser);
      this.loadSubscriptionInfo();
      updateMembershipInfo()
    })
  }

  onSignup = () => {
    let url = this.props.config.data.stripePaymentLink;
    window.location = url;
  }

  submitFeedback = () => {
    this.setState({isSubmittingFeedback: true});
    global.firebaseApi(`/contactSupport`, {body: {
      siteId: this.props.siteData.id,
      userId: this.props.loggedInUser.id,
      userEmail: this.props.loggedInUser.email,
      text: this.state.feedback
    }}).then(res => {
      this.setState({isSubmittingFeedback: false, feedback: ''});
      message.success('Feedback Sent!')
    })
  }

  render() {
    let { stripeSetupComplete } = this.props.currentUser;
    let { onboardingStatus, stripeAccountInfo, stripeAccountInfoLoading, subscription, paymentMethod } = this.state;
    const bankAccount = ((this.state.stripeAccountInfo.external_accounts || {}).data || [])[0] || {};


    const stripeSetupSection = (
      <DashboardSquare
        title={'Connect Your Bank'}
        loading={this.state.loading}
        desc={`In order to let you send invoices and process your payments, we partner with Stripe which powers millions of businesses around the world. You may see the name "DBR Software
        LLC" during the process - this is our official company name.`}
      >
      {onboardingStatus === 'waiting' ? 
        <Alert type='warning' showIcon style={{marginTop: 10}} message='We are currently verifying the information you entered. Please check back later to finish your setup.'/>
      :null}
      {onboardingStatus === 'in_progress' ? 
        <Alert type='info' showIcon style={{marginTop: 10}} message='It looks like you started setting up payments, but there is still some more information required. Please click the button below to finish entering any information required.'/>
      :null}
      {onboardingStatus === 'waiting' ? null : 
        <Button loading={this.state.loadingSetupStripe} type="primary" onClick={this.onClickSetupPayments} style={{marginTop: 15}}>
          Set Up Payments
        </Button>
      }
      </DashboardSquare>
    );

    const stripeCompleteSection = (
      <DashboardSquare
        loading={this.state.loading}
        title={'Your Bank Info'}
        loading={stripeAccountInfoLoading || this.state.loadingPayouts}
        desc={`Your payout schedule is ${(stripeAccountInfo.payout_schedule || {}).interval}, with a ${(stripeAccountInfo.payout_schedule || {}).delay_days} day delay. `}
      >
      {this.state.hasMultipleCurreny ? 
        <Alert type='warning' showIcon style={{marginBottom: 10}} message={`It looks like your current payouts have a mix of currencies, including ${JSON.stringify(this.state.otherCurrenciesUsed)}. We are only showing the payouts and balance for your currently selected currency: ${this.props.currentUser.currency || 'USD'}`}/>
      :null}
        <SquareSection 
          title={'Balance (to be paid out)'}
          value={this.state.toBePaid} 
          valueStyle={{color: '#00B289'}}
        />

        <SquareSection 
          title={'Payouts in transit to bank'}
          value={this.state.inTransit} 
          valueStyle={{color: '#00B289'}}
        />

        <SquareSection 
          title={'Connected Bank Account'}
          value={`${bankAccount.bank_name} ****${bankAccount.last4}`} 
        />

      </DashboardSquare>
    );

    const supportSection = (
      <DashboardSquare
      title={'Support'}
      loading={false}
      desc='If you have any feedback or questions, please reach out to support@bluecollarpages.com. We are constantly working to make this product even better suited to your needs!'
    >
      <Input.TextArea rows={4} placeholder='Your Feedback...' onChange={e => this.setState({feedback: e.target.value})} value={this.state.feedback}/>
      <Button style={{marginTop: 7}} onClick={this.submitFeedback} type='primary' disabled={!this.state.feedback} loading={this.state.isSubmittingFeedback}>Submit Feedback</Button>


    </DashboardSquare>
    )

    const countrySection = (
      <DashboardSquare
      title={'Country / Currency'}
      loading={false}
      desc=''
    >
        <SquareSection 
          title={'Currency'}
          value={
            <Select style={{width: 100}} value={this.props.currentUser.currency || 'USD'} onChange={this.onChangeCurrency}>
              {currencies.map(c => {
                return <Option key={c} value={c}>{c}</Option>
              })}
            </Select>
          } 
        />

        {/* <SquareSection 
          title={'Country Phone Code'}
                   value={
            <Select style={{width: 200}}  value={this.props.currentUser.countryPhoneCode || '+1'}>
              {phoneCodes.map(item => {
                return(
                  <Option key={item.code} value={item.code}>{item.code} - {item.name}</Option>
                )
              })}
            </Select>
          } 
        /> */}


    </DashboardSquare>
    )

    const subscriptionInfoSection = (
      <DashboardSquare title={`${this.props.config.data.siteNameDis} Subscription Info`} loading={this.state.subscriptionInfoLoading}>
        {this.props.currentUser.subscriptionHasBeenCancelled ? 
          <>
            <SquareSection
              title={"Status"}
              value={'Cancelled'}
            />
            <SquareSection
              title={"Subscription Ends"}
              value={dayjs(new Date(this.props.currentUser.subscriptionEndsAt)).format("MMM DD, YYYY")}
            />
            <SquareSection
              title={"Re-Subscribe"}
              value={<Button type='primary' onClick={this.onSignup}>Sign Up</Button>}
            />
          </>
        :
        <>
        <SquareSection
            title={"Payment Method"}
            value={this.getPaymentMethodVal()}
          />
          <SquareSection
            title={"Next Payment Date"}
            value={dayjs(new Date(subscription.current_period_end*1000)).format("MMM DD, YYYY")}
          />
          <SquareSection
            title={"Cancel Subscription"}
            value={
              <Popconfirm
                title={`Are you sure you want to unsubscribe? You will lose access to ${this.props.config.data.siteNameDis} on ${dayjs(new Date(subscription.current_period_end*1000)).format("MMM DD, YYYY")}`}
                onConfirm={this.confirmUnsubscribe}
                okText='UNSUBSCRIBE'
                okButtonProps={{type: 'danger'}} 
              >
                <Button type='text' danger >Unsubscribe</Button>
              </Popconfirm>
            }
          />
        </>
        }
      </DashboardSquare>
    )

    return (
      <div style={styles.wrapper}>
        <OrdersNavbar />

        <div style={styles.page}>
          <div style={styles.content}>
            {stripeSetupComplete ? stripeCompleteSection : stripeSetupSection}
            {countrySection}
            {this.props.membershipInfo.isMembershipActive ? subscriptionInfoSection : null}
            {supportSection}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  content: {
    width: isMobile ? "100%" : 700,
    paddingLeft: 10,
    paddingRight: 10
  },
  stripeTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  stripeDesc: {
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  currentUser: state.currentUser,
  membershipInfo: state.membershipInfo,
  siteData: state.siteData,
  config: state.config
});
const mapActionsToProps = {
  updateCurrentUser,
};
export default connect(mapStateToProps, mapActionsToProps)(SettingsPage);
