import { Button, Input, message, Popconfirm, Table, Tag, Upload } from "antd";
import React, { Component } from "react";
import OrdersNavbar from "../../../components/orders/OrdersNavbar";
import { db } from "../../../firebase";
import { connect } from "react-redux";
import colors from "../../../colors";
import TitleSection from "./TitleSection";
import { isMobile } from "react-device-detect";
import ItemBox from "./ItemBox";
import DraggableList from "react-draggable-list";
import shortid from "shortid";
import Loader from "../../../components/Loader";

class MyServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      services: {
        list: []
      }
    };
  }

  componentDidMount(){
    this.loadServices();
  }

  onChangeService = (key, property, val) => {
    let newServiceList = this.state.services.list.map((x, xIndex) => {
      if(x.serviceTypeKey === key){
        return({
          ...x,
          [property]: val
        })
      }else{
        return x
      }
    })
    this.setState({
      services: {
        ...this.state.services,
        list: newServiceList
      }
    })
    this.setState({dataHasChanged: true});
    this.resetSaveTimeout(3000);
  }

  getDefaultData = () => {
    return({
      title: 'New Service',
      desc: 'This is the description of your service',
      price: '$79',
      serviceTypeKey: shortid.generate()
    })
  }


  loadServices = () => {
    db.collection('sites').doc(this.props.siteData.id).get().then(docRef => {
      let data = docRef.data();
      this.setState({
        services: data.services ? data.services : this.props.config.data.defaultServices,
        loading: false
      })
    })
  }

  addService = () => {
    this.setState({dataHasChanged: true});
    this.setState({
      services: {
        ...this.state.services,
        list: [this.getDefaultData(), ...this.state.services.list]
      }
    })
  }

  save = () => {
    this.setState({isSaving: true})
    db.collection('sites').doc(this.props.siteData.id).update({
      services: this.state.services
    }).then(docRef => {
      this.setState({isSaving: false, dataHasChanged: false});
      message.success("Saved!")
    })
  }

  onListChange = newList => {
    this.setState({
      services: {
        ...this.state.services,
        list: newList
      }
    });
    this.resetSaveTimeout();
  }

  onDelete = serviceTypeKey => {
    this.setState({
      services: {
        ...this.state.services,
        list: this.state.services.list.filter(x => x.serviceTypeKey !== serviceTypeKey)
      }
    })
    this.resetSaveTimeout();
  }

  resetSaveTimeout = (timeout) => {
    if(this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.save();
    }, timeout || 1000);
  }

  render() {


    return (
      <div style={styles.wrapper}>
        <OrdersNavbar />
        <div style={styles.page}>
          {/* {isMobile ? mobileContent : pageContent} */}
          <div style={styles.pageContent}>
        <div style={styles.pageTitle}>My Services</div>
        <div style={styles.pageDesc}>These services will be available for your customers to view & select from on your website.</div>
          <div style={styles.buttonRow}>
            <Button onClick={this.addService}>+ Add Service</Button>
            <div style={{width: 15}}></div>
            <Button onClick={this.save} type='primary' loading={this.state.isSaving}>Save Changes</Button>
          </div>
          <div style={styles.list}>

            <DraggableList
              itemKey={"serviceTypeKey"}
              template={ItemBox}
              list={this.state.services.list}
              onMoveEnd={this.onListChange}
              // container={() => this.sectionCol}
              constrainDrag={true}
              padding={20}
              autoScrollMaxSpeed={0}
              commonProps={{
                onChange: (itemKey, property, val) => this.onChangeService(itemKey, property, val),
                onDelete: key => this.onDelete(key),
                isEdit: true,
                hideHasBeenInvoiced: true
              }}
            />
            {/* {this.state.services.list.map((s, index) => {
              return <ItemBox service={s} onChange={(id, val) => this.onChangeService(index, id, val)} />
            })} */}

          </div>
          {this.state.loading ? <Loader loading/> : null}
          {this.state.services.list.length===0 && !this.state.loading ? <div style={styles.noServicesMessage}>You have no services yet. Get started by adding some using the button above!</div> : null}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    overflow: "hidden",
  },
  page: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
  },
  pageContent: {
    maxWidth: isMobile ? "100%" : 700,
    flex: 1,
    display: 'flex',
    flexDirection: "column",
    display: "flex",
    overflow: "hidden",
    width: "100%",
    padding: 8,
    paddingTop: 0
  },
  list: {
    flex: 1,
    overflow: 'auto',
    width: '100%',
    padding: 10
  },
  message: {
    textAlign: "center",
    padding: 20,
  },
  pageTitle: {
    fontSize: 20,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: isMobile ? 'center' : 'flex-end',
    width: '100%',
    marginBottom: 20
  },
  pageDesc: {
    textAlign: 'center',
    marginBottom: 15
  },
  noServicesMessage: {
    textAlign: 'center',
    color: colors.lightText,
    marginTop: 50
  }
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
  loggedInUser: state.loggedInUser,
  config: state.config
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(MyServicesPage);
