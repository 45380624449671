import React, { Component } from 'react';
import { saveTemplateData } from '../../../../firebase/templateData';
import store from '../../../../redux/store';
import { connect } from 'react-redux';
import { updateTemplateData } from 'redux/actions';

class EditSectionClass extends Component {
  constructor(props) {
    super(props);
    // this.state = {};
    // this.styles = styles
  }

  updateTemplateData = (newData) => {
    store.dispatch(updateTemplateData(newData))
    if(this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.setState({isSaving: true})
      saveTemplateData().then(res => {
        this.setState({isSaving: false})
      })
    }, 2000);
  }

  /**
  * used by editor inputs, top level or on an item in a list
  * @param {Object} modifiedData - The data that is changing
  * @param {Number} nestedItemIndex - if this is a list item and not a top level property on this section, the index of the item
  * @param {String} nestedListKey - The key the list is under for the section, usually its "items" but sometimes i name it different. see editor_definitions, a type: items will have a dataKey that specifies this. 
  */
  editData = (modifiedData={}, nestedItemIndex, nestedListKey) => {
    let oldSectionData = {...this.props.templateData[this.props.uid]};
    let newTemplateData = {
      ...this.props.templateData,
      [this.props.uid]: {
        ...oldSectionData,
        ...modifiedData
      }
    }
    if(nestedItemIndex !== undefined){
      newTemplateData = {
        ...this.props.templateData,
        [this.props.uid]: {
          ...oldSectionData,
          [nestedListKey]: oldSectionData[nestedListKey].map((item, index) => {
            if(index === nestedItemIndex){
              return({
                ...item,
                ...modifiedData
              })
            }else{
              return item;
            }
          })
        }
      }
    }
    this.updateTemplateData(newTemplateData);
  }

  addItem = (dataKey) => {
    const { templateData, editorData, sectionData } = this.props;
    this.editData({[dataKey]: [...this.props.templateData[this.props.uid][dataKey], {}]})
  }

  removeItem = (index, dataKey) => {
    let newItems = this.props.templateData[this.props.uid][dataKey];
    newItems.splice(index, 1);
    this.editData({items: newItems});
  }

}

const styles = {

};


export default EditSectionClass