import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import colors from '../colors';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // show: props.loading,
      // shouldRender: props.loading
    };
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({canShow: true})
    }, 500);
  }

  // componentDidUpdate(prevProps, prevState){
  //   if(prevProps.loading && !this.props.loading){
  //     this.setState({show: false});
  //     setTimeout(() => {
  //       this.setState({shouldRender: false})
  //     }, 200);
  //   }else{
  //     if(this.state.show || this.state.shouldRender) this.setState({show: false, shouldRender: false})
  //   }
  // }

  render() {

    const { loading, small } = this.props;

    if(!loading || !this.state.canShow) return null;

    const styles = {
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: small ? 50 : 250,
        opacity: 1,
        transition: '.2s',
      }
    };

    return(
      <div style={styles.wrapper}>
        <ClipLoader color={colors.lightText} />
      </div>
    );
  }
}



export default Loader;