export default {
  pricing1: require('./pricing1.png'),
  carDetailHero: require('./carDetailHero.png'),
  hero2: require('./hero2.png'),
  hero1: require('./hero1.png'),
  meetOurTeam1: require('./meetOurTeam1.png'),
  contactUs1: require('./contactUs1.png'),
  imageServices: require('./imageServices.png'),
  features1: require('./features1.png'),
  footer1: require('./footer1.png'),
  photoGallery1: require('./photoGallery1.png'),
  screenshot2: require('./screenshot2.png'),
  emailCollection1: require('./emailCollection1.png'),
  websitePortfolio: require('./websitePortfolio.png'),
  pricingWithDetails: require('./pricingWithDetails.png'),
  testimonials1: require('./testimonials1.png'),
  videoSection1: require('./videoSection1.png'),
  orderPlacer1: require('./orderPlacer1.png'),
  bigFeature1: require('./bigFeature1.png'),

}