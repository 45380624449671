import React, { Component } from "react";
import { Radio } from 'antd';
import colors from "../../colors";

class HasMediaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      hasMedia,
      onChange,
      trueText = "I've got one",
      falseText = "Make me one!",
      label=''
    } = this.props;
    return (
      <div style={{...styles.hasLogoButtonsWrapper, alignItems: label ? 'flex-start' : 'center'}}>
        {label ? 
          <div style={styles.label}>{label}</div>
        :null}
        <Radio.Group value={hasMedia} onChange={e => onChange(e.target.value)}>
          <Radio.Button value={true}>{trueText}</Radio.Button>
          <Radio.Button value={false}>{falseText}</Radio.Button>
        </Radio.Group>
      </div>
    );
  }
}

const styles = {
  hasLogoButtonsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
  },
  label: {
    maxWidth: '50%',
    marginRight: 10,
    marginBottom :5,
    color: colors.lightText,
    fontSize: 12
  }
};

export default HasMediaSelect;
