import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import SignupBanner from "../components/SignupBanner";


// Pages
import Homepage from './Homepage';
import EditSitePage from './EditSitePage';
import MySitesPage from "./MySitesPage";
import SiteDashboardPage from "./SiteDashboardPage";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import NoRouteFound from "./NoRouteFound";

// Designers
import MyJobsPage from './designers/MyJobsPage';
import NewJobsPage from './designers/NewJobsPage';
import ViewJobPage from './designers/ViewJobPage';
// import ViewJobPage from './designers/ViewJobPage';

// Admin
import AdminOrdersPage from "./admin/AdminNavbar";
import AdminDesignersPage from "./admin/AdminDesignersPage";
import LoggedInUserRoute from "./LoggedInUserRoute";
import URLListener from "../redux/URLListener";

// Orders
import OrdersDashboard from "./orders/OrdersDashboard";
import ViewOrderPage from "./orders/ViewOrderPage";
import { isMobile } from "react-device-detect";
import SplashMobileWarning from "../components/SplashMobileWarning";
import SplashScreen from "../components/SplashScreen";
import MyServicesPage from "./orders/MyServicesPage";
import SettingsPage from "./orders/SettingsPage/SettingsPage";


class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isLoading = () => {
    // i handle the "initial" load in app.js whcih needs to be done before this, thats the logged in user
    let isLoading = false;
    if(this.props.loading.siteData) isLoading = true;
    return isLoading;
  }


  // if changing :params format for any route, be sure to reconfigure URLListener in redux folder

  
  render() {
    const { user, config } = this.props;

    if(isMobile && config.appType !== 'orders' && window.location.pathname.indexOf('signup')<-1 && window.location.pathname.indexOf('login')<-1) return <SplashMobileWarning />;
    let isLoading = this.isLoading();


    if(config.appType == 'orders'){
      return (
        <BrowserRouter>
        {isLoading ? null : <SignupBanner />}
        {isLoading ? <SplashScreen /> :
          <Switch>
            <LoggedInUserRoute exact path="/" component={MySitesPage} />
            <Route exact path="/login" render={p => user.email ? <Redirect to='/' /> : <LoginPage {...p}/> } />
            <LoggedInUserRoute exact path="/site/:siteId/orders" component={OrdersDashboard} />
            <LoggedInUserRoute exact path="/site/:siteId/order/:orderId" component={ViewOrderPage} />
            <LoggedInUserRoute exact path="/site/:siteId/neworder" render={p => <ViewOrderPage {...p} isNewOrder={true} />} />
            <LoggedInUserRoute exact path="/site/:siteId/myservices" component={MyServicesPage} />
            <LoggedInUserRoute exact path="/site/:siteId/settings" component={SettingsPage} />

            <Route component={NoRouteFound} />
          </Switch>
        }
          <URLListener />
        </BrowserRouter>
      );

    }else{
      return (
        <BrowserRouter>
        {isLoading ? null : <SignupBanner />}
        {isLoading ? <SplashScreen /> :
          <Switch>
            {/* Misc */}
            {/* <Route exact path="/" component={Homepage} />  i dont think we need a homepage anymore since app will be on a subdomain right? */}
            <LoggedInUserRoute exact path="/" component={MySitesPage} />
  
            <Route exact path="/login" render={p => user.email ? <Redirect to='/mysites' /> : <LoginPage {...p}/> } />
            <Route exact path="/signup" render={p => user.email ? <Redirect to='/mysites' /> : <SignupPage {...p}/> } />
  
  
            <Route exact path="/new" render={p => <EditSitePage {...p} isNewSite={true} /> } />
            <LoggedInUserRoute exact path="/mysites" component={MySitesPage} />
            <LoggedInUserRoute exact path="/site/:siteId" component={SiteDashboardPage} />
            <LoggedInUserRoute exact path="/site/:siteId/edit" render={p => <EditSitePage {...p} /> } />
  
            {/* DESIGNERS */}
            <Route exact path="/designer/login" render={p => <EditSitePage {...p} /> } />
            <Route exact path="/designer" render={p => <Redirect to='/designer/jobs/new' /> } />
            <Route exact path="/designer/jobs/new" render={p => <NewJobsPage {...p} /> } />
            <Route exact path="/designer/jobs/mine" render={p => <MyJobsPage {...p} /> } />
            <Route exact path="/designer/jobs/view/:id" render={p => <ViewJobPage {...p} /> } />
  
            {/* ADMIN */}
            <Route exact path="/admin" render={p => <Redirect to='/admin/orders/new' /> } />
            <Route exact path="/admin/designers" render={p => <AdminDesignersPage {...p} /> } />
            <Route exact path="/admin/orders/:filter" render={p => <AdminOrdersPage {...p} /> } />

            <Route component={NoRouteFound} />
          </Switch>
          }
          <URLListener />
        </BrowserRouter>
      );
    }

  }
}

const styles = {};

const mapStateToProps = state => ({
  user: state.loggedInUser,
  config: state.config,
  loading: state.loading
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Routes);
