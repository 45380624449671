import { combineReducers } from 'redux';
import * as types from '../types';
// import initialTemplateState from '../template_state';
import { mediaReducer } from './media';

const defaultTemplateState = {
  sections: []
}

export function templateDataReducer(state = defaultTemplateState, action) {
  switch (action.type) {
    case types.UPDATE_TEMPLATE_DATA:
      return(action.payload)
    default: return state;
  }
}

export function editsPendingReducer(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_EDITS_PENDING:
      return({
        ...state,
        ...action.payload
      })
    default: return state;
  }
}

export function siteDataReducer(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_SITE_DATA:
        let newSite = {...action.payload};
        delete newSite.data;
      return newSite;
    default: return state;
  }
}

export function loggedInUserReducer(state = {isLoading: true, loggedIn: false}, action) {
  switch (action.type) {
    case types.UPDATE_LOGGED_IN_USER:
      return action.payload;
    default: return state;
  }
}

const defaultMembershipState = {
  isLoading: true,
  membershipLevel: 0
}

export function membershipInfoReducer(state = defaultMembershipState, action) {
  switch (action.type) {
    case types.UPDATE_MEMBERSHIP_INFO:
      return action.payload;
    default: return state;
  }
}

export function miscReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_MISC:
      return action.payload;
    default: return state;
  }
}

export function configReducer(state = {data: {}, isLoading: true}, action) {
  switch (action.type) {
    case types.UPDATE_CONFIG_DATA:
      return ({...state, isLoading: false, data: action.payload});
    case types.UPDATE_APP_TYPE:
      return ({...state, appType: action.payload});
    default: return state;
  }
}

export function currentUserReducer(state = {isLoading: true}, action) {
  switch (action.type) {
    case types.UPDATE_CURRENT_USER:
      return({
        // ...state,
        ...action.payload,
        isLoading: false
      });
    default: return state;
  }
}

export function loadingReducer(state = {siteData: true}, action) {
  switch (action.type) {
    case types.UPDATE_LOADING:
      return({...state, ...action.payload});
    default: return state;
  }
}


const allReducers = combineReducers({
  templateData: templateDataReducer,
  loggedInUser: loggedInUserReducer,
  siteData: siteDataReducer,
  currentUser: currentUserReducer,
  media: mediaReducer,
  config: configReducer,
  loading: loadingReducer,
  membershipInfo: membershipInfoReducer,
  editsPending: editsPendingReducer,
  misc: miscReducer


})

export default (state, action) => {
  return allReducers(state, action);
}