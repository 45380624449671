import React, { Component } from 'react';

class NamecheapDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <a href='https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain' target='_blank'>View directions on namecheap.com</a>
      </div>
    );
  }
}

const styles = {

};

export default NamecheapDirections;