import React, { Component } from "react";
import InputText from "components/InputText";
import { connect } from "react-redux";
import { updateSiteData, updateTemplateData} from "redux/actions";
import SaveBar from "../../components/SaveBar";
import PageContentContainer from "../../components/containers/PageContentContainer";
import DashboardTabClass from "./DashboardTabClass";
import { Button } from "antd";
import DashboardInputSection from "../../components/input_sections/DashboardInputSection";
import { addMediaItem } from "../../firebase/media";
import shortid from "shortid";

const createImage = function (src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

function getResizedImage(imgFile, height, width) {
  //image preview after select image
  return new Promise((resolve) => {
    var filerdr = new FileReader();
    filerdr.onload = async function (e) {
      let img = await createImage(e.target.result);
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(function (blob) {
        blob.lastModifiedDate = new Date();
        blob.filename = shortid.generate();
        resolve(blob);
      });
    };
    filerdr.readAsDataURL(imgFile);
  });
}

class GeneralTab extends DashboardTabClass {
  constructor(props) {
    super(props);
  }

  onFaviconAdded = async (file) => {
    let resized = await getResizedImage(file, 32, 32);
    this.setState({ uploadingFavicon: true });
    addMediaItem(resized, {isFavicon: true}).then((item) => {
      this.onChangeSiteDataItem("faviconUrl", item.url);
      this.setState({ uploadingFavicon: false });
    });
  };

  onSocialImageAdded = async (file) => {
    let resized = await getResizedImage(file, 630, 1200);
    this.setState({ uploadingSocialImage: true });
    addMediaItem(resized).then((item) => {
      this.onChangeSiteDataItem("socialImageUrl", item.url);
      this.setState({ uploadingSocialImage: false });
    });
  };

  render() {
    const { isSaving, canSave } = this.state;
    const { siteName, faviconUrl, socialImageUrl, googleAnalyticsId } = this.props.siteData;
    return (
      <PageContentContainer maxWidth={650}>
        <input
          type="file"
          accept={`image/x-png,image/x-jpg`}
          ref={(x) => (this.faviconInput = x)}
          style={{ display: "none" }}
          onChange={(e) => this.onFaviconAdded(e.target.files[0])}
        ></input>

        <input
          type="file"
          accept={`image/x-png,image/x-jpg`}
          ref={(x) => (this.socialImageInput = x)}
          style={{ display: "none" }}
          onChange={(e) => this.onSocialImageAdded(e.target.files[0])}
        ></input>

        <div style={styles.wrapper}>
          <SaveBar onSave={this.save} loading={isSaving} disabled={!canSave} />
          <DashboardInputSection label={"Site Nickname"} info='This is just used for you to identify the site. Put whatever you want!'>
            <InputText
              inputProps={{
                onChange: (e) => this.onChangeSiteDataItem("siteName", e.target.value),
                value: siteName,
              }}
            />
          </DashboardInputSection>

          <DashboardInputSection
          label={"Title (appears in browser tab)"}
          info={'The text you see in the browser tab at the top of your computer screen'}
          >
            <InputText
              subtext=''
              inputProps={{
                onChange: e => this.onChangeTemplateDataItem("documentTitle", e.target.value),
                value: this.props.templateData.documentTitle,
                type: '',
                placeholder: '',
              }}
            />

          </DashboardInputSection>

          <DashboardInputSection
          label={"Google Analytics Tracking ID"}
          info={<div>We integrate with Google Analytics to let you view detailed information about your sites visitors. If you do not have Google Analytics set up, you can follow the guide <a href="https://support.google.com/analytics/answer/1008015?hl=en" target="_blank">here</a></div>}
          >
            <InputText
              subtext=''
              inputProps={{
                placeholder: 'UA-122873808-1',
                onChange: e => this.onChangeSiteDataItem("googleAnalyticsId", e.target.value),
                value: googleAnalyticsId
              }}
            />

          </DashboardInputSection>

          {/* // i forget why the favicon and social images are commented out */}

          {/* <DashboardInputSection label="Favicon:" info="This is the small icon on the browser tab" description="Please upload a square .png image, preferably 32x32">
            {faviconUrl ? <img src={faviconUrl} style={styles.favicon} /> : null}
            <Button loading={this.state.uploadingFavicon} onClick={() => this.faviconInput.click()}>
              {faviconUrl ? "Upload New Favicon" : "Choose File"}
            </Button>
          </DashboardInputSection> */}
{/* 
          <DashboardInputSection
            label="Social Media Image:"
            info="(og:image) This image will be used as a preview when anyone shares your website on social meida / messages. If you do not set this, a random image from your site will be used."
            description="Please upload a 1200x630 image"
          >
            {socialImageUrl ? <img src={socialImageUrl} style={styles.socialImage} /> : null}
            <Button loading={this.state.uploadingSocialImage} onClick={() => this.socialImageInput.click()}>
              {socialImageUrl ? "Upload New Image" : "Choose File"}
            </Button>
          </DashboardInputSection> */}
        </div>
      </PageContentContainer>
    );
  }
}

const styles = {
  favicon: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  socialImage: {
    width: 240,
    height: 126,
    marginRight: 8,
  },
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
  templateData: state.templateData
});
const mapActionsToProps = {
  updateSiteData,
  updateTemplateData
};
export default connect(mapStateToProps, mapActionsToProps)(GeneralTab);
