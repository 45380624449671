import React, { Component } from "react";
import { Radio, Slider, Input, Checkbox } from "antd";
import colors from "../../../colors";
import { connect } from "react-redux";
import { updateTemplateData } from "../../../redux/actions/index";
import NumberSlider from "../../NumberSlider";
import HasMediaSelect from "../HasMediaSelect";
import DescribeMediaCreation from "../DescribeMediaCreation";
import FileUploadSection from "../../media/FileUploadSection";
import EditSectionClass from "./shared/EditSectionClass";

const logoDescriptionPlaceholder = `I like the logo of https://pcpartpicker.com/ - it includes the full company name, and an icon of a computer tower.

I'd like a logo similar to that with a "high tech" and minimal feel, that includes my company name text (Blue Collar Pages), and an icon on the left.

My main color is #95BCF2`;

class LogoSection extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUrlSelected = (url) => {


      this.setState({
        logoFile: {
          url: url,
        },
      });
      this.props.updateTemplateData({
        ...this.props.templateData,
        images: {
          ...this.props.templateData.images,
          headerLogo: {
            url: url,
            height: 40,
          },
        },
      });

  };

  logoSliderChange = (x) => {
    this.props.updateTemplateData({
      ...this.props.templateData,
      images: {
        ...this.props.templateData.images,
        headerLogo: {
          ...this.props.templateData.images.headerLogo,
          height: x,
        },
      },
    });
  };

  onChangeWhite = e => {
    this.props.updateTemplateData({
      ...this.props.templateData,
      images: {
        ...this.props.templateData.images,
        headerLogo: {
          ...this.props.templateData.images.headerLogo,
          isWhite: e.target.checked,
        },
      },
    });
  }

  render() {
    const { templateData } = this.props;
    const { isDragging, logoFile, logoDescription } = this.state;

    return (
      <div style={{ flex: 1 }}>
        <div style={styles.wrapper}>
          <FileUploadSection 
            label='Logo'
            onUrlSelected={this.onUrlSelected}
            currentFile={logoFile}
          />
          {logoFile ? <NumberSlider onChange={this.logoSliderChange} value={templateData.images.headerLogo.height} /> : null}
          <Checkbox onChange={this.onChangeWhite} checked={templateData.images.headerLogo.isWhite} >White logo</Checkbox>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex: 1,
    paddingTop: 0,
  },

  dropzone: {
    // width: '100%',
    height: 140,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 3,
    color: colors.lightText,
    // fontSize: 20,
    // marginTop: 10,
    textAlign: "center",
    margin: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    flex: 1,
  },
  fileInput: {
    visibility: "hidden",
    display: "none",
  },
  sampleLogo: {},
};

const mapStateToProps = (state) => ({
  templateData: state.templateData,
});
const mapActionsToProps = {
  updateTemplateData,
};
export default connect(mapStateToProps, mapActionsToProps)(LogoSection);
