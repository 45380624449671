import React, { Component } from "react";
import Navbar from "../../components/Navbar";

class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  nav = url => {
    this.props.history.push();
  };

  logout = () => {
    alert('designer logout')
  }

  render() {
    const { maxWidth } = this.props;
    const styles = {
      body: {
        padding: 20,
        paddingTop: 0,
        maxWidth: maxWidth || 1000,
        width: '100%'
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }
    };
    return (
      <div style={styles.wrapper}>
        <Navbar
          navItems={[
            {
              label: "New Jobs",
              url: "/designer/jobs/new"
            },
            {
              label: "My Jobs",
              url: "/designer/jobs/mine"
            },
          ]}
          onLogout={this.logout}
          name={"testdesigner@gmail.com"}
          logo={require("assets/img/logo-header.png")}
        />
        <div style={styles.body}>{this.props.children}</div>
      </div>
    );
  }
}



export default PageTemplate;
