import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Loader from "../../../components/Loader";
import MobileOrderCard from "../../../components/orders/MobileOrderCard";
import OrdersNavbar from "../../../components/orders/OrdersNavbar";
import OrderDashboardClass from "./OrderDashboardClass";
import colors from '../../../colors'
import JobMap from "../../../components/JobMap/JobMap";
class MobileDashboard extends OrderDashboardClass {

  getDateTypeLabel = () => {

  }

  getPageTitle = () => {
    const { activeTabKey, dateType } = this.state;
    if(activeTabKey === 'quotes') return('Quotes');
    if(activeTabKey === 'unscheduled') return('Unscheduled');
    if(activeTabKey === 'scheduled' && dateType === 'today') return('Today');
    if(activeTabKey === 'scheduled' && dateType === 'tomorrow') return('Tomorrow');
    if(activeTabKey === 'scheduled' && dateType === 'any') return('All Scheduled Orders');
    if(activeTabKey === 'pendingPayment') return('Pending Payment');
    if(activeTabKey === 'complete') return('Complete');
    if(activeTabKey === 'canceled') return('Canceled');
  }

  render() {
    let orders = this.getFilteredOrders();

    let top = (
      <div style={styles.top}>
        <div style={styles.toptop}>
          <Input
            prefix={<SearchOutlined />}
            style={{ width: 160, marginRight: 10 }}
            onChange={(e) => this.setState({ searchText: e.target.value })}
            value={this.state.searchText}
          />

          <Link to={`/site/${this.props.match.params.siteId}/neworder`}>
            <Button>New Order</Button>
          </Link>
          
        </div>
        <div style={styles.topBottom}>

          <Radio.Group onChange={this.onChangeViewType} value={this.state.viewType} style={{ marginLeft: 10 }} size='small'>
            <Radio.Button value="list">List</Radio.Button>
            <Radio.Button value="map">Map</Radio.Button>
          </Radio.Group>

        </div>
      </div>
    )
  
      const pageTitle = this.getPageTitle();

      let loading = this.state[`${this.state.activeTabKey}Loading`];

      let map = (
        <JobMap orders={orders} onClickOrder={this.onClickOrder} />
      )

      let orderList = (
        <div style={styles.orderList}>
            <div style={{height: 15}} />
            {/* {loading ? <Loader loading={true} /> : null} */}
            {orders.map(o => {
              return(
                <MobileOrderCard order={o} onClick={() => this.onClickOrder(o)}/>
              )
            })}
          </div>
      )
  
      let pageContent = this.state.viewType === 'map' ? map : orderList;

    return (
      <div style={styles.wrapper}>
        <OrdersNavbar />
        <div style={styles.body}>
          <div style={styles.pageTitle}>
            {pageTitle} {orders.length ? `(${orders.length})` : ''} {loading ? <ClipLoader size={15} color={colors.lightText} /> : null}
            </div>
          {top}
          <div style={styles.content}>
          {pageContent}
            
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {

    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: 20,
    paddingTop: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    position: 'relative',
    paddingTop: 10

  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  toptop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topBottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 8
  },
  pageTitle: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    marginBottom: 5
  },
  orderList: {
    // paddingTop: 10
    // paddingLeft: 10,
    // paddingRight: 10
  },


};

export default MobileDashboard;
