import * as types from '../types';

export function mediaReducer(state = [], action) {
  switch (action.type) {
    case types.UPDATE_MEDIA_ITEMS:
      let newMedia = action.payload;
      if(!Array.isArray(action.payload)) newMedia = [];
      return newMedia;
    default: return state;
  }
}