import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "redux/actions";
import PageContentContainer from "../../../components/containers/PageContentContainer";
import DashboardTabClass from "../DashboardTabClass";
import { db } from "../../../firebase";
import dayjs from "dayjs";
import colors from "../../../colors";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

class EmailsTab extends DashboardTabClass {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
    };
  }

  componentDidMount() {
    this.loadEmails();
  }

  onDownloadCsv = () => {
    let csvString = "";
    this.state.emails.forEach((item, index) => {
      csvString += item.email;
      if (index !== this.state.emails.length - 1) csvString += ",";
    });

    let filename = `${this.props.siteData.siteName}_emails_${dayjs().format("YYYY-MM-DD")}.csv`;

    var blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  loadEmails = async () => {
    let emails = await db.collection("collectedEmails").where("siteId", "==", this.props.siteData.id).get();
    let list = emails.docs.map((item) => {
      return { ...item.data() };
    });
    this.setState({ emails: list, emailsLoading: false });
    // console.log(list)
  };

  render() {
    const { emails, emailsLoading } = this.state;

    return (
      <PageContentContainer maxWidth={550}>
        <div style={styles.wrapper}>
          <div style={styles.topRow}>
            <div style={styles.title}>{emails.length} emails collected</div>
            <div style={styles.topRight}>
              <Button icon={<DownloadOutlined />} onClick={this.onDownloadCsv}>
                Download emails .csv
              </Button>
            </div>
          </div>
          <div style={styles.emailList}>
            {emailsLoading ? null : 
            emails.length < 1 ? (
              <div style={styles.noEmailMessage}>If you have an email collection form on your website, collected emails will appear here.</div>
            ) : (
              this.state.emails.map((email) => {
                return (
                  <div style={styles.emailWrapper}>
                    {email.email}
                    <span style={styles.dateCollected}>added {dayjs(new Date(email.createdAt)).format("MMM DD, YYYY HH:mm a")}</span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </PageContentContainer>
    );
  }
}

const styles = {
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  emailList: {
    flex: 1,
    overflow: "auto",
  },
  title: {
    fontSize: 20,
  },
  dateCollected: {
    marginLeft: 8,
    color: colors.lightText,
    fontSize: 11,
  },
  noEmailMessage: {
    textAlign: "center",
    color: colors.lightText,
    marginTop: 20,
  },
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(EmailsTab);
