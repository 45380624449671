import store from '../redux/store';
import * as actions from '../redux/actions';
import shortid from 'shortid';
import config from 'bluecollarpages-shared/config';

function getCurrentDomain(){

  // FOR LOCAL DEV TESTING
  let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
  if(isLocalDev){
    // return 'bluecollarpages'
    return 'detailpages'
  }

  let url = isLocalDev ? 'https://app.detailpages.com/site/fs98734bf/edit' : window.location.href; // https://app.bluecollarpages.com/
  if(url.includes('detailpages.com')) return 'detailpages';
  if(url.includes('bluecollarpages.com')) return 'bluecollarpages';
  return 'bluecollarpages';

  // let urlList = url.split('.'); 
  // urlList.splice(0)
  // url = urlList.join('.')
  // if(url.indexOf('http') < 0) url = 'https://'+url; // https://bluecollarpages.com
  // var parsedDomain = /:\/\/([^\/]+)/.exec(url)[1]; // bluecollarpages.com
  // if(parsedDomain.includes('app.')) parsedDomain = parsedDomain.replace('app.', '')
  // let parts = parsedDomain.split("."); // ['bluecollarpages', 'com']
  // console.log('domain name', parts[0])
  // return parts[0];
}


export const loadConfig = async function(){

  let currentDomain = getCurrentDomain() || 'bluecollarpages';
  let configToUse = config[currentDomain] || config['bluecollarpages'];
  await loadAppType();
  store.dispatch(actions.updateConfig(configToUse));
  document.title = `${configToUse.siteNameDis}`;
  // set favicon
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = configToUse.faviconUrl;

}

const loadAppType = function(){
  return new Promise(resolve => {
    let appType = 'normal'
    if(window.location.origin.includes('orders.')) appType = 'orders';
    
    let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
    // if(isLocalDev) appType = 'orders' // FOR TESTING
    // if(window.location.host.includes('192.168')) alert("you are using an ip instead of localhost. this may cause problems, since we are using 'localhost' to check for things. see lines above this alert")
  
    store.dispatch(actions.updateAppType(appType));

    resolve()
  })

}