import { Button } from 'antd';
import React, { Component } from 'react';
import DashboardSquare from '../../../../components/DashboardSquare/DashboardSquare';
import SquareSection from '../../../../components/DashboardSquare/SquareSection';
import InvoiceModal from '../../../../components/modals/InvoiceModal/InvoiceModal';
import InvoiceCard from '../InvoiceCard';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

class InvoiceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      existingInvoicesLoading: true,
      existingInvoices: [],
      showInvoiceModal: false,
    };
  }

  componentDidMount(){
    this.loadAllInvoices()
  }

  loadAllInvoices = () => {
    this.setState({existingInvoicesLoading: true})
    global.firebaseApi(`/getAllInvoices`, {body: {
      orderId: this.props.orderId
    }}).then(res => {
      this.setState({existingInvoices: res.invoices, existingInvoicesLoading: false})
    })
  }

  onClickInvoice = inv => {
    this.setState({preselectedInvoice: inv}, () => {
      this.setState({showInvoiceModal: true})
    });
  }


  onSendInvoiceButtonClick = () => {
    if(!this.props.currentUser.stripeSetupComplete){
      return alert('Please go to "Settings" and set up payments before you send an invoice.')
    }
    this.setState({showInvoiceModal: true})
  }


  render() {
    const { orderLoading, existingInvoicesLoading, order } = this.props;

    let topRight = (
      <Button size={isMobile ? "small" : undefined} onClick={this.onSendInvoiceButtonClick} >
        + New Invoice
      </Button>
    )

    if(['quote', 'complete', 'canceled'].indexOf(order.stage)>-1) topRight = null;

    return(
      <>
               {orderLoading || existingInvoicesLoading ? null : 
         <InvoiceModal
            updateLineItems={this.props.onChangeLineItems}
            visible={this.state.showInvoiceModal}
            order={order}
            onCancel={() => this.setState({showInvoiceModal: false})}
            existingInvoices={this.state.existingInvoices}
            existingInvoicesLoading={this.state.existingInvoicesLoading}
            loadAllInvoices={this.loadAllInvoices}
            preselectedInvoice={this.state.preselectedInvoice}
            clearPreselectedInvoice={() => this.setState({preselectedInvoice: {}})}
         />
         }
               <DashboardSquare title="Invoices" loading={this.state.existingInvoicesLoading} right={topRight}>
        {/* <SquareSection title='# Invoices Generated' value={this.state.existingInvoices.length} />
        <SquareSection title='# Invoices Paid' value={'1'} /> */}
        <SquareSection width={'100%'} >
          <div style={styles.invoiceRow}>
            {this.state.existingInvoices.map(inv => {
              return <InvoiceCard invoice={inv} onClick={() => this.onClickInvoice(inv)} />
            })}
          </div>
        </SquareSection>

      </DashboardSquare>
      </>
    );
  }
}

const styles = {
  invoiceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
};

const mapStateToProps = state => ({
  currentUser: state.currentUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(InvoiceSection);