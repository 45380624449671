import { AutoComplete } from "antd";
import React, { Component } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
let googleKey = "AIzaSyAbQMQgERXR5QgUfcdc3M2YjOFKGhfETNY";

class AddressAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // results: [],
      // searchText: ''
    };
  }

  // loadGoogleResults = str => {
  //   fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${this.state.searchText}&key=${googleKey}`)
  //   // .then(result => result.json())
  //   .then(result => {
  //     console.log('google resut', result)
  //     // this.setState({ results: result });
  //   }).catch(err => {
  //     console.log('google loader error', err)
  //   })

  // };

  // resetGoogleResultsLoader  = () => {
  //   if(this.googleResultsTimeout) clearTimeout(this.googleResultsTimeout);
  //   this.googleResultsTimeout = setTimeout(() => this.loadGoogleResults(), 300);
  // }

  // onChangeSearchText = str => {
  //   this.setState({ searchText: str }, () => {
  //     if (str.length > 0) {
  //         this.resetGoogleResultsLoader()
  //     } else {
  //       this.setState({ results: [] });
  //     }
  //   });
  // };

  onSelectAddress = (data) => {
    geocodeByPlaceId(data.value.place_id)
    .then((results) => {
        const { formatted_address } = results[0] || {};
        this.props.onSelectAddress({
          dis: data.label,
          place_id: data.value.place_id,
          formatted_address: formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      })
      .catch((error) => console.error(error));
  };

  render() {
    // const autocompleteOptions = this.state.results.map(item => {
    //   // return (
    //   //   <Option key={item.gcpPlacesId} value={JSON.stringify(item)}>
    //   //     {item.dis} {compact ? ' - '+ item.geoAddr : null}
    //   //     {compact ? null :
    //   //     <span className="certain-search-item-count" style={{ maxWidth: 300, overflowWrap: "break-word" }}>
    //   //       {item.geoAddr}
    //   //     </span>
    //   //     }
    //   //   </Option>
    //   // );
    //   return <Option></Option>
    // });

    // return(
    //   <AutoComplete
    //   style={{ width: 300 }}
    //   onSelect={this.onSelectAddress}
    //   value={this.state.searchText}
    //   onSearch={this.onChangeSearchText}
    //   dropdownStyle={{minWidth: 450}}
    // >
    //   {autocompleteOptions}
    // </AutoComplete>
    // );

    return (
      <GooglePlacesAutocomplete
        apiKey={googleKey}
        selectProps={{
          value: this.props.address,
          onChange: this.onSelectAddress,
        }}
      />
    );
  }
}

const styles = {};

export default AddressAutoComplete;
