import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import DataItem from "../orders/DataItem";
import DateTimeInput from "../DateTimeInput";
import dayjs from "dayjs";

class ChooseMediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOk = () => {
    this.props.onCancel();
    this.props.onSchedule(this.state.scheduledDate)
  };

  onChangeScheduledFor = (data) => {
    this.setState({scheduledDate: data})
  }


  render() {
    const { visible, onCancel, isScheduleOnly } = this.props;
    const { scheduledDate } = this.state;

    return (
      <Modal
        title={isScheduleOnly ? 'Schedule Job' : 'Accept Job'}
        visible={visible}
        onOk={this.handleOk}
        okText="Accept Job"
        okButtonProps={{  }}

        onCancel={onCancel}
        // width={1000}
        zIndex={60}
        bodyStyle={styles.modalBody}
      >
        <div style={styles.inside}>
          {/* <div>Optional: schedule for a certain date</div> */}
      <DataItem label='Optional: Schedule for a date' value={''} isEdit={true}>
        <DateTimeInput 
          value={scheduledDate}
          onChange={this.onChangeScheduledFor}
        />
      </DataItem>
          
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalBody: {
    maxHeight: 500,
    overflowY: "auto",
  },
};

const mapStateToProps = (state) => ({

});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(ChooseMediaModal);
