import React, { Component } from "react";
import { connect } from "react-redux";
import NumberSlider from "../../NumberSlider";
import InputText from "../../InputText";
import FileUploadSection from "../../media/FileUploadSection";
import EditSectionClass from "../sections/shared/EditSectionClass";
import EditableItemCard from "../../EditableItemCard";
import { Alert, Button, Switch } from "antd";
import { InfoCircleFilled, InfoOutlined, PlusOutlined } from "@ant-design/icons";
import colors from "../../../colors";
import EditorInputSection from "../../input_sections/EditorInputSection";
import ColorPicker from "../../ColorPicker";

class EditorRenderer extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSection(sectionData, editorInputs, nestedItemIndex, nestedListKey, options={}) {

    return (
      <>
        {(editorInputs || []).map((f) => {
          const { inputProps = {}, label, value, type, dataKey } = f;

          if(f.dependsOn && !sectionData[f.dependsOn]) return null;

          if (f.type === "string" || f.type === 'text') {
            return (
              <EditorInputSection compact={options.isCompact} label={f.label} info={f.info}>
                <InputText
                  // label={label}
                  inputProps={{
                    ...inputProps,
                    value: sectionData[dataKey],
                    onChange: (e) => this.editData({ [dataKey]: e.target.value }, nestedItemIndex, nestedListKey),
                  }}
                />
              </EditorInputSection>
            );
          }

          if (f.type === "color" ) {
            return (
              <EditorInputSection compact={options.isCompact} label={f.label} info={f.info}>
                <ColorPicker value={sectionData[dataKey] || ''} onChange={(e) => this.editData({ [dataKey]: e.target.value }, nestedItemIndex, nestedListKey)} />
              </EditorInputSection>
            );
          }

          if (f.type === "image") {
            return (
              <EditorInputSection compact={options.isCompact} label={f.label} info={f.info}>
                <FileUploadSection
                  {...(f.inputProps || {})}
                  // label={f.label}
                  onUrlSelected={(url) => this.editData({ [dataKey]: url }, nestedItemIndex, nestedListKey)}
                  currentFile={{ url: sectionData[f.dataKey] }}
                />
              </EditorInputSection>
            );
          }

          if (f.type === "slider") {
            return (
              <EditorInputSection compact={options.isCompact} label={f.label} info={f.info}>
                <NumberSlider
                  value={sectionData[dataKey]}
                  onChange={x => this.editData({[f.dataKey]: x}, nestedItemIndex, nestedListKey)}
                  {...inputProps}
                />
              </EditorInputSection>
            );
          }

          if (f.type === "bool") {
            return (
              <EditorInputSection label={f.label} info={f.info}>
                <Switch checked={sectionData[dataKey]} onChange={val => this.editData({[f.dataKey]: val}, nestedItemIndex, nestedListKey)} />
              </EditorInputSection>
              
            );
          }

          if(f.type === 'items'){
            console.log("SDD", sectionData)
            return(
              <>
              {sectionData[f.dataKey].map((item, index) => {
                return (
                  <EditableItemCard
                    onClose={() => this.removeItem(index, f.dataKey)}
                    header={<span></span>}
                  >
                    {this.renderSection(item, f.items, index, f.dataKey, {isCompact: true})}
                  </EditableItemCard>
                );
              })}
              <div style={styles.addButtonWrapper}>
                <Button onClick={() => this.addItem(f.dataKey)} icon={<PlusOutlined />} style={styles.addButton}>{f.addButtonLabel || 'Add Item'}</Button>
              </div>
              </>
            )
          }




        })}
      </>
    );
  }

  render(){
    const { templateData, sectionData, editorData={} } = this.props;

    return(
      <div style={styles.wrapper}>
        {editorData.info ? 
        <Alert message={editorData.info} type="info" showIcon />
        :null}
        {this.renderSection(sectionData, editorData.editorInputs)}
      </div>
    )
  }
}

const styles = {
  wrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: "scroll",
    padding: 10,
  },

  // editableitemcard
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
    cursor: 'pointer'
  },
  addButton: {
    width: '50%',
  },
  addButtonWrapper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

};

const mapStateToProps = (state) => ({
  templateData: state.templateData,
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(EditorRenderer);
