import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import DashboardSquare from '../DashboardSquare';
import SquareSection from '../DashboardSquare/SquareSection';

class MobileOrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { order, onClick } = this.props;
    return(
      <DashboardSquare >
        <div style={styles.wrapper} onClick={onClick}>
          <div style={styles.topTimeRow}>
            {order.scheduledFor ? 
            <>
              <div style={styles.date}>
                <CalendarOutlined style={{marginRight: 4}}/>
                  {dayjs(new Date(order.scheduledFor)).format('dddd MMM DD')}
              </div>
              <div style={styles.time}>
                <ClockCircleOutlined style={{marginRight: 4}}/>
                {dayjs(new Date(order.scheduledFor)).format('hh:mm a')}
              </div>
            </>
            :null}
          </div>
          {!order.hasBeenViewed ? <Tag color='green'>NEW</Tag> : null}
          <div style={styles.name}>{order.customerName}</div>
          <div style={styles.address}>{order.customerAddress.formatted_address}</div>
        </div>
      </DashboardSquare>
    );
  }
}

const styles = {
  wrapper: {
    width: '100%',
    padding: 0,
    borderRadius: 3,
  },
  topTimeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: -5
  },
  name: {
    fontSize: 18,
  },
  address: {

  },
  date: {
    marginRight: 15
  },
  time: {

  }
};

export default MobileOrderCard;