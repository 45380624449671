import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import MobileDashboard from './MobileDashboard';
import WebDashboard from './WebDashboard';
import { connect } from 'react-redux';

class OrdersDashboard extends Component {
  render() {
    if(isMobile){
      return <MobileDashboard {...this.props} />
    }else{
      return <WebDashboard {...this.props} />
    }
  }
}

const mapStateToProps = state => ({
  siteData: state.siteData,
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(OrdersDashboard);