export default {
  editorInputs: [
    {
      label: "Dark Mode",
      type: "bool",
      dataKey: "darkMode",
      info: '',
    },
    {
      label: "Footer Text",
      type: "text",
      dataKey: "subtext",
      inputProps: {
        multiline: true,
        rows: 3,
      },
    },
    {
      label: "Logo",
      type: "image",
      dataKey: "logoUrl",
      inputProps: {
        compact: true,
      },
    },
    {
      label: "Logo Size",
      type: "slider",
      dataKey: "logoHeight",
      inputProps: {
        min: 20,
        max: 80,
      },
    },
    {
      label: "Instagram Username",
      dataKey: "instagram",
      type: "text",
      inputProps: {
        prefix: "@",
      },
    },
    {
      label: "Facebook Username",
      dataKey: "facebook",
      type: "text",
    },
    {
      label: "Phone",
      dataKey: "phone",
      type: "text",
    },
    {
      label: "Email",
      dataKey: "email",
      type: "text",
    },
    {
      label: "Address line 1",
      dataKey: "address1",
      type: "text",
    },
    {
      label: "Address line 2",
      dataKey: "address2",
      type: "text",
    },
    {
      label: "Address line 3",
      dataKey: "address3",
      type: "text",
    },

    {
      label: "Links",
      dataKey: "links",
      type: "items",
      addButtonLabel: 'Add Another Link',
      header: "",
      items: [
        {
          label: "Label",
          dataKey: "label",
          type: "text",
        },
        {
          label: "Link URL",
          dataKey: "url",
          type: "text",
          inputProps: {
            placeholder: "https://google.com",
          },
        },
      ],
    },
  ],
};
