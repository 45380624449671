import React, { Component } from "react";
import colors from "../../../colors";
import { connect } from "react-redux";
import {} from "redux/actions";
import { updateTemplateData } from "../../../redux/actions/index";
import InputText from "../../InputText";
import { Card, Button, Switch } from "antd";
import EditableItemCard from '../../EditableItemCard';
import { PlusOutlined } from '@ant-design/icons';
import EditSectionClass from "./shared/EditSectionClass";
import EditorInputSection from "../../input_sections/EditorInputSection";

class IconFeatures extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeText = (name, text) => {
    this.editData({[name]: text})
  };

  changeItemText = (index, key, value) => {
    let newItems = this.props.sectionData.items;
    newItems[index][key] = value;
    this.editData({items: newItems})
  }

  addItem = () => {
    let newItems = this.props.sectionData.items;
    newItems.push({title: 'New Item Title', subtext: 'New item subtext'})
    this.editData({items: newItems})
  }

  removeItem = index => {
    let newItems = this.props.sectionData.items;
    newItems.splice(index, 1);
    this.editData({items: newItems})
  }

  render() {
    const { templateData, sectionData } = this.props;

    return (
      <div style={styles.wrapper}>
                <EditorInputSection label={'Dark Mode'} >
          <Switch checked={sectionData.darkMode} onChange={val => this.editData({darkMode: val})} />
        </EditorInputSection>
        <InputText
          label={"Title"}
          inputProps={{
            onChange: e => this.changeText("title", e.target.value),
            value: sectionData.title
          }}
        />
        <div style={{height: 10}}></div>
        <InputText
          label={"Subtitle"}
          inputProps={{
            onChange: e => this.changeText("subtext", e.target.value),
            value: sectionData.subtext
          }}
        />
        <div style={{height: 20}}></div>

        {sectionData.items.map((item, index) => {
          return (
            <EditableItemCard
              onClose={() => this.removeItem(index)}
              // key={item.title}
              header={
                <InputText
                  inputProps={{
                    onChange: e => this.changeItemText(index, 'title', e.target.value),
                    value: item.title,
                    style: styles.titleInput
                  }}
                />
              }
            >
              <InputText
                // label={"Subtext"}
                inputProps={{
                  onChange: e => this.changeItemText(index, 'subtext', e.target.value),
                  value: item.subtext,
                  multiLine: true,
                  rows: 2,
                  style: {fontSize: 12}
                }}
              />
            </EditableItemCard>
          );
        })}
        <div style={styles.addButtonWrapper}>
          <Button onClick={this.addItem} icon={<PlusOutlined />} style={styles.addButton}>Add Item</Button>
        </div>

      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'scroll',
    padding: 10,
    // backgroundColor: colors.lightBG
  },
  itemCard: {
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.border,
    borderRadius: 3,
    backgroundColor: colors.lightBG,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
    cursor: 'pointer'
  },
  addButton: {
    width: '50%',
  },
  addButtonWrapper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleInput: {
    fontWeight: 'bold',
    fontSize: 20,
    // width: '94%',
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(IconFeatures);
