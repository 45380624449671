import React, { Component } from "react";
import { Button, message } from "antd";
import { saveSiteData } from "../../firebase/siteData";
import { saveTemplateData } from "../../firebase/templateData";

export default class DashboardTabClass extends Component{
  constructor(props){
    super(props);
    this.state = {
      isSaving: false,
      canSave: false
    }
  }

  onChangeSiteDataItem = (key, val) => {
    this.props.updateSiteData({
      ...this.props.siteData,
      [key]: val
    });
    this.setState({canSave: true})
  };

  onChangeTemplateDataItem = (key, val) => {
    this.props.updateTemplateData({
      ...this.props.templateData,
      [key]: val
    });
    this.setState({canSave: true})
  }



  save = () => {
    return new Promise(resolve => {
      this.setState({ isSaving: true });
      saveTemplateData()
      saveSiteData().then(() => {
        this.setState({ isSaving: false, canSave: false });
        message.success("Saved!");
        resolve()
      });


    })
  };

}

