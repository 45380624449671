import React, { Component } from "react";
import plusImage from "assets/img/plus.png";
import colors from "../colors";
import { Spin } from "antd";
import webpageImg from "assets/img/webpage2.png";

class SiteCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { color, title, onClick, newSite, loading, faviconUrl } = this.props;

    const styles = {
      wrapper: {
        backgroundColor: color ? color : "#f3f3f3",
        padding: 20,
        borderRadius: 12,
        marginRight: 30,
        width: 333,
        minHeight: 200,
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end",
        position: "relative",
      },
      siteTitle: {
        fontSize: 20,
        fontFamily: "montserrat",
        // fontWeight: "800",
        color: newSite ? colors.text : "#fff",
        textAlign: newSite ? "center" : undefined,
        wordWrap: "break-word",
        overflowWrap: "break-word",
        width: 300,
        // display: 'inline-block',
        marginBottom: 0
      },
      plusImage: {
        height: 22,
        marginTop: 20
      },
      imageSection: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 40
      },
      siteIcon: {
        height: 32,
        width: 32,
        position: "absolute",
        top: -10,
        left: -10
      }
    };

    if (newSite) {
      styles.wrapper.alignItems = undefined;
      return (
        <div style={styles.wrapper} onClick={onClick} class="siteSelectCard">
          <div style={styles.imageSection}>
            <div style={styles.siteTitle}>New Site</div>
            {loading ? (
              <Spin style={styles.plusImage} />
            ) : (
              <img src={plusImage} style={styles.plusImage} />
            )}
          </div>
        </div>
      );
    }

    return (
      <div style={styles.wrapper} onClick={onClick} class="siteSelectCard">
        <img style={styles.siteIcon} src={faviconUrl || webpageImg} />
        <p style={styles.siteTitle}>{title}</p>
      </div>
    );
  }
}

export default SiteCard;
