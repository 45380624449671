import { Checkbox, Tooltip } from "antd";
import React, { Component } from "react";

class InvoiceLine extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, isChecked, onChange } = this.props;

    const styles = {
      wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: 2,
        opacity: item.hasBeenInvoiced || !isChecked ? 0.4 : 1,
      },
      title: {},
      price: {
        paddingLeft: 10,
      },
    };

    let content = (
      <div style={styles.wrapper}>
        <div style={styles.left}>
          <span>
            <Checkbox
              checked={isChecked}
              disabled={item.hasBeenInvoiced}
              onChange={(e) => onChange(item.id, e.target.checked)}
              style={{ marginRight: 4 }}
            />
          </span>
          <span style={styles.title}>{item.title}</span>
        </div>
        <div style={styles.right}>
          <div style={styles.price}>{item.price}</div>
        </div>
      </div>
    );

    if (item.hasBeenInvoiced) {
      return <Tooltip placement='left' title="This item has already been included on another invoice.">{content}</Tooltip>;
    } else {
      return content;
    }
  }
}

export default InvoiceLine;
