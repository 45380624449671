import * as types from "../types";
import store from "../store";
const one_hour = 1000 * 60 * 60;

export function updateMembershipInfo(x) {
  let state = store.getState();

  let signedUpAt = state.currentUser.created;
  let now = Date.now();
  let allowedTime = one_hour * 24 * 14;

  let msLeftTrial = signedUpAt + allowedTime - now;
  let msLeftSubscription = (state.currentUser.subscriptionEndsAt || 0) - Date.now();
  let msLeft = state.currentUser.subscriptionHasBeenCancelled ? msLeftSubscription : msLeftTrial;

  let hoursLeft = Math.floor(msLeft / one_hour);
  let daysLeft = Math.floor(hoursLeft / 24);

  let showSignupBanner = daysLeft < 5;

  let info = {
    showSignupBanner,
    daysLeft,
    hoursLeft,
    canSignup: !state.currentUser.stripeSubscriptionId || (state.currentUser.stripeSubscriptionId && state.currentUser.subscriptionHasBeenCancelled),
    accessHasExpired: hoursLeft <= 0,
    subscriptionHasBeenCancelled: state.currentUser.subscriptionHasBeenCancelled,
    isTrial: !state.currentUser.stripeSubscriptionId,
    timeLeftText: hoursLeft < 25 ? `${hoursLeft} hour${hoursLeft > 1 ? "s" : ""}` : `${daysLeft} day${daysLeft > 1 ? "s" : ""}`,
    isMembershipActive: state.currentUser.membershipLevel > 0 ? true : false,
  };

  store.dispatch({
    type: types.UPDATE_MEMBERSHIP_INFO,
    payload: info,
  });
}

export function updateEditsPending(data) {

  store.dispatch({
    type: types.UPDATE_EDITS_PENDING,
    payload: data,
  });
}

export function setMisc(key, val) {

  store.dispatch({
    type: types.SET_MISC,
    payload: {[key]: val},
  });
}