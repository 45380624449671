
import { db, firebase } from './index';
let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
let ngrok = 'https://ce51-2600-8806-8700-40-9187-a471-9588-ca16.ngrok.io'
const apiBaseUrl = isLocalDev ? `${ngrok ? ngrok+'/mvptester-84a0c/us-central1' : `http://${window.location.hostname}:5000/mvptester-84a0c/us-central1`}` : 'https://us-central1-mvptester-84a0c.cloudfunctions.net';
if(isLocalDev) alert(apiBaseUrl)

export default function(path, options={}){
  return new Promise(async resolve => {
    let currentUserToken = '';
    try{
      currentUserToken = await firebase.auth().currentUser.getIdToken();
    }catch(e){
      console.warn(e);
    }
    fetch(`${apiBaseUrl}${path}`, {
      method: 'POST',
      body: JSON.stringify(options.body || {}),
      headers: {
        Authorization: currentUserToken
      }
    })
    .then(result => result.json())
    .then(result => {
      resolve(result)
    })
  })

}