import React, { Component } from "react";
import colors from "../../../colors";
import TextArea from "antd/lib/input/TextArea";
import { Button } from "antd";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollMessages = () => {
    document.getElementById("messagesScrollPoint").scrollIntoView();
  };

  componentDidMount() {
    this.scrollMessages();
  }

  render() {
    const { isNew } = this.props;
    return (
      <div style={styles.wrapper}>
        <div style={styles.messagesContainer}>
          <Message />
          <Message isMe={true} />
          <Message />
          <Message isMe={true} />
          <Message />
          <Message isMe={true} />
          <Message />
          <Message isMe={true} />
          <Message />
          <Message isMe={true} />
          <Message />
          <Message isMe={true} />
          <div id="messagesScrollPoint" />
        </div>
        {!isNew ? 
          <div style={styles.inputRow}>
            <TextArea />
            <Button style={styles.sendButton}>Send</Button>
          </div>
        :null}
      </div>
    );
  }
}

const Message = ({ isMe }) => {
  if (isMe) {
    return (
      <div style={styles.myMessage}>
        <span style={{ ...styles.messageTitle, color: "blue" }}>Me</span>
        <span style={styles.messageTime}>11/8/2019, 14:52</span>
        <div style={styles.messageContent} id="messagesContent">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.customerMessage}>
        <span style={{ ...styles.messageTitle }}>Customer</span>
        <span style={styles.messageTime}>11/8/2019, 14:52</span>
        <div style={styles.messageContent} id="messagesContent">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
      </div>
    );
  }
};

const styles = {
  wrapper: {},
  messagesContainer: {
    maxHeight: 400,
    overflow: "auto"
  },

  // message
  messageTitle: {
    fontWeight: "bold",
    marginRight: 10
  },
  messageTime: {
    color: colors.text,
    fontSize: 12
  },
  myMessage: {
    marginTop: 15,
  },
  customerMessage: {
    marginTop: 15,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  sendButton: {
    marginLeft: 8,
    height: 50
  }
};

export default Chat;
