import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTemplateData } from 'redux/actions';
import ScreenshotSelectBox from './ScreenshotSelectBox';
import InputText from '../../../InputText';
import HasMediaSelect from '../../HasMediaSelect';
import DescribeMediaCreation from '../../DescribeMediaCreation';
import FileUploadSection from '../../../media/FileUploadSection';
import { addMediaItem } from '../../../../firebase/media';
import NumberSlider from '../../../NumberSlider';
import EditSectionClass from '../shared/EditSectionClass';


class EditScreenshot2 extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  // onSelectScreenshotType = (type) => {
  //   const { templateData } = this.props;
  //   // this.props.updateTemplateData('screenshot2', 'deviceType', type)

  // }

  onChangeDescription = text => {

  }

  onUrlSelected = url => {
    this.editData({url: url});
  }

  changeItem = (key, val) => {
    this.editData({[key]: val})
  }

  render() {
    const { templateData, sectionData} = this.props;
    // const { currentFile } = this.state;
    let { screenshot2={} } = templateData;
    const hasMedia = screenshot2.isActive ? false : true;

    return(
      <div style={styles.wrapper}>
        <ScreenshotSelectBox onSelect={val => this.changeItem('deviceType', val)} selected={screenshot2.deviceType} />
        <br/>
        {/* <HasMediaSelect hasMedia={hasMedia} onChange={val => this.changeItem('deviceType', val)} /> */}
   
          <div>
            <FileUploadSection 
              label='Screenshot'
              onUrlSelected={this.onUrlSelected}
              currentFile={{url: sectionData.url}}
            />
          </div>

        <br/>
        <NumberSlider value={sectionData.size} onChange={val => this.changeItem('size', val)} label='Image Size:'/>
        <br/>
        <InputText
          label={"Title"}
          inputProps={{
            onChange: e => this.changeItem("title", e.target.value),
            value: sectionData.title
          }}
        />
        <br/>
        <InputText
          label={"Subtitle"}
          inputProps={{
            onChange: e => this.changeItem("subtext", e.target.value),
            value: sectionData.subtext,
            rows: 4
          }}
        />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingLeft:10,
    paddingRight: 10,
    overflow: 'scroll'
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData,
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(EditScreenshot2);