import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "redux/actions";
import TextArea from "antd/lib/input/TextArea";
import { Button, Collapse, message } from "antd";
import faq from "./faq";
import PageContentContainer from "../../../components/containers/PageContentContainer";
const { Panel } = Collapse;

class SupportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  onSubmit = () => {
    this.setState({isSending: true});
    global.firebaseApi(`/contactSupport`, {body: {
      siteId: this.props.siteData.id,
      userId: this.props.loggedInUser.id,
      userEmail: this.props.loggedInUser.email,
      text: this.state.text
    }}).then(res => {
      this.setState({isSending: false, text: ''});
      message.success('Message Sent!')
    })
  }

  render() {
    return (
      <PageContentContainer maxWidth={1200}>
        <div style={styles.wrapper}>
          <div style={styles.row}>
            {/* <div style={styles.left}>
              <h1>FAQ</h1>
              <Collapse bordered={false} defaultActiveKey={["1"]}>
                {faq.map(item => {
                  return (
                    <Panel header={item.question} key={item.question}>
                      {item.answer}
                    </Panel>
                  );
                })}
              </Collapse>
            </div> */}
            <div style={styles.right}>
              <h1>Contact Us</h1>
              <p>
                You can reach {this.props.config.data.siteNameDis} support using the form below, or email
                support@{this.props.config.data.supportEmailDomain}.com
              </p>
              <TextArea rows={5} value={this.state.text} onChange={e => this.setState({text: e.target.value})} />
              <Button style={{marginTop: 10}} loading={this.state.isSending} onClick={this.onSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      </PageContentContainer>
    );
  }
}

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  left: {
    flex: 0.48
  },
  right: {
    flex: 0.48
  }
};

const mapStateToProps = state => ({
  config: state.config,
  loggedInUser: state.loggedInUser,
  siteData: state.siteData
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(SupportTab);
