import imageHero from './imageHero';
import hero from './hero';
import footer from './footer';
import imageServices from './imageServices';
import contactUs from './contactUs';
import photoGallery from './photoGallery';
import websitePortfolio from './websitePortfolio';
import meetOurTeam from './meetOurTeam';
import pricingWithDetails from './pricingWithDetails';
import orderPlacer from './orderPlacer';
import video from './video';
import detailHero from './detailHero';
import bigFeature from './bigFeature';


// make sure keys on this object match defaultDataKey of the section 
const obj = {
  imageHero: imageHero,
  hero: hero,
  footer: footer,
  imageServices: imageServices,
  contactUs: contactUs,
  photoGallery: photoGallery,
  websitePortfolio: websitePortfolio,
  meetOurTeam: meetOurTeam,
  // pricingWithDetails: pricingWithDetails,
  orderPlacer: orderPlacer,
  video: video,
  detailHero: detailHero,
  bigFeature: bigFeature,


};

export default obj