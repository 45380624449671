import React, { Component } from "react";
import SignupForm from "components/SignupForm";
import { connect } from 'react-redux';

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.topNav}>
        <img src={this.props.config.data.bigLogoUrl} style={styles.logo} />
          
        </div>
        <SignupForm />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // paddingTop: 100,
    // backgroundColor: '#f5f5f5',
    height: '100vh'
  },
  topNav: {
    backgroundColor: '#fff',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 30,
    boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
  },
  logo: {
    height: 40
  }
};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SignupPage);
