// this component watches the URL for any changes
// when it detects a change in the site id, it automatically grabs the site from firebase and loads it into redux
// useful for page reloads


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { db } from '../firebase';
import { connect } from 'react-redux';
import { updateTemplateData, updateSiteData, updateLoading } from 'redux/actions';
import { loadMyMedia } from '../firebase/media';

class URLListener extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    let ids = this.getIdsFromURL();
    if(ids.siteId){
      this.setNewSite(ids.siteId);
    }else{
      this.props.updateLoading({siteData: false})
    }
    // im using firebase/index.js to do stuff that requires a logged in user

  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.pathname !== this.props.location.pathname){
      let ids = this.getIdsFromURL();
      let oldIds = this.getIdsFromURL(prevProps.location.pathname);
      if(oldIds.siteId !== ids.siteId && ids.siteId){
        // new site
        this.setNewSite(ids.siteId)
      }
    }
  }

  setNewSite = id => {
    db.collection("sites").doc(id)
    .get()
    .then(res => {
      this.props.updateTemplateData(res.data().data);
      this.props.updateSiteData({...res.data(), data: undefined, id: res.id})
      this.props.updateLoading({siteData: false})
    });
  }

  getIdsFromURL = (url) => {
    let data = url ? url.split('/') : this.props.location.pathname.split('/');
    let siteId = data.indexOf('site')>-1 ? data[data.indexOf('site')+1] : null;
    return {
      siteId,
    }
  }

  render() {console.log(this.props); return null }
}



const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser

});
const mapActionsToProps = {
  updateTemplateData,
  updateSiteData,
  updateLoading
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(URLListener))