import React, { Component } from 'react';
import { Input } from 'antd';
import colors from '../colors';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, inputProps, wrapperStyle, subtext } = this.props;
    return(
      <div style={{...styles.wrapper, ...wrapperStyle}}>
        {label ? <div style={styles.label}>{label}</div> : null}
        {(inputProps || {}).rows ? 
        <Input.TextArea {...inputProps}/>
        :
        <Input {...inputProps}/>
        }
        {subtext ? <div style={styles.subtext}>{subtext}</div> : null}
      </div>
    );
  }
}

const styles = {
  wrapper: {

  },
  label: {
    fontSize: 12,
    color: colors.lightText,
    marginLeft: 3
  },
  subtext: {
    fontSize: 12,
    color: colors.lightText
  }
};

export default InputText;