export default {
  editorInputs: [
    {
      label: "Dark Mode",
      type: "bool",
      dataKey: "darkMode",
    },
    {
      label: "Section Title",
      dataKey: "title",
      type: "text",
      header: "",
    },
    {
      label: "Section Description",
      dataKey: "desc",
      type: "text",
      header: "",
      inputProps: {
        multiline: true,
        rows: 2,
      },
    },
    {
      label: "",
      dataKey: "items",
      type: "items",
      header: "",
      items: [
        {
          label: "Name",
          dataKey: "name",
          type: "text",
        },
        {
          label: "Details (optional)",
          dataKey: "desc",
          type: "text",
          inputProps: {
            multiline: true,
            rows: 2,
          },
        },
        {
          label: "Image",
          type: "image",
          dataKey: "imageUrl",
          inputProps: {
            compact: true,
          },
        },
      ],
    },
  ],
};
