import React, { Component } from "react";
import { Modal } from "antd";
import ServiceItem from "./ServiceItem";
import shortid from "shortid";
import { connect } from "react-redux";

class SelectServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDefaultServiceData = () => {
    return {
      title: "Custom Service",
      desc: "Enter your own details about the service here after selecting this option.",
      price: "$123",
      id: shortid.generate(),
    };
  };

  handleOk = () => {};

  onSelectItem = (service) => {
    this.props.onSelectService({
      ...service,
      id: shortid.generate()
    });
    this.props.close();
  };

  render() {
    const { visible, close, onSelectService } = this.props;
    return (
      <Modal
        footer={null}
        title="Add Service"
        visible={visible}
        // onOk={this.handleOk}
        onCancel={close}
        // width={1000}
        bodyStyle={styles.modalBody}
      >
        <div style={styles.inside}>
          <ServiceItem item={this.getDefaultServiceData()} onClick={() => this.onSelectItem(this.getDefaultServiceData())} />
          {this.props.siteData.services.list.map((s) => {
            return <ServiceItem item={s} onClick={() => this.onSelectItem(s)} />;
          })}
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalBody: {
    height: "60vh",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  inside: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 8,
  },
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(SelectServiceModal);
