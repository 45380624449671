import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <h1>Test HOMEPAGE</h1>
        <p>Logged in: {this.props.loggedInUser.email ? "true" : "false"}</p>
        <br/>
        <br/>
        <Link to="/login">Login</Link>
        <br />
        <br />
        <Link to="/signup">Signup</Link>
        <br />
        <br />
        <Link to="/new">Get Started</Link>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    padding: 30
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(Homepage);
