import React, { Component } from "react";
import { Modal, Button } from "antd";
import shortid from "shortid";
import { connect } from "react-redux";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

class AddToCalendarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getEvent = () => {
    const { order } = this.props;

    const event = {
      title: "Job for: "+order.customerName,
      description: `View job: ${window.location}`,
      start: new Date(order.scheduledFor),
      duration: [1, "hour"],
      location: order.customerAddress.formatted_address,
      // url: window.location
    };
    return event;
  };

  onGoogle = () => {
    let link = google(this.getEvent());
    window.open(link, "_blank");
    this.props.onCancel();
  };

  onOutlook = () => {
    let link = outlook(this.getEvent());
    window.open(link, "_blank");
    this.props.onCancel();
  };

  onOffice = () => {
    let link = office365(this.getEvent());
    window.open(link, "_blank");
    this.props.onCancel();
  };

  onYahoo = () => {
    let link = yahoo(this.getEvent());
    window.open(link, "_blank");
    this.props.onCancel();
  };

  onical = () => {
    let link = ics(this.getEvent());
    window.open(link, "_blank");
    this.props.onCancel();
  };

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        footer={null}
        title="Add Job to Your Calendar"
        visible={visible}
        // onOk={this.handleOk}
        onCancel={onCancel}
        // width={1000}
        bodyStyle={styles.modalBody}
      >
        <div style={styles.inside}>
          <div style={{ ...styles.button, backgroundColor: "#2B9D4A" }} onClick={this.onGoogle}>
            {/* <img src={googleIcon} style={{...styles.logo}} /> */}
            <div style={styles.text}>Google</div>
          </div>

          <div style={{ ...styles.button, backgroundColor: "#217ECC" }} onClick={this.onOutlook}>
            {/* <img src={googleIcon} style={{...styles.logo}} /> */}
            <div style={styles.text}>Outlook</div>
          </div>

          <div style={{ ...styles.button, backgroundColor: "#F33B00" }} onClick={this.onOffice}>
            {/* <img src={googleIcon} style={{...styles.logo}} /> */}
            <div style={styles.text}>Office 365</div>
          </div>

          <div style={{ ...styles.button, backgroundColor: "#6001D2" }} onClick={this.onYahoo}>
            {/* <img src={googleIcon} style={{...styles.logo}} /> */}
            <div style={styles.text}>Yahoo</div>
          </div>

          <div style={{ ...styles.button, backgroundColor: "black" }} onClick={this.onical}>
            {/* <img src={googleIcon} style={{...styles.logo}} /> */}
            <div style={styles.text}>iCal</div>
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalBody: {},
  inside: {
    // padding: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  button: {
    // width: '100%',
    marginBottom: 20,
    borderRadius: 3,
    height: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 15,
    cursor: 'pointer'
  },
  text: {
    // flex: 1,
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
    fontSize: 18,
  },
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(AddToCalendarModal);
