import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import colors from '../../../colors';

class TitleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: true
    };
  }

  render() {
    const { groupData={}, uncategorized } = this.props;
    return(
      <Collapse isOpened={this.state.isOpened}>
        <div style={styles.inside}>
          <div style={styles.groupSection}>
            <div style={styles.groupLeft}>
              {uncategorized ? <div style={styles.titleInput}>Uncategorized</div> : <Input style={styles.titleInput} value={groupData.title} /> }
              

            </div>
            <div style={styles.groupRight}>
              <div style={styles.upDownContainer}>
                <UpOutlined />
                <div style={{height: 10}} />
                <DownOutlined />
              </div>
            </div>
          </div>
          <div style={styles.itemsSection}>

          </div>
        </div>
      </Collapse>
    );
  }
}

const styles = {
  inside: {
    marginBottom: 30,
    width: '100%',
    padding: 10,
    borderRadius: 3,
    backgroundColor: colors.lightBG
  },
  groupSection: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupLeft: {

  },
  groupRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  itemsSection: {
    paddingLeft: 25
  },
  titleInput: {
    fontSize: 22
  },
  upDownContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export default TitleSection;