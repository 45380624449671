import React, { Component } from "react";
import { Modal, Anchor } from "antd";
import { connect } from "react-redux";
import { updateTemplateData } from "redux/actions";
import { generateTemplate, sections, all_sections_data, getSectionByName } from "mvptester-templates";
import shortid from "shortid";
import SectionCard from "./SectionCard";
const { Link } = Anchor;

// const sectionTypeDisMapping = {
//   contactus: 'Contact Us',

// }

class AddNewSectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLeftBar: false
    };
  }

  componentDidUpdate(prevProps, prevState){

    // gotta render this after modal animates and is in place, the left bar glitches out
    if(!prevProps.visible && this.props.visible){
      setTimeout(() => this.setState({showLeftBar: true}), 300);
    }
    if(prevProps.visible && !this.props.visible){
      setTimeout(() => {
        this.setState({showLeftBar: false})
      }, 300);
    }
  }

  handleOk = () => {};

  onClickSection = (section) => {
    const { templateData } = this.props;
    let newList = [...templateData.sections];
    let callerIndex;
    newList.forEach((x, index) => {
      if (x.uid === this.props.addSectionCallerUid) callerIndex = index;
    });
    const newItemUid = shortid.generate();
    let newItem = {
      name: section.name,
      active: true,
      uid: newItemUid,
    };
    // add default data
    let defaultData = all_sections_data[getSectionByName(section.name).defaultDataKey];
    newList.splice(callerIndex + 1, 0, newItem);
    this.props.updateTemplateData({
      ...templateData,
      sections: newList,
      [newItemUid]: defaultData,
    });
    this.props.onCancel();
  };

  getGroupedSections = () => {
    let types = {};
    Object.values(sections).forEach((s) => {
      if(s.isNotAddable) return;
      let sectionType = s.sectionType || "Other";
      if (types[sectionType]) {
        types[sectionType].sections.push(s);
      } else {
        types[sectionType] = {
          sections: [s],
          sectionType: sectionType,
        };
      }
    });
    let groupedSections = Object.values(types);
    groupedSections = groupedSections.sort((a, b) => a.sectionType.toLowerCase() > b.sectionType.toLowerCase());
    return groupedSections;
  };

  render() {
    const { visible, onCancel, addSectionCallerUid } = this.props;
    const { showLeftBar } = this.state;
    let groupedSections = this.getGroupedSections();
    return (
      <Modal footer={null} title="Add a Section" visible={visible} onOk={this.handleOk} onCancel={onCancel} width={1000} bodyStyle={styles.modalBody}>
 
          <div style={styles.left}> 
          {showLeftBar ? 
            <Anchor style={{marginLeft: 5}} getContainer={() => this.right || document.body} offsetTop={20}>
              {/* <Link href="#components-anchor-demo-basic" title="Basic demo" />
              <Link href="#components-anchor-demo-static" title="Static demo" /> */}
              {groupedSections.map((gs) => {
                return <Link href={`#${gs.sectionType}-section-anchor`} title={gs.sectionType} />;
              })}
            </Anchor>
          :null}
          </div>
          <div style={styles.right} ref={x => this.right = x}>
            <div style={{height: 22}} />
            {groupedSections.map((gs) => {
              return (
                <div style={styles.sectionCardGroup} id={`${gs.sectionType}-section-anchor`}>
                  <div style={styles.sectionTypeTitle}>{gs.sectionType}</div>
                  <div style={styles.sectionCardRow}>
                    {gs.sections.map((s) => {
                      if(s.isNotAddable) return null;
                      return <SectionCard onClick={() => this.onClickSection(s)} item={s} />;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
      
      </Modal>
    );
  }
}

const styles = {
  inside: {
    display: "flex",
    flexDirection: "row",
  },
  left: {
    paddingRight: 30,
    minWidth: 150,
    overflow: 'auto',
    height: '100%'
  },
  right: {
    display: "flex",
    flexDirection: "column",
    overflow: 'auto',
    height: '100%',
    flex :1
  },
  sectionCardGroup: {
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
  },
  sectionCardRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  sectionTypeTitle: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    marginBottom: 15
  },
  modalBody: {
    height: '60vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row'
  }
};

const mapStateToProps = (state) => ({
  templateData: state.templateData,
});
const mapActionsToProps = {
  updateTemplateData,
};
export default connect(mapStateToProps, mapActionsToProps)(AddNewSectionModal);
