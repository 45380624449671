import { Tag } from 'antd';
import React, { Component } from 'react';

class PopupPill extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { show, message } = this.props;

    let tagStyle = {

    };

    let outsideStyle = {
      height: show ? 22 : 0,
      overflow: 'hidden',
      transition: '.2s',
      marginTop: show ? 2 : 0,
      marginBottom: show ? 3 : 0
    }

    return(
      <div style={outsideStyle}>
        <Tag style={tagStyle} color='green'>{message}</Tag> 
      </div>
    );
  }
}

const styles = {

};

export default PopupPill;