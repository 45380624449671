import React, { Component } from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import markerIcon from '../../assets/img/pin.png';

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={4.3} defaultCenter={{ lat: 39.6272, lng: -99.9903 }}>
      {props.orders.map((item, index) => {
        // console.log('order', order)
        return (
          <Marker
            position={{ lat: item.customerAddress.lat, lng: item.customerAddress.lng }}
            onClick={() => props.onClick(item)}
            title={`${item.customerAddress.dis}`}
            // opacity={props.selectedIds && !props.selectedIds.includes(item.ID) ? 0.5 : 1}
            icon={{
              url: markerIcon,
              scaledSize: { width: 30, height: 30 }
            }}
            options={{ icon: { scaledSize: 5 } }}
            key={"marker" + item.id}
            place={item.customerAddress.place_id}
          />
        );
      })}
    </GoogleMap>
  ))
);

class JobMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Map
        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyAbQMQgERXR5QgUfcdc3M2YjOFKGhfETNY&v=3.exp&libraries=geometry,drawing,places"}
        loadingElement={<div style={{ ...styles.map }} />}
        containerElement={<div style={{ ...styles.map }} />}
        mapElement={<div style={{ ...styles.map, borderRadius: 3 }} />}
        orders={this.props.orders}
        onClick={this.props.onClickOrder}
        // selectedIds={selectedIds}
      />
    );
  }
}

const styles = {
  map: { 

    // minHeight: '100%',
    // width: '100%'
    height: '100%'
    
  }
};

export default JobMap;
