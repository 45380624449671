import React, { Component } from "react";
import { Alert, Button, Checkbox, Dropdown, Input, Modal, Popconfirm, Menu, message } from "antd";
import { connect } from "react-redux";
import ItemBox from "../../../screens/orders/MyServicesPage/ItemBox";
import InvoiceLine from "./InvoiceLine";
import colors from "../../../colors";
import parsePrice from "../../../helpers/parsePrice";
import Dinero from 'dinero.js'
import { AreaChartOutlined, DeleteOutlined, EyeOutlined, MoreOutlined, SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
import PreviewLineItem from "./PreviewLineItem";
import { db } from "../../../firebase";
import { isAndroid, isMobile } from "react-device-detect";

const taxRateRegex = /^[0-9]*.?[0-9]{0,4}$/;

let defaultState = {
  taxPercent: 5.75,
  hasTax: false,
  selectedLineItemIds: [],
  activeInvoice: {},
}

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    if(props.currentUser.taxPercent) this.state.taxPercent = props.currentUser.taxPercent;
  }

  componentDidMount(){

    // if(!this.props.order.id) console.warn("Error: do not render this InvoiceModal until we have the order loaded")
    Dinero.defaultCurrency=this.props.currentUser.currency || 'USD';

  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.visible && !prevProps.visible){
      this.resetState()
    }
  }

  resetState = () => {
    this.setState(defaultState, () =>{
      this.selectAllEligibleItems();
      // this.props.loadAllInvoices();
      if(this.props.preselectedInvoice){
        this.setState({activeInvoice: this.props.preselectedInvoice}, () => {
          this.props.clearPreselectedInvoice()
        })
      }
    })
  }

  getActiveServices = () => {
    return this.props.order.requestedServices.filter(s => {
      if(this.state.selectedLineItemIds.indexOf(s.id) < 0 || s.hasBeenInvoiced){
        return false
      }
      return true;
    })
  }

  selectAllEligibleItems = () => {
    let selectedLineItemIds = [];
    this.props.order.requestedServices.forEach(s => {
      if(s.hasBeenInvoiced){

      }else{
        selectedLineItemIds.push(s.id)
      }
    })
    this.setState({selectedLineItemIds})
  }


  getInvoiceSubtotal = () => {
    
    let total = Dinero();
    this.getActiveServices().forEach(s => {
      let price = parsePrice(s.price);
      total = total.add(price);
    })
    return total;
  }

  getTaxAmount = () => {
    let isTaxPercentValid = taxRateRegex.test(this.state.taxPercent);
    let subtotal = this.getInvoiceSubtotal();
    if(this.state.hasTax && subtotal.getAmount() > 0){
      let amount = subtotal.multiply((isTaxPercentValid ? this.state.taxPercent : 0) /100)
      return amount
    }else{
      return Dinero()
    }
  }


  getTotalInvoicePrice = () => {
    let subtotal = this.getInvoiceSubtotal();
    let total = Dinero();
    if(this.state.hasTax){
      total = subtotal.add(this.getTaxAmount());
    }else{
      total = subtotal
    }
    return total;
  }

  onChangeTaxPercent = (e) => {
    if(taxRateRegex.test(e.target.value) || !e.target.value) this.setState({taxPercent: e.target.value})
    
  }

  onChangeHasTax = e => {
    this.setState({hasTax: e.target.checked})
  }

  getFormattedLineItems = () => {
    let formatted = [];
    this.getActiveServices().forEach(s => {
      let item = {};
      item.price = parsePrice(s.price).getAmount();
      item.title = s.title;
      formatted.push(item)
    })
    return formatted
  }

  checkMultipleCurrencies = () => {
    let current = (this.props.currentUser.currency || 'usd').toLowerCase();
    let multipleCurrencies = [];
    this.props.existingInvoices.forEach(inv => {
      if(inv.currency !== current){
        multipleCurrencies.push(inv.currency)
      }
    })
    return multipleCurrencies;
  }

  generateInvoice = () => {
    let multipleCurrencyRes = this.checkMultipleCurrencies();
    if(multipleCurrencyRes.length>0) return alert(`You cannot create multiple invoices with different currencies for the same customer. You are currently using ${(this.props.currentUser.currency || 'usd').toUpperCase()}, but already have invoices with ${JSON.stringify(multipleCurrencyRes)}. Go to Settings at the top of the page, and change your currency, or create a new order.`);
    if(this.getActiveServices().length===0) return alert("You dont have any line items selected to put on the invoice")
    return new Promise(resolve => {
      this.setState({isGeneratingInvoice: true})
      return global.firebaseApi(`/generateInvoice`, {body: {
        orderId: this.props.order.id,
        lineItems: this.getFormattedLineItems(),
        taxRate: this.state.hasTax ? this.state.taxPercent : undefined
      }}).then(res => {
        this.setState({activeInvoice: res.invoice, isGeneratingInvoice: false}, () => {
          this.updateLineItems();
          resolve(res.invoice);
          this.props.loadAllInvoices();

        })
        if(this.state.hasTax) db.collection('users').doc(this.props.loggedInUser.id).update({taxPercent: this.state.taxPercent})
      })
    })
  }

  updateLineItems = () => {
    let newLineItems = this.props.order.requestedServices.map(s => {
      let hasBeenInvoiced = this.state.selectedLineItemIds.includes(s.id) || s.hasBeenInvoiced;
        return({
          ...s,
          hasBeenInvoiced: hasBeenInvoiced ? true : false
        })
    })
    this.props.updateLineItems(newLineItems)
  }

  onPreview = () => {
    window.open(this.state.activeInvoice.hosted_invoice_url, '_blank').focus();
  }

  onChangeSelectItem = (id, val) => {
    let newSelectedLineItemIds = [...this.state.selectedLineItemIds];
    let existingIndex = newSelectedLineItemIds.indexOf(id);
    if(existingIndex > -1){
      newSelectedLineItemIds.splice(existingIndex, 1);
    }else{
      newSelectedLineItemIds.push(id)
    }
    this.setState({selectedLineItemIds: newSelectedLineItemIds})
  }

  selectInvoice = inv => {
    this.setState({activeInvoice: inv})
  }

  onConfirmDeleteInvoice = () => {
    // use a backend func to delete the invoice from stripe
    // after that, reload existingInvoices
    // they will have to un-mark the line items as invoiceed manually for now
    // this.setState({isVoiding: true})
    global.firebaseApi(`/voidInvoice`, {body: {invoiceId: this.state.activeInvoice.id}}).then(() => {
      this.props.loadAllInvoices();
      this.props.onCancel();
    })

  }

  markAsPaid = () => {
    this.setState({isMarkingPaid: true})
    global.firebaseApi(`/markInvoicePaid`, {
      body: {
        invoiceId: this.state.activeInvoice.id
      }
    }).then(res => {
      this.props.loadAllInvoices();
      message.success("Marked as paid!");
      this.setState({isMarkingPaid: false})
    })
  }

  onText = () => {
    const { siteData } = this.props;
    // if(isMobile){
    //   document.location.href = `sms:${this.props.order.customerPhone}${isAndroid ? '?' : '&'}body=View and pay your invoice: ${this.state.activeInvoice.hosted_invoice_url}`
    // }else{
      this.setState({isTextingInvoice: true})
      global.firebaseApi('/sendInvoiceViaText', {body: {
        invoiceUrl: this.state.activeInvoice.hosted_invoice_url,
        orderId: this.props.order.id
      }}).then(res => {
        this.setState({isTextingInvoice: false})
        message.success("Sent!")
      })
    // }
  }

  onEmail = () => {
    document.location.href = `mailto:${this.props.order.customerEmail}?subject=Your Invoice&body=View and pay your invoice: ${this.state.activeInvoice.hosted_invoice_url}`
  }


  render() {
    const { visible, onCancel, order, existingInvoices, existingInvoicesLoading } = this.props;
    const { selectedLineItemIds, isGeneratingInvoice, activeInvoice={}, isTextingInvoice } = this.state;

    const { status } = activeInvoice;

    // console.log('active invoice', activeInvoice)


    let generateInvoiceContent = (
      <>
          {/* <Alert
            message={
              <div style={styles.alreadySentWrapper}>
                Past Invoices{' '} {existingInvoicesLoading ? <ClipLoader size={15} style={{marginLeft: 5}} /> : 
                <>
                {existingInvoices.length>0 ? <>({existingInvoices.length}):</> : ': none'}
                </>} 
                {existingInvoices.map(inv => {
                  return <div onClick={() => this.selectInvoice(inv)} style={styles.invoicePill}>{`$${Dinero({amount: inv.amount_due}).toFormat('0,0.00')}`}</div>
                })}
              </div>
            }
            style={styles.alreadySentAlert}
          /> */}
      
          <div style={styles.sectionTitle}>Items</div>
          {order.requestedServices.map(s => {
            return <InvoiceLine item={s} isChecked={selectedLineItemIds.indexOf(s.id)>-1} onChange={this.onChangeSelectItem} />
          })}
          <div style={styles.taxRow}>
            <div style={styles.taxLeft}>
              <div style={styles.taxLabel}>
                <Checkbox onChange={this.onChangeHasTax} checked={this.state.hasTax} style={{marginRight: 6}}/>
                {/* <span>Include</span> */}
                <Input size='small' style={{width: 60, marginLeft: 5}} value={this.state.taxPercent} onChange={this.onChangeTaxPercent} disabled={!this.state.hasTax} />
                <span>% Tax</span>
              </div>
            </div>
            <div style={styles.taxRight}>{this.getTaxAmount().toFormat('$0,0.00')}</div>
          </div>
          <div style={styles.totalRow}>
            <div style={styles.totalText}>Total</div>
            <div style={styles.totalPrice}>{this.getTotalInvoicePrice().toFormat('$0,0.00')}</div>
          </div>
      </>

    )

    const sendButtons = (
      <>
      <div style={styles.sendToCustomer}>Send to customer:</div>  
      {order.customerPhone  ? (
            <Button style={styles.bottomButton} block loading={isGeneratingInvoice || isTextingInvoice} type="primary" icon={<SendOutlined />} onClick={this.onText}>
              Text ({order.customerPhone})
            </Button>
          ) : null}
          {order.customerEmail ? (
            <Button style={styles.bottomButton} block loading={isGeneratingInvoice} type="primary" icon={<SendOutlined />} onClick={this.onEmail}>
              Email ({order.customerEmail})
            </Button>
          ) : null}
      </>
    )


    const dropdownMenu = (
      <Menu style={{width: 130}}>
        {status === 'open' ? 
          <Menu.Item>
            <div style={styles.deleteButton}>
              <Popconfirm onConfirm={this.onConfirmDeleteInvoice} title="Are you sure you want to void this invoice?" okText='VOID' okButtonProps={{type: 'danger'}}>
                {/* <DeleteOutlined style={{color: colors.red, fontSize: 18}} /> */}
                <div style={{color: colors.red}}>Mark as VOID</div>
              </Popconfirm>
            </div>
          </Menu.Item>
        :null}
        {status === 'open' ? 
          <Menu.Item onClick={this.markAsPaid}>
            {this.state.isMarkingPaid ? <ClipLoader size={18} /> : <div>Mark as Paid</div>}
          </Menu.Item>
        :null}
      </Menu>
    )

    let paidAtTimestamp = ((activeInvoice.status_transitions || {}).paid_at || 0)*1000;
    let paidAtStr = dayjs(new Date(paidAtTimestamp)).format('MMM DD, YYYY hh:mm a')

    let activeInvoiceContent = (
      <>
        <Button size={'small'} loading={isGeneratingInvoice} icon={<EyeOutlined />} onClick={this.onPreview} style={{marginBottom: 8}}>
          Preview
        </Button>
        <PreviewLineItem label="Amount: " value={Dinero({ amount: activeInvoice.amount_due || 0 }).toFormat("0,0.00")} />
        <PreviewLineItem label="Created At: " value={dayjs(new Date(activeInvoice.created * 1000)).format("MMM DD, YYYY hh:mm a")} />
        <PreviewLineItem label="Status: " value={status} />
        {status === 'paid' ? <PreviewLineItem label="Paid at: " value={paidAtStr} /> : null}
        <div style={styles.bottomButtons}>
          {status === 'open' ? sendButtons : null}
          
        </div>
      </>
    );

    return (
      <Modal
        title={
          activeInvoice.id ? 
            <div style={styles.invoiceTitleRow}>
              <div style={styles.invoiceTitle}>Invoice Summary</div>
              {status === 'open' ? 
                <Dropdown overlay={dropdownMenu} trigger='click'>
                  <MoreOutlined style={{padding: 5, paddingLeft: 25, paddingRight: 20}} />
                </Dropdown>
              :null}
            </div>
           : 'New Invoice'}
        visible={visible}
        footer={activeInvoice.id ? null :
          <div style={styles.footer}>
            <Button loading={isGeneratingInvoice} type='primary' onClick={this.generateInvoice}>Generate Invoice</Button>
          </div>
        }
        okText="Send"
        okButtonProps={{
          icon: <SendOutlined />
        }}
        onCancel={onCancel}
        // width={1000}
        zIndex={60}
        bodyStyle={styles.modalBody}
      >
        <div style={styles.inside}>
          {activeInvoice.id ? activeInvoiceContent : generateInvoiceContent}
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalBody: {
    maxHeight: 500,
    overflowY: "auto",
  },
  inside: {
    position: 'relative'
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 10,
    borderBottom: '1px solid '+colors.text
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderTop: '1px solid '+colors.text,
    marginTop: 10

  },
  totalText: {
    fontSize: 20
  },
  totalPrice: {
    fontSize: 20,

  },
  taxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  taxLeft: {

  },
  taxRight: {

    paddingLeft: 8
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  alreadySentAlert: {
    marginTop: 5,
    marginBottom: 10
  },
  invoicePill: {
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 5,
    borderRadius: 3,
    backgroundColor: colors.main,
    color: '#fff',
    cursor: 'pointer',
    marginLeft: 5,
    marginBottom: 5
  },
  invoiceTitleRow: {
    // borderBottom: `1px solid ${colors.border}`,
    // marginBottom: 5,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
    // paddingBottom: 5
  },
  invoiceTitle: {

  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  bottomButton: {
    // width: '100%',
    marginTop: 10
  },
  deleteButton: {
    // marginLeft: 15
  },
  alreadySentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  sendToCustomer: {
    textAlign: 'center',
    fontSize: 20,
    marginTop: 7,
  }
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  loggedInUser: state.loggedInUser,
  siteData: state.siteData

});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(InvoiceModal);
