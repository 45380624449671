import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTemplateData } from 'redux/actions';
import InputText from '../../InputText';
import EditSectionClass from './shared/EditSectionClass';

class EditSignupBanner extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeTitle = e => {
    let newData = {...this.props.sectionData};
    newData.title = e.target.value;
    this.editData(newData);
  }

  render() {
    const { templateData, sectionData } = this.props;
    return(
      <div style={styles.wrapper}>
          <InputText
          label={"Title"}
          inputProps={{
            onChange: this.onChangeTitle,
            value: sectionData.title
          }}
        />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'scroll',
    padding: 10,
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(mapStateToProps, mapActionsToProps)(EditSignupBanner);