import React, { Component } from "react";
import { updateTemplateData } from "redux/actions/index";
import { connect } from "react-redux";
import InputText from "components/InputText";
import colors from "colors";
import { Card, Checkbox, Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import EditableItemCard from '../../../EditableItemCard';
import AddButtonRow from "../../AddButtonRow";
import EditSectionClass from "../shared/EditSectionClass";

class Pricing extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeText = (name, text) => {
    this.editData({[name]: text})
  };

  changePlanData = (name, index, text) => {
    let newPlans = this.props.sectionData.plans;
    newPlans[index][name] = text;
    this.editData({plans: newPlans})
  };

  changeOption = (i, val) => {
    let newOptions = this.props.sectionData.options;
    newOptions[i] = val;
    this.editData({options: newOptions})
  };

  includeItem = (itemIndex, optionIndex, val) => {
    let newData = {...this.props.sectionData};
    newData.plans[itemIndex]['includedOptions'][optionIndex] = val;
    this.editData(newData);
  }

  deleteOption = optIndex => {
    let newData = {...this.props.sectionData};
    newData.options.splice(optIndex, 1);
    this.editData(newData)
  }

  deletePlan = i => {
    let newData = {...this.props.sectionData};
    newData.plans.splice(i,1);
    this.editData(newData)
  }

  addPlan = () => {
    let newData = {...this.props.sectionData};
    newData.plans.push({includedOptions: [], buttonText: 'Purchase!'});
    this.editData(newData)
  }

  addOption = () => {
    let newData = {...this.props.sectionData};
    newData.options.push('');
    this.editData(newData)
  }

  render() {
    const { templateData, sectionData } = this.props;
    return (
      <div style={styles.wrapper} >
        <InputText
          label={"Title"}
          inputProps={{
            onChange: e => this.changeText('title', e.target.value),
            value: sectionData.title
          }}
        />
        <div style={{ height: 20 }} />
        <InputText
          label={"Description"}
          inputProps={{
            onChange: e => this.changeText('subtext', e.target.value),
            value: sectionData.subtext,
            rows: 4
          }}
          wrapperStyle={{marginBottom: 20}}
        />
        {sectionData.plans.map((item, index) => {
          return(
            <EditableItemCard
              onClose={() => this.deletePlan(index)}
              header={
                <InputText
                // label={"Plan Name"}
                inputProps={{
                  onChange: e => this.changePlanData('title', index, e.target.value),
                  value: item.title,
                  style: {fontSize: 20,fontWeight: 'bold'},
                  placeholder: 'Plan name...'
                  // size: 'small'
                }}
              />
              }
            >
              <div style={styles.row}>
                <InputText
                  label={"Price"}
                  inputProps={{
                    onChange: e => this.changePlanData('price', index, e.target.value),
                    value: item.price,
                    size: 'small'
                  }}
                  wrapperStyle={{width: '47%'}}
                />
                <InputText
                  label={"Time"}
                  inputProps={{
                    onChange: e => this.changePlanData('period', index, e.target.value),
                    value: item.period,
                    size: 'small'
                  }}
                  wrapperStyle={{width: '47%'}}
                />
              </div>
              <div style={{height: 10}}></div>
              {sectionData.options.map((option, optIndex) => {
                return(
                  <div style={styles.optionRow}>
                    <Checkbox
                      onChange={e => this.includeItem(index, optIndex, e.target.checked)}
                      checked={item.includedOptions[optIndex]}
                      style={{marginRight: 5}}
                    ></Checkbox>
                    {/* <div
                      style={item.includedOptions[optIndex] ? styles.checkUncheckButtonSelected : styles.checkUncheckButton}
                      onClick={() => this.includeItem(index, optIndex, true)}
                    >
                      <Icon type='check' />
                    </div>
                    <div
                      style={item.includedOptions[optIndex] ? styles.checkUncheckButton : styles.checkUncheckButtonSelected}
                      onClick={() => this.includeItem(index, optIndex, false)}
                    >
                      <Icon type='close' />
                    </div> */}
                    <InputText
                      // label={"Time"}
                      inputProps={{
                        onChange: e => this.changeOption(optIndex, e.target.value),
                        value: option,
                        size: 'small'
                      }}
                      wrapperStyle={{width: '55%'}}
                    />
                    <div style={styles.deleteLink} class='deleteLink' onClick={() => this.deleteOption(optIndex)}>
                      <DeleteFilled />
                    </div>
                  </div>
                )
              })}
              <div style={styles.addOptionButton} onClick={this.addOption}>+ Add Another Option</div>
              <InputText
                label={"Button Text"}
                inputProps={{
                  onChange: e => this.changePlanData('buttonText', index, e.target.value),
                  value: item.buttonText,
                  size: 'small'
                }}
              />
            </EditableItemCard>
          )
        })}
        <AddButtonRow label='Add Plan' onClick={this.addPlan} />

       <br/>
      </div>
    );
  }
}

const checkUncheckButtonStyle = {
  marginRight: 7,
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  width: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'scroll',
    padding: 10,
  },
  addOptionButton: {
    color: colors.main,
    // fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: 12,
    marginTop: -4,
    marginLeft: 40
  },

  // pricebox
  card: {
    marginTop: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkUncheckButton: {
    ...checkUncheckButtonStyle,
    borderColor: colors.border,
    opacity: .6
  },
  checkUncheckButtonSelected: {
    ...checkUncheckButtonStyle,
    borderColor: 'black'
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8
  },
  deleteLink: {
    marginLeft: 8
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
  updateTemplateData
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Pricing);
