import React, { Component } from 'react';

class GodaddyDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <div style={styles.text}>

        </div>
        <a href='https://ae.godaddy.com/help/add-an-a-record-19238' target='_blank'>View Directions on godaddy.com</a>
      </div>
    );
  }
}

const styles = {

};

export default GodaddyDirections;