import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import colors from '../../colors';

class SquareSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, value, valueStyle={}, children, width } = this.props;

    const styles = {
      wrapper: {
        // padding: 20,
        paddingLeft: 0,
        borderRadius: 3,
        width: isMobile ? '100%' : (width || '47%'),
        marginBottom: isMobile ? 7 : 15
      },
      title: {
        color: colors.lightText
      },
      value: {
        fontSize: 20,
        marginTop: 1
      }
    };

    return(
      <div style={styles.wrapper}>
        <div style={styles.title}>{title}</div>
        {children ? children : 
        <div style={{...styles.value, ...valueStyle}}>{value}</div>
        }
      </div>
    );
  }
}



export default SquareSection;