import React, { Component } from 'react';

class BluehostDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <a href='https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries' target='_blank'>View directions on bluehost.com</a>
      </div>
    );
  }
}

const styles = {

};

export default BluehostDirections;