import React, { Component } from "react";
import { connect } from "react-redux";
import InputText from "../../InputText";
import ColorPicker from "../../ColorPicker";
import colors from "../../../colors";
import EditSectionClass from "./shared/EditSectionClass";
import LogoSection from "./LogoSection";
import TopBanner from "./shared/TopBanner";
import { getSectionByName } from 'mvptester-templates';
import EditorInputSection from "../../input_sections/EditorInputSection";
import { Button, Input, Switch } from "antd";

class EditNavbar extends EditSectionClass {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeColor = e => {
    let colorVal = e.target.value;
    this.updateTemplateData({
      ...this.props.templateData,
      styles: {
        ...this.props.templateData.styles,
        mainColor: colorVal
      }
    });
  };

  onChangeUserEmail = e => {
    this.updateTemplateData({
      ...this.props.templateData,
      userEmail: e.target.value
    });
  }

  removeAllLinks = () => {
    this.editData({links: []})
  }

  getDefaultLinks = () => {
    // label, name, uid
    let links = [];
    this.props.templateData.sections.forEach(s => {
      let sProperties = getSectionByName(s.name);
      if(sProperties.defaultInNav) links.push({
        name: s.name,
        uid: s.uid,
        label: sProperties.label
      })
    })

    return links
  }

  onChangeNavLabel = (uid, val) => {
    let existingLinks = this.props.sectionData.links ? this.props.sectionData.links : this.getDefaultLinks();
    existingLinks = existingLinks.map(x => {
      if(x.uid === uid){
        return({
          ...x,
          label: val
        })
      }else{
        return x
      }
    })
    this.editData({links: existingLinks})
  }

  onChangeLink = (section, val, label) => {
    let existingLinks = this.props.sectionData.links ? this.props.sectionData.links : this.getDefaultLinks();
    if(val){
      let sProperties = getSectionByName(section.name);
      existingLinks.push({
        name: section.name,
        uid: section.uid,
        label: sProperties.label
      })
    }else{
      existingLinks = existingLinks.filter(x => x.uid !== section.uid);
    }
    this.editData({links: existingLinks})
  }

  render() {
    const { templateData, sectionData } = this.props;

    return (
      <div style={styles.wrapper}>
        <TopBanner title='Navigation bar' />
        <LogoSection />
        {this.props.templateData.sections.map(s => {
          let sData = this.props.templateData[s.uid];
          let sProperties = getSectionByName(s.name);
          let links = sectionData.links ? sectionData.links : this.getDefaultLinks();
          let thisLink = {};
          let isChecked = false;
          links.forEach(l => {
            if(l.uid === s.uid){
              isChecked = true;
              thisLink = l
            }
            
          })
          if(sProperties.allowInNav){
            return(
              <EditorInputSection label={sProperties.label} >
                <div style={styles.row}>
                  <Switch checked={isChecked} onChange={val => this.onChangeLink(s, val)} />
                  {isChecked ? 
                    <Input
                      size='small'
                      onChange={(e) => this.onChangeNavLabel(thisLink.uid, e.target.value)}
                      value={thisLink.label}
                      style={{width: 200, marginLeft: 10}}/> 
                    : null}
                </div>
            </EditorInputSection>
            )
          }
        })}
        <Button size='small' onClick={this.removeAllLinks}>De-select All Links</Button>

      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
};

const mapStateToProps = state => ({
  templateData: state.templateData
});
const mapActionsToProps = {
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(EditNavbar);
