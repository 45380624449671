import { Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import linkToSubdomain from '../helpers/linkToSubdomain';
import firebase from "firebase";

class SplashMobileWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  onLogout = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //
      });
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <div style={styles.logoutButton} onClick={this.onLogout}>
          log out
        </div>
        <img src={this.props.config.data.headerLogoUrl} style={styles.logo} />
        {/* <div style={styles.message}>You must use a desktop/laptop computer to manage and edit your {this.props.config.data.siteNameDis} site. </div> */}
        <div style={styles.message}>Signup Complete! The website builder will not fit on a phone screen - Please visit {this.props.config.data.siteNameDis} on your laptop / desktop computer to begin building your website. </div>
        <Button style={{marginTop: 15}} onClick={() => linkToSubdomain('orders')}>Go to Order Manager</Button>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    position: 'relative'
  },
  logo: {
    height: 70,
    marginBottom: 25
  },
  message: {
    textAlign: 'center',
    fontSize: 18
  },
  logoutButton: {
    position: 'absolute',
    cursor: 'pointer',
    padding: 6,
    top: 10,
    right: 10
  }

};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SplashMobileWarning);