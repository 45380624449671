import { Input } from "antd";
import React, { Component } from "react";
import colors from "../../../colors";
import { Button } from 'antd';
import { isMobile } from "react-device-detect";

class DataItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeTextHandler = t => {
    if(this.props.regex){
      if(this.props.regex.test(t)){
        this.props.onChangeText(t);
      }
    }else{
      this.props.onChangeText(t);
    }

  }

  render() {
    const { isEdit, label, children, onChangeText, value, buttons=[], alwaysShowChildren, desc } = this.props;

    let editContent = (
      children ? (
        children
      ) : (
        <>
          <Input onChange={(e) => this.onChangeTextHandler(e.target.value)} value={value} style={styles.input} />
        </>
      )
    );

    let viewContent = (
      children && alwaysShowChildren ? children : 
        <div style={styles.viewContent}>{value}</div>

    )

    if( value === undefined && isEdit === false && !children) return null;

    return (
      <div style={styles.wrapper}>
        <div style={styles.labelWrapper}>
        <div style={styles.label}>{label}</div>
        {desc ? <div style={styles.desc}>{desc}</div> : null}
        <div>
          {buttons.length>0 && !isEdit ? 
          buttons.map(button => {
            return <Button size='small' {...button} style={styles.button}>{button.label}</Button>
          })
          :null}

        </div>

        </div>
        <div style={styles.content}>
          {isEdit ? editContent : viewContent}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  label: {
    color: colors.lightText,
    // fontSize: 18

  },
  viewContent: {
    color: colors.text,
    // fontWeight: '700',
    marginTop: 2,
    fontSize: 18

  },
  button: {
    marginLeft: 15
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: isMobile ? 'space-between' : 'flex-start',
  },
  desc: {
    color: colors.lightText,
    fontSize: 12,
    marginLeft: 8
  }
};

export default DataItem;
