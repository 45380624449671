import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class SaveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onClick, loading } = this.props;

    let wrapperStyle = {

    }
    return(
      <div style={wrapperStyle}>
        <Button icon={<SaveOutlined />} type='primary' onClick={onClick} disabled={this.props.editsPending.templateData ? false : true} loading={loading}>{this.props.editsPending.templateData ? 'Save' : 'Saved'}</Button>
      </div>
    );
  }
}

const styles = {

};

const mapStateToProps = state => ({
  editsPending: state.editsPending
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(SaveButton);