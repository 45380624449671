import React, { Component } from 'react';
import PageTemplate from './PageTemplate';
import { Table, Divider } from 'antd';

class NewJobsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onRowClick = data => {
    this.props.history.push(`/designer/jobs/view/${data.id}`)
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // render: (text, record) => <div>1472</div>
      },
      {
        title: '# items',
        key: '#items',
        render: (text, record) => <div>2</div>
      },
      {
        title: 'Total Pay',
        dataIndex: 'address',
        key: 'address',
        render: (text, record) => <div>$48.00</div>
      },
     
      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <a>Invite {record.name}</a>
      //       <Divider type="vertical" />
      //       <a>Delete</a>
      //     </span>
      //   ),
      // },
    ];
    
    const data = [
      {
        id: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
      },
      {
        id: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
      },
      {
        id: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
      },
    ];
    return(
      <div style={styles.wrapper}>
        <PageTemplate>
        <h1>New Jobs</h1>
        <Table columns={columns} dataSource={data} pagination={false} onRowClick={this.onRowClick} />

        </PageTemplate>
      </div>
    );
  }
}

const styles = {

};

export default NewJobsPage;