import React, { Component } from "react";
import { Modal } from "antd";
import MediaScroll from "../media/MediaScroll";
import { connect } from "react-redux";
import {} from "redux/actions";
class ChooseMediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOk = () => {
    this.props.onSelectItem(this.props.media[this.state.selectedIndex]);
    this.props.onCancel();
  };

  onSelectImage = (index) => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        title="Choose Image"
        visible={visible}
        onOk={this.handleOk}
        okText="Select"
        okButtonProps={{ disabled: this.state.selectedIndex !== undefined ? false : true }}
        onCancel={onCancel}
        // width={1000}
        zIndex={60}
        bodyStyle={styles.modalBody}
      >
        <div style={styles.inside}>
          <MediaScroll onSelect={this.onSelectImage} selectedIndex={this.state.selectedIndex} images={this.props.media} width={"100%"} disableScroll={true} />
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalBody: {
    maxHeight: 500,
    overflowY: "auto",
  },
};

const mapStateToProps = (state) => ({
  media: state.media,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(ChooseMediaModal);
