import React, { Component } from "react";
import PageTemplate from "./PageTemplate";
import { Button } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import colors from "../../colors";
import Chat from "./components/Chat";
import TextArea from "antd/lib/input/TextArea";
import { Collapse, Modal, message } from "antd";
const { Panel } = Collapse;

const defaultImgSrc = require("assets/img/default.jpg");

class ViewJobPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: {}
    };
  }

  selectItem = data => {
    this.setState({ selectedItem: data });
  };

  closeClaimModal = () => {
    this.setState({isClaimModalVisible: false, isClaimModalSaving: false})
  }

  confirmAcceptJob = () => {
    this.setState({isClaimModalSaving: true})
    setTimeout(() => {
      this.closeClaimModal();
      this.props.history.push('/designer/jobs/mine')
      message.success('Job accepted!')
    }, 1000);
  }

  render() {
    const jobId = this.props.match.params.id;
    let isNew = false;

    return (
      <PageTemplate maxWidth={1500}>
                <Modal
          title="Accept Job"
          visible={this.state.isClaimModalVisible}
          // onOk={this.confirmDelete}
          okButtonProps={{
            loading: this.state.isClaimModalSaving
          }}
          // footer={null}
          onCancel={this.closeClaimModal}
          onOk={this.confirmAcceptJob}
          
        >
          <div>Are you sure you can do this job? It must be completed by 111/7/2019</div>
        </Modal>
        <div style={styles.topBar}>
          <div style={styles.topLeft}>
            <h1 style={{ margin: 0 }}>View Job: {jobId}</h1>
            <div style={{ marginRight: 15 }}>Status: In Progress</div>
          </div>
          <div style={styles.topRight}>
            {isNew ? (
              <Button type="primary" size="large" onClick={() => this.setState({isClaimModalVisible: true})}>
                Claim Job
              </Button>
            ) : (
              <Button>Mark Job Complete</Button>
            )}
          </div>
        </div>
        <div style={styles.body}>
          <div style={styles.left}>
            {/* <h3 style={{margin: 0}}>Job Items:</h3> */}
            <div style={styles.listBox}>
              <ListItem
                selected={false}
                onClick={() => this.selectItem(1)}
                isComplete={true}
                label={"Screenshot 22"}
              />
              <ListItem
                selected={true}
                onClick={() => this.selectItem(1)}
                isComplete={false}
                label={"Screenshot 32"}
              />
              <ListItem
                selected={false}
                onClick={() => this.selectItem(1)}
                isComplete={false}
                label={"Screenshot 42"}
              />
            </div>
          </div>
          <div style={styles.right}>
            <Collapse bordered={false} defaultActiveKey={[0, 1, 3]} style={{backgroundColor: 'transparent'}}>
            <Panel
                header={<h2 style={styles.sectionTitle}>Item Info</h2>}
                key={0}
                style={styles.panel}
              >
                <div style={styles.detailsSection}>

                  <div>Pay: $48.50</div>
                  <div>Type: Screenshot, iphonex</div>
                </div>
              </Panel>
              {!isNew ? (
                <Panel
                  header={<h2 style={styles.sectionTitle}>Media</h2>}
                  key={1}
                  style={styles.panel}
                >
                  <div style={styles.mediaSection}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={defaultImgSrc} style={styles.image} />
                      <div style={styles.imageLinkSection}>
                        <div style={styles.imageLink}>upload new image</div>
                        <div style={styles.imageLink}>download</div>
                        <div style={{ ...styles.imageLink, color: "red" }}>
                          remove
                        </div>
                      </div>
                      <div style={styles.infoSection}>
                        Images should be a 1200 x 800 .png format
                      </div>
                    </div>
                  </div>
                </Panel>
              ) : null}
              {!isNew ? (
                <Panel
                  header={<h2 style={styles.sectionTitle}>My Notes</h2>}
                  key={2}
                  style={styles.panel}
                >
                  <div style={styles.notesSection}>
                    <TextArea rows={4} />
                  </div>
                </Panel>
              ) : null}
              <Panel
                header={<h2 style={styles.sectionTitle}>Details</h2>}
                key={3}
                style={styles.panel}
              >
                <div style={styles.detailsSection}>

                  <Chat isNew={isNew}/>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const ListItem = ({ isComplete, label, selected, onClick }) => {
  let dotStyle = {
    ...styles.listDot,
    backgroundColor: isComplete ? "green" : "#fff",
    borderColor: isComplete ? "#fff" : "#ccc",
    borderWidth: isComplete ? 0 : 1
  };
  let listItemStyle = {
    ...styles.listItem,
    backgroundColor: selected ? colors.lightBG : "#fff"
  };
  return (
    <div style={listItemStyle} onClick={onClick} class="fadeHover">
      <div style={dotStyle}>
        {isComplete ? (
          <CheckOutlined style={{ color: "#fff" }} size={12} />
        ) : null}
      </div>
      <div style={styles.listItemText}>{label}</div>
    </div>
  );
};

const styles = {
  // page top
  topBar: {
    padding: 10,
    backgroundColor: colors.lightBG,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20
  },
  topLeft: {},
  topRight: {},

  //page body
  body: {
    display: "flex",
    flexDirection: "row"
  },
  left: {
    flex: 0.3
  },
  right: {
    flex: 0.7,
    backgroundColor: colors.lightBG,
    minHeight: 500,
    padding: 10,
    borderRadius: 3
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  // items list
  listBox: {
    // borderWidth: 1,
    // borderRadius: 3,
    // borderStyle: 'solid',
    // borderColor: '#ccc',
    marginTop: 10
  },
  listItem: {
    display: "flex",
    padding: 12,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    cursor: "pointer"
  },
  listDot: {
    height: 22,
    width: 22,
    borderRadius: 11,
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listItemText: {
    marginLeft: 10
  },

  // right side contents
  image: {
    maxHeight: 200,
    maxWidth: 200,
    marginRight: 15
  },
  mediaSection: {},
  detailsSection: {
    marginTop: 0
  },
  imageLinkSection: {},
  imageLink: {
    color: "blue",
    textDecoration: "underline",
    marginBottom: 10,
    cursor: "pointer"
  },
  infoSection: {
    marginLeft: 15
  },
  sectionTitle: {
    fontWeight: "bold"
  },
  panel: {
    backgroundColor: "transparent"
  }
};

export default ViewJobPage;
