import React, { Component } from "react";
import PageContentContainer from "../../../components/containers/PageContentContainer";
import SaveBar from "../../../components/SaveBar";
import { connect } from "react-redux";
import { updateSiteData } from "redux/actions";
import InputText from "components/InputText";
import DashboardTabClass from "../DashboardTabClass";
import { db } from "../../../firebase";
import DashboardInputSection from "../../../components/input_sections/DashboardInputSection";
import { Button, Radio, Checkbox, Table, Tabs, Alert } from "antd";
import GoogleDomainDirections from "./tutorials/GoogleDomainDirections";
import OtherDomainDirections from "./tutorials/OtherDomainDirections";
import GoogleDomainsTutorial from "./tutorials/GoogleDomainsTutorial";
import colors from "../../../colors";
import GodaddyDirections from "./tutorials/GodaddyDirections";
import NamecheapDirections from "./tutorials/NamecheapDirections";
import BluehostDirections from "./tutorials/BluehostDirections";
import { ClipLoader } from 'react-spinners';
const { TabPane } = Tabs;
let urlRegex = /^[a-zA-Z0-9-]+$/;
let customDomainRegex = /^[a-zA-Z0-9-.]+$/;

class DomainTab extends DashboardTabClass {
  constructor(props) {
    super(props);
  }

  onChangeSubdomain = (text) => {
    // TODO validate
    if (urlRegex.test(text) || !text) {
      this.setState({ uniqueSiteName: text });
      this.setState({ canSave: true });
    } else {
    }
  };

  onChangeCustomDomain = (text) => {
    if (customDomainRegex.test(text) || !text) {
      this.setState({ customDomainName: text });
      this.setState({ canSave: true });
    } else {
    }
  };

  onChangeIsCustomDomain = (e) => {
    this.onChangeSiteDataItem("usesCustomDomainName", e.target.value);
  };


  handleSaveDomain = async (name, isCustom) => {
    let exists = false;
    let domainKey = isCustom ? 'customDomainName' : 'uniqueSiteName';
    name = name.toLowerCase().trim();
    let shouldTriggerNewCert = this.state.customDomainName && this.props.siteData.usesCustomDomainName;

    let invalidSubdomains = ['app', 'mail', 'mg', 'storage', 'hosting', 'builder', 'website', 'bluecollarpages', 'detailpages', 'woodlandcoffee', 'domain', 'orders'];

    let newSiteName = name.toLowerCase().trim();
    newSiteName = newSiteName.replace('www.', '')
    if (!newSiteName) return alert("Please enter a domain name");
    if (!(isCustom ? customDomainRegex : urlRegex).test(newSiteName)) return alert(`Invalid domain name -  ${isCustom ? 'it should look something like "woodlandcoffee.com"' : 'Please use only letters, numbers, and dashes -'}`);
    if(!isCustom){
      let isValidName = true;
      invalidSubdomains.forEach(invalidName => {
        if(newSiteName.toLowerCase() === invalidName.toLowerCase()) isValidName = false;
      })
      if(isValidName === false) return alert("That doman name is already in use. Please try another name.");
    }
    let siteDocs = await db.collection("sites").where(domainKey, "==", newSiteName).limit(1).get();
    siteDocs.forEach((d) => {
      if (d.data()[domainKey] === newSiteName && d.data()[domainKey] !== this.props.siteData[domainKey]) exists = true;
    });
    if (exists) {
      alert(`The site name ${newSiteName} is already in use. Please choose another name.`);
    } else {
      this.onChangeSiteDataItem(domainKey, newSiteName);
      this.setState({ [domainKey]: undefined });
    }

    if(shouldTriggerNewCert) this.issueSsl();
    this.save();
  }

  beforeSave = async () => {
    const { customDomainName, uniqueSiteName } = this.state;
    const { usesCustomDomainName } = this.props.siteData;
    const newDomainNameToUse = usesCustomDomainName ? customDomainName : uniqueSiteName;
    if(newDomainNameToUse){
      this.handleSaveDomain(newDomainNameToUse, usesCustomDomainName)
    }else{
      this.save()
    }
  };

  issueSsl = (shouldSave) => {
    this.props.updateSiteData({
      ...this.props.siteData,
      shouldTryIssueSsl: true,
      shouldTryIssueSslStartDate: Date.now(),
      unableToIssueSsl: false,
      sslIssuedSuccess: false
    });
    setTimeout(() => {
      if(shouldSave) this.save();
    }, 100);
  }


  render() {
    const { isSaving, canSave, isCustomDomain } = this.state;
    const { siteData } = this.props;
    const { customDomainName, usesCustomDomainName } = siteData;
    const { siteNameValue, supportEmailDomain } = this.props.config.data;

    let uniqueSiteNameToUse = this.state.uniqueSiteName !== undefined ? this.state.uniqueSiteName : this.props.siteData.uniqueSiteName;
    let customDomainNameToUse = this.state.customDomainName !== undefined ? this.state.customDomainName : this.props.siteData.customDomainName;

    let wasDomainSslStartedRecently = (Date.now() -  (siteData.shouldTryIssueSslStartDate || 0)) < (1000*60*60*24*2) ? true : false;

    const freeDomainSection = (
      <DashboardInputSection
        label="Domain Name:"
        description={`https://${uniqueSiteNameToUse || "yoursite"}.${siteNameValue}.com`}
        info={`Choose your .${siteNameValue}.com domain name.`}
      >
        <InputText
          inputProps={{
            onChange: (e) => this.onChangeSubdomain(e.target.value.toLowerCase()),
            value: uniqueSiteNameToUse,
            addonAfter: `.${siteNameValue}.com`,
            addonBefore: "https://",
            style: { width: 450 },
          }}
        />
      </DashboardInputSection>
    );

    const customDomainSection = (
      <>

      
          <>
            <DashboardInputSection
              label="Domain Name:"
              description={`https://${customDomainNameToUse || "yoursite.com"}`}
              info={
                <div>
                  Enter your custom domain name, ex: <strong>yoursite.com</strong>. You will need to purchase & connect a domain name from a registrar for this option to work. For help with this, see below "I need help registering a domain name"
                </div>
              }
            >
              <InputText
                inputProps={{
                  onChange: (e) => this.onChangeCustomDomain( e.target.value.toLowerCase()),
                  value: customDomainNameToUse,
                  addonBefore: "https://",
                  style: { width: 450 },
                  placeholder: "yoursite.com",
                }}
              />
            </DashboardInputSection>
            <DashboardInputSection
              label="Your DNS Settings"
              info={
                <div>Set these records with your domain name registrar (Godaddy, Namecheap, etc). For help with this, see the directions below</div>
              }
            >
              <Table
                columns={[
                  { title: "hostname", dataIndex: "hostname" },
                  { title: "type", dataIndex: "type" },
                  { title: "value", dataIndex: "value" },
                ]}
                rowKey={(record) => record.hostname}
                dataSource={[
                  { hostname: "@", value: "3.130.46.58", type: "A" },
                  { hostname: "www", value: "3.130.46.58", type: "A" },
                ]}
                pagination={false}
              />
            </DashboardInputSection>
            {!siteData.unableToIssueSsl && wasDomainSslStartedRecently ?
              <Alert
                style={{marginTop: 20}}
                message={
                  <div>
                    Domain processing... It can take up to 48 hours for DNS records to update with your provider. You can periodically check your site to see if it's finished processing.
                    <ClipLoader size={12} color={colors.border} />
                  </div>
                }
                type='info'
                showIcon={true}
              />
            : null}
            {!siteData.sslIssuedSuccess && !siteData.shouldTryIssueSsl && siteData.unableToIssueSsl  ?
              <Alert
                style={{marginTop: 20}}
                type='warning'
                showIcon={true}
                message={
                  <div>Failed to issue SSL Certificate. Please make sure your DNS settings and domain name are correct. Contact support@{supportEmailDomain}.com if you need further assistance.
                    <Button size='small' style={{marginLeft: 10}} onClick={() => this.issueSsl(true)}>Retry</Button>
                  </div>
                }
              />
            : null}
            <div style={styles.hr} />
            <h1 style={{fontFamily: 'Montserrat'}}>Custom Domain Help</h1>
            <Radio.Group
              defaultValue={undefined}
              buttonStyle="solid"
              onChange={(e) => this.setState({ needsHelpRegisteringDomain: e.target.value })}
              value={this.state.needsHelpRegisteringDomain}
            >
              <Radio.Button value={false}>I already have my domain name</Radio.Button>
              <Radio.Button value={true}>I need help registering a domain name</Radio.Button>
            </Radio.Group>
            {this.state.needsHelpRegisteringDomain === true ? (
              <>
                <div style={styles.explinationText}>
                  Custom domain names must be purchased through an official domain name registrar such as Godaddy, Namecheap, Google Domains,
                  Bluehost, etc. We reccomend using Google Domains for the best price and ease of use. The tutorial below will guide you through the
                  steps using Google Domains.
                </div>
                <GoogleDomainsTutorial />
              </>
            ) : null}
            {this.state.needsHelpRegisteringDomain === false ? (
              <>
                <div style={styles.explinationText}>Here are some walkthroughs on how to connect your existing domain name using the DNS settings above. Select your domain registrar:</div>
                <a style={styles.domainLink} href='https://support.google.com/domains/answer/9211383?hl=en' target='_blank'>Google Domains</a>
                <a style={styles.domainLink} href='https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries' target='_blank'>Bluehost</a>
                <a style={styles.domainLink} href='https://ae.godaddy.com/help/add-an-a-record-19238' target='_blank'>Godaddy</a>
                <a style={styles.domainLink} href='https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain' target='_blank'>Namecheap</a>
                {/* <Tabs defaultActiveKey={"namecheap"}>
                  <TabPane key={"namecheap"} tab="Namecheap">
                    <NamecheapDirections />
                  </TabPane>
                  <TabPane key={"google"} tab="Google">
                    <GoogleDomainDirections />
                  </TabPane>
                  <TabPane key={"godaddy"} tab="GoDaddy">
                    <GodaddyDirections />
                  </TabPane>
                  <TabPane key={"bluehost"} tab="Bluehost">
                    <BluehostDirections />
                  </TabPane> */}
                  {/* <TabPane key={"other"} tab="Other">
                    <OtherDomainDirections />
                  </TabPane> */}
                {/* </Tabs> */}
              </>
            ) : null}
          </>
       
      </>
    );

    return (
      <PageContentContainer maxWidth={700}>
        <div style={styles.wrapper}>
          <SaveBar onSave={this.beforeSave} loading={isSaving} disabled={!canSave} />

          <DashboardInputSection
            label="Domain Type"
            description={""}
            info={
              <div>
                Do you want to use a free .{siteNameValue} domain name <strong>(www.yoursitehere.{siteNameValue}.com)</strong>, or do you want to connect your own
                domain name <strong>(www.yoursitehere.com)</strong> ?
              </div>
            }
          >
            <Radio.Group defaultValue={false} buttonStyle="solid" onChange={this.onChangeIsCustomDomain} value={usesCustomDomainName}>
              <Radio.Button value={false}>.{siteNameValue}.com Domain</Radio.Button>
              <Radio.Button value={true}>Custom Domain</Radio.Button>
            </Radio.Group>
          </DashboardInputSection>
          {usesCustomDomainName ? customDomainSection : freeDomainSection}
        </div>
      </PageContentContainer>
    );
  }
}

const styles = {
  explinationText: {
    marginTop: 10,
    marginBottom: 20
  },
  small: {
    color: colors.lightText,
    fontSize: 11,
    marginBottom: 2
  },
  hr: {
    height: 1,
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
    backgroundColor: colors.border,
    opacity: .6
  },
  domainLink: {
    display: 'block',
    marginBottom: 5
  }
};

const mapStateToProps = (state) => ({
  siteData: state.siteData,
  config: state.config
});
const mapActionsToProps = {
  updateSiteData,
};
export default connect(mapStateToProps, mapActionsToProps)(DomainTab);
