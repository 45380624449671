import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/app.css';
import './assets/css/bootstrap-grid.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './redux/store';
import 'antd/dist/antd.css';

import colors from './colors';
import firebaseApi from './firebase/api';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

var packageJSON = require('../package.json');

let isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('192.168');
Bugsnag.start({
  apiKey: 'e15f7828b0db805efe80c57ec2142b48',
  releaseStage: isLocalDev ? 'development' : 'production',
  appVersion: packageJSON.version,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);


global.myColors = colors;
global.firebaseApi = firebaseApi;


ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
        <App />
    </Provider>
  </ErrorBoundary>
  , document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
