import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import colors from '../../colors';
import Pill from '../DashboardNavbar/Pill';
import MobileMenu from './MobileMenu';
import menuIcon from '../../assets/img/menu.png';
import {firebase} from '../../firebase';
import { Button, Tooltip } from 'antd';
import linkToSubdomain from '../../helpers/linkToSubdomain';

class OrdersNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
      bankTooltipVisible: false
    };
  }

  componentDidMount(){
    this.checkIfShowTooltip()
  }

  checkIfShowTooltip = async () => {
    if(window.location.pathname.includes('/settings')) return;
    setTimeout(async () => {
      await global.firebaseApi("/getStripeOnboardingStatus").then(res => {
        if(res.status === 'new'){
          this.setState({bankTooltipVisible: true});
          setTimeout(() => {
            this.setState({bankTooltipVisible: false});
          }, 6000);
        }
      })
    }, 1000);
  }

  onSelectSection = (name) => {
    this.props.onSelectSection(name)

  }

  toggleMenu = () => {
    this.setState({isMobileMenuOpen: !this.state.isMobileMenuOpen})
  }

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //
      });
  };


  onClickSignup = () => {
    window.location = this.props.config.data.stripePaymentLink;
  }

  render() {
    const { selectedSection } = this.props;
    let siteId = this.props.match.params.siteId;

    const webRight = (
      <>
      {siteId ? 
        <>
          <Link style={styles.link} to={`/site/${siteId}/orders`}>Orders</Link>
          <div style={{width: 20}} />
          <Link style={styles.link} to={`/site/${siteId}/myservices`}>My Services</Link>
          <div style={{width: 20}} />
          <Tooltip title='Connect your bank account here in order to be able to recieve payments!' visible={this.state.bankTooltipVisible}>
            <Link style={styles.link} to={`/site/${siteId}/settings`}>Settings</Link>
          </Tooltip>
          <div style={{width: 20}} />
          <Link style={styles.link} to={`/`}>All Sites</Link>
          <div style={{width: 20}} />
        </>
        :null}

        <a onClick={() => linkToSubdomain('app')}>Website Editor</a>
        <div style={{width: 20}} />
        <div style={styles.link} onClick={this.logout}>Log out</div>
        {this.props.membershipInfo.canSignup ?
        <>
          <div style={{width: 12}} />
          <Button type='primary' onClick={this.onClickSignup}>Sign Up!</Button>
        </>
          :null}
        </>
    )

    const mobileRight = (
      <div style={styles.menuButtonWrapper} onClick={this.toggleMenu}>
        <img src={menuIcon} style={styles.menuIcon} />
      </div>
    )

    return(
      <div style={styles.wrapper}>
        <MobileMenu isOpen={this.state.isMobileMenuOpen} onClose={this.toggleMenu} />

        <div style={styles.top}>
          <div style={styles.left}>
            <img src={this.props.config.data.headerLogoUrl} style={styles.logo}/>
            <div style={styles.ordersLabel}>Order Management</div>
          </div>
          <div style={styles.middle}>
          </div>
          <div style={styles.right}>
            {isMobile ? mobileRight : webRight}
          </div>
        </div>
{/* 
        <div style={styles.bottom}>
        <Pill label="Quotes" onClick={() => this.onSelectSection("quotes")} isSelected={selectedSection === "quotes"} />
        <Pill label="Active" onClick={() => this.onSelectSection("active")} isSelected={selectedSection === "active"} />
        <Pill label="Today" onClick={() => this.onSelectSection("today")} isSelected={selectedSection === "today"} />
        <Pill label="Tomorrow" onClick={() => this.onSelectSection("tomorrow")} isSelected={selectedSection === "tomorrow"} />
        <Pill label="Pending Payment" onClick={() => this.onSelectSection("pendingpayment")} isSelected={selectedSection === "pendingpayment"} />
        <Pill label="Complete" onClick={() => this.onSelectSection("complete")} isSelected={selectedSection === "complete"} />
        <Pill label="Closed" onClick={() => this.onSelectSection("closed")} isSelected={selectedSection === "closed"} />
        </div> */}

      </div>
    );
  }
}


const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
    // height: 65,
    width: '100%',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 10
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 10
  },
  logo: {
    height: 40,
    marginRight: 8
  },
  left: {
    display: 'flex',
    flex: .3,
    flexDirection: 'row',
    alignItems: 'center',

  },
  middle: {
    display: 'flex',
    // flex: .333,
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    display: 'flex',
    flex: .7,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ordersLabel: {
    fontFamily: 'Montserrat',
    color: colors.lightText,
    lineHeight: 1.2
  },
  menuIconWrapper: {
    padding: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    height: 25,
    width: 25
  },
  link: {
    color: colors.main,
    cursor: 'pointer'
  }
};

const mapStateToProps = state => ({
  config: state.config,
  membershipInfo: state.membershipInfo,
  currentUser: state.currentUser
});
const mapActionsToProps = {
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(OrdersNavbar));