import React, { Component } from 'react';
import colors from '../../colors';
import Loader from '../Loader';

class DashboardSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, desc, loading, right } = this.props;

    const topStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: title ? 8 : 0
    }

    return(
      <div style={styles.card}>
        <div style={topStyle}>
          {title ? <div style={styles.cardTitle}>{title}</div> : null}
          <div style={styles.right}>
            {right}
          </div>
        </div>
        {loading ? 
        <div style={styles.loadingWrapper}>
          <Loader loading small />
        </div>
        :
        <>
          <div style={styles.cardDesc}>{desc}</div>
          <div style={styles.cardItems}>
            {this.props.children}
          </div>
        </>
        }
      </div>
    );
  }
}

const styles = {
  card: {
    padding: 15,
    width: '100%',
    boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
    borderRadius: 4,
    borderLeft: `2px solid ${colors.main}`,
    marginBottom: 25
  },
  cardTitle: {
    fontFamily: 'Montserrat',
    fontSize: 22,
    marginBottom: 7
  },
  cardDesc: {
    color: colors.lightText,
    marginBottom: 10
  },
  cardItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    width: '100%'
  },
};

export default DashboardSquare;