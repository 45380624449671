export default {
  main: '#1890FF',
  border: '#ddd',
  text: '#191919',
  lightText: '#ababab',
  lightBG: '#f7f7f7',
  darkBG: '#ededed',

  green: '#30D158',
  red: '#FF453A',
  yellow: '#FFD60A',
  lightBlue: '#94C8FF',


  boxShadow: `0 0 13px 5px rgba(0,0,0,.06)`
}