import React, { Component } from 'react';
import colors from 'colors';
import { CloseOutlined } from '@ant-design/icons';

class EditableItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    // do animation here
    // this.setState({isClosed: true});

    this.props.onClose();
  }

  render() {
    const { isClosed } = this.state;
    const { header } = this.props;
    return(
      <div style={styles.wrapper} class={isClosed ? 'itemCardClosed' : 'itemCard'}>
        {header ? 
        <div style={styles.header}>
          {header}
        </div>
        :null}
        <div style={styles.closeButton} onClick={this.onClose} className={`closeButton`}>
          <CloseOutlined type='close' />
        </div>
        <div style={styles.body}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const styles = {

  wrapper: {
    marginBottom: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.border,
    borderRadius: 3,
    backgroundColor: colors.darkBG,
    position: 'relative',
    // boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
  },
  closeButton: {
    position: 'absolute',
    top: -7,
    right: -7,
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    // borderColor: colors.border,
    cursor: 'pointer',
  },
  header: {
    backgroundColor: colors.main,
    padding: 10,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3
  },
  body: {
    padding: 10
  }
};

export default EditableItemCard;