import React, { Component } from 'react';
import colors from "colors";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  nav = url => {
    this.props.history.push(url)
  }

  render() {
    const { name, onLogout, logo, navItems } = this.props;
    const logoutMenu = (
      <Menu>
        <Menu.Item>
          <a onClick={onLogout}>Log out</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <div style={styles.wrapper}>
        <div style={styles.logoWrapper}>
          <img
            src={logo}
            style={styles.logo}
          />
        </div>
        <div style={styles.right}>
          {navItems.map(item => {
            return <span style={styles.navItem} onClick={item.url ? () => this.nav(item.url) :  item.onClick}>{item.label}</span>

          })}
          {name && onLogout ? 
            <Dropdown overlay={logoutMenu} >
              <span style={styles.navItem}>{name}<DownOutlined style={{marginLeft: 5}} /></span>
            </Dropdown>
          :null}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    height: 60,
    // marginBottom: 10,
    // backgroundColor: '#f5f5f5',
    width: "100%",
    boxShadow: '0 0 2px 2px rgba(0,0,0,.05)',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    zIndex: 50,
    backgroundColor: '#fff'
  },
  logo: {
    height: 30
  },
  navItem: {
    marginRight: 30,
    cursor: "pointer"
  }
};
export default withRouter(Navbar);