import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

const LoggedInUserRoute = (props) => {
  if(props.loggedInUser.email){
    return(
      <Route {...props} />
    );
  }else{
    return <Redirect to='/login' />
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(LoggedInUserRoute);
