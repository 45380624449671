import React, { Component } from "react";
import TutorialStep from "./TutorialStep";
import { connect } from 'react-redux';
import googleClickDomain from "../../../../assets/img/tutorials/google_click_domain.png";
import googleDnsClick from "../../../../assets/img/tutorials/google_dns_click.png";
import googleDnsConfig from "../../../../assets/img/tutorials/google_dns_config.png";

class GoogleDomainDirections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <TutorialStep
          number="1."
          title="Select Your Domain"
          description={
            <>
              Go to{" "}
              <a href="https://domains.google/" target="_blank">
                Google Domains
              </a>{" "}
              and select "Manage" for your domain.{" "}
            </>
          }
          imgSrc={googleClickDomain}
        />

        <TutorialStep
          number="2."
          title="Find the Configuration"
          description={<>Click "DNS" on the left side, and scroll down to the section "Custom Resource Records"</>}
          imgSrc={googleDnsClick}
        />
        <TutorialStep
          number="3."
          title="Add Your Records"
          description={
            <>
              Enter your records into the "Custom Resource Records". Be sure to scroll up on this page to find your custom records, do NOT actually
              enter 123.123.123.123
            </>
          }
          imgSrc={googleDnsConfig}
        />
        <TutorialStep
          number="4."
          title="Done!"
          description={
            <>
              Domain name registrars can take anywhere from 5 minutes to 48 hours to update your settings. Check back later, and your website should
              be showing on your domain name! If your website is still not showing after 48 hours, you can contact support@{this.props.config.data.supportEmailDomain}.com for help.
            </>
          }
        />
      </div>
    );
  }
}

const styles = {};

const mapStateToProps = state => ({
  config: state.config
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(GoogleDomainDirections);

