import React, { Component } from "react";
import colors from "../../../../colors";

class TutorialStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, number, description, imgSrc, wrapperStyle = {} } = this.props;
    return (
      <div style={{ ...styles.wrapper, ...wrapperStyle }}>
        {title ?
          <div style={styles.title}>
            {number ? <span style={styles.number}>{number}</span> : null}
            {title}
          </div>
        : null}
        {description ? <div style={styles.description}>{description}</div> : null}
        {imgSrc ? <img src={imgSrc} style={styles.image} /> : null}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    marginTop: 15,
    marginBottom: 25,
    maxWidth: 600,
    border: '1px solid '+colors.border,
    borderRadius: 3,
    padding: 15
  },
  title: {
    fontSize: 20,
    // fontWeight: 'bold'
  },
  number: {
    color: colors.main,
    marginRight: 7
  },
  description: {

  },
  image: {
    marginTop: 10,
    borderRadius: 3,
    width: '100%',
    boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',

  }
};

export default TutorialStep;
