import * as firebase from 'firebase';
import { updateLoggedInUser, updateCurrentUser, updateSiteData, updateTemplateData } from '../redux/actions';
import store from '../redux/store';
import { loadMyMedia } from './media';

var firebaseConfig = {
  apiKey: "AIzaSyCa4A2dQreIYWLRpGZOvM15ateC0QtUIsw",
  authDomain: "mvptester-84a0c.firebaseapp.com",
  databaseURL: "https://mvptester-84a0c.firebaseio.com",
  projectId: "mvptester-84a0c",
  storageBucket: "mvptester-84a0c.appspot.com",
  messagingSenderId: "197362179316",
  appId: "1:197362179316:web:2c98f63de6697b90eed2a1",
  measurementId: "G-4ZJ5KPQ15L"
};

firebase.initializeApp(firebaseConfig);
let db = firebase.firestore();

// handle user login or logout, or things that need to happen on load based on if there is a logged in user
firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    let linkedUser = await db.collection('users').doc(user.uid).get();
    if(linkedUser.exists){
      store.dispatch(updateCurrentUser({
        ...linkedUser.data(),
        id: linkedUser.id
      }))
    }else{
      // this should only trigger once, when they just signed up <1 second ago. lets create their user doc
      let userData = {
        email: user.email,
        id: user.uid,
        created: Date.now(),
        builderDomain: store.getState().config.data.siteNameValue
      };
      await db.collection('users').doc(user.uid).set(userData);
      store.dispatch(updateCurrentUser(userData));
    }

    store.dispatch(updateLoggedInUser({
      email: user.email,
      id: user.uid,
      loading: false,
      loggedIn: true
    }))

    // ---- do stuff that requires logged in user here ----
    loadMyMedia()

  } else {
    // no one is signed in
    store.dispatch(updateLoggedInUser({loading: false, loggedIn: false}))
    store.dispatch(updateCurrentUser({loading: false}))
  }
});

export {
  db,
  firebase,
};
