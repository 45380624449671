import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "redux/actions";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import DashboardSidenav from "../../components/DashboardSidenav";

//tabs
import DomainTab from "../dashboardTabs/DomainTab";
// import AnalyticsTab from "../dashboardTabs/AnalyticsTab";
import SupportTab from "../dashboardTabs/SupportTab";
import MediaTab from "../dashboardTabs/MediaTab";
import SettingsTab from "../dashboardTabs/SettingsTab";
import EmailsTab from "../dashboardTabs/EmailsTab";
import GeneralTab from "../dashboardTabs/GeneralTab";
import PageContentContainer from "../../components/containers/PageContentContainer";
import colors from "../../colors";
import { isMobile } from "react-device-detect";
import SplashMobileWarning from "../../components/SplashMobileWarning";

class SiteDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: 'general'
    };
  }

  onSelectSection = section => {
    // console.log(section)
    this.setState({selectedSection: section})
  }

  renderSection = () => {
    switch(this.state.selectedSection){
      case 'domain': return <DomainTab />;
      case 'media': return <MediaTab />;
      // case 'analytics': return <AnalyticsTab />;
      case 'emails': return <EmailsTab />;
      case 'support': return <SupportTab />;
      case 'settings': return <SettingsTab history={this.props.history} />;
      case 'general': return <GeneralTab />;
    }
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <DashboardNavbar onSelectSection={this.onSelectSection} selectedSection={this.state.selectedSection}/>
        <div style={styles.bodyRow}>
          {/* <DashboardSidenav onSelectNavItem={this.onSelectNavSection} selected={this.state.selectedNavSection} /> */}
              {this.renderSection()}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: colors.lightBG
  },
  bodyRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    position: "relative",
    // height: '100%',
    overflow: 'hidden',
  },
};

const mapStateToProps = state => ({});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(SiteDashboardPage);
