import dayjs from 'dayjs';
import React, { Component } from 'react';
import { db } from '../../../firebase';
import queryString from 'query-string';
import { setMisc } from '../../../redux/actions/dispatchers';

const defaultFilterState = {
  daterange: []
};

class OrderDashboardClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      active: [],
      today: [],
      tomorrow: [],
      scheduled: [],
      unscheduled: [],
      pendingPayment: [],
      complete: [],
      activeTabKey: 'quotes',
      dateType: 'any',
      viewType: 'list',
      filterState: defaultFilterState,
      quotesLoading: true,
      scheduledLoading: true,
      unscheduledLoading: true,
      pendingPaymentLoading: true, 
      completeLoading: true,
      canceledLoading: true,
      numberOfNewQuotes: 0

    };

    this.loadersMapping = {
      quotes: this.loadQuotes,
      scheduled: this.loadActive,
      unscheduled: this.loadActive,
      today: this.loadToday,
      tomorrow: this.loadTomorrow,
      pendingPayment: this.loadPendingPayment,
      complete: this.loadComplete,
      canceled: this.loadCanceled,
    }
  }

  componentDidMount(){
    this.updateTabFromUrl();
    this.loadQuotes();
    this.quotesInterval = setInterval(() => this.loadQuotes(), 1000*30);
  }

  componentWillUnmount = () => {
    if(this.quotesInterval) clearInterval(this.quotesInterval);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.search !== this.props.location.search){
      this.updateTabFromUrl();
    }
  }

  updateTabFromUrl = () => {
    const queryItems = queryString.parse(this.props.location.search) || {};
    if(queryItems.tab) this.setState({activeTabKey: queryItems.tab}, () => {
      this.loadersMapping[this.state.activeTabKey]();
    })
    if(queryItems.datetype){
      this.setState({dateType: queryItems.datetype})
    }else{
      this.setState({dateType: 'any'})
    }
  }


  loadQuotes = () => {
    this.setState({quotesLoading: true})
    db.collection('orders')
    .where('stage', '==', 'quote')
    .where('siteId', '==', this.props.siteData.id)
    .where('userId', '==', this.props.loggedInUser.id)
    .get().then(res => {
      let orders = [];
      let numberOfNewQuotes = 0;
      res.forEach(doc => {
        let data = doc.data();
        orders.push(data);
        if(!data.hasBeenViewed) numberOfNewQuotes++;
      })
      this.setState({
        quotes: orders,
        quotesLoading: false,
        numberOfNewQuotes
      });
      setMisc('numberOfNewQuotes', numberOfNewQuotes)
    })
  }

  loadActive = () => {
    this.setState({scheduledLoading: true, unscheduledLoading: true});
    db.collection('orders')
    .where('stage', '==', 'active')
    .where('siteId', '==', this.props.siteData.id)
    .where('userId', '==', this.props.loggedInUser.id)
    .get().then(res => {
      let scheduled = []
      let unscheduled = []
      res.forEach(doc => {
        let data = doc.data();
        if(data.scheduledFor){
          scheduled.push(data); 
        }else{
          unscheduled.push(data); 
        }
      })
      this.setState({scheduled, unscheduled, scheduledLoading: false, unscheduledLoading: false});
    })
  }


  // loadTomorrow = () => {
  //   let startOfDay = dayjs().add(1, 'day').startOf('day').toDate().getTime();
  //   let endOfDay = dayjs().add(1, 'day').endOf('day').toDate().getTime();


  //   db.collection('orders')
  //   .where('stage', '==', 'scheduled')
  //   .where('siteId', '==', this.props.siteData.id)
  //   .where('userId', '==', this.props.loggedInUser.id)
  //   .where('scheduledFor', '>', startOfDay)
  //   .where('scheduledFor', '<', endOfDay)
  //   .get().then(res => {
  //     let orders = []
  //     res.forEach(doc => {
  //       let data = doc.data();
  //       orders.push(data);
  //     })
  //     this.setState({tomorrow: orders, loadingTomorrow: false});
  //   })
  // }

  loadPendingPayment = () => {
    this.setState({pendingPaymentLoading: true})
    db.collection('orders')
    .where('stage', '==', 'pendingPayment')
    .where('siteId', '==', this.props.siteData.id)
    .where('userId', '==', this.props.loggedInUser.id)
    .get().then(res => {
      let orders = []
      res.forEach(doc => {
        let data = doc.data();
        orders.push(data);
      })
      this.setState({pendingPayment: orders, pendingPaymentLoading: false});
    })
  }

  loadComplete = () => {
    this.setState({completeLoading: true})
    db.collection('orders')
    .where('stage', '==', 'complete')
    .where('siteId', '==', this.props.siteData.id)
    .where('userId', '==', this.props.loggedInUser.id)
    .get().then(res => {
      let orders = []
      res.forEach(doc => {
        let data = doc.data();
        orders.push(data);
      })
      this.setState({complete: orders, completeLoading: false});
    })
  }

  loadCanceled = () => {
    this.setState({canceledLoading: true})
    db.collection('orders')
    .where('stage', '==', 'canceled')
    .where('siteId', '==', this.props.siteData.id)
    .where('userId', '==', this.props.loggedInUser.id)
    .get().then(res => {
      let orders = []
      res.forEach(doc => {
        let data = doc.data();
        orders.push(data);
      })
      this.setState({canceled: orders, canceledLoading: false});
    })
  }

  onClickOrder = order => {
    this.props.history.push(`/site/${this.props.siteData.id}/order/${order.id}`)
  }

  onChangeDateType = data => {
    this.setState({dateType: data.target.value})
    if(data.target.value === 'today'){
      this.setState({
        filterState: {
          ...this.state.filterState,
          dateFilter: new Date().getTime()
        }
      })
    }
    if(data.target.value === 'tomorrow'){
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.setState({
        filterState: {
          ...this.state.filterState,
          dateFilter: tomorrow.getTime()
        }
      })
    }
  }

  onChangeTab = (name) => {
    this.setState({activeTabKey: name, filterState: defaultFilterState, dateType: null}, () => {
      this.loadersMapping[name]();
    });
  }

  onChangeViewType = type => {
    this.setState({viewType: type.target.value})
  }


  onChangeFilterStateItem = (key, val) => {
    let newState = {
      filterState: {
        ...this.state.filterState,
        [key]: val
      },
    }
    if(key === 'daterange') newState.dateType = 'daterange'
    if(key === 'dateFilter') newState.dateType = 'singledate';
    this.setState(newState)
  }

  getFilteredOrders = () => {
    let orders = this.state[this.state.activeTabKey] || [];
    const { dateType, searchText } = this.state;
    const { daterange, dateFilter, today, tomorrow, thisweek } = this.state.filterState;

    if(dateType === 'daterange' && daterange[0] && daterange[1]){
      let startDay = dayjs(new Date(daterange[0])).startOf('day').$d;
      let endDay = dayjs(new Date(daterange[1])).endOf('day').$d;
      orders = orders.filter(o => {
        if(!o.scheduledFor) return false;
        let orderDay = dayjs(new Date(o.scheduledFor)).startOf('day').$d;
        return(orderDay >= startDay && orderDay <= endDay);
      })
    }

    if(dateType === 'singledate'){
      let startDay = dayjs(new Date(dateFilter)).startOf('day').$d;
      orders = orders.filter(o => {
        let orderDay = dayjs(new Date(o.scheduledFor)).startOf('day').$d;
        return(orderDay.getTime() === startDay.getTime())
      })
    }

    if(dateType === 'today'){
      let today = dayjs(new Date()).startOf('day').$d;
      orders = orders.filter(o => {
        let orderDay = dayjs(new Date(o.scheduledFor)).startOf('day').$d;
        return(orderDay.getTime() === today.getTime())
      })
    }

    if(dateType === 'tomorrow'){
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      orders = orders.filter(o => {
        let orderDay = dayjs(new Date(o.scheduledFor)).startOf('day').$d;
        return(orderDay.getTime() === tomorrow.getTime())
      })
    }

    if(dateType === 'thisweek'){
      // const tomorrow = new Date();
      // tomorrow.setDate(tomorrow.getDate() + 1);
      // orders = orders.filter(o => {
      //   let orderDay = dayjs(new Date(o.scheduledFor)).startOf('day').$d;
      //   return(orderDay.getTime() === tomorrow.getTime())
      // })
    }

    if(searchText){
      orders = orders.filter(o => {
        if(
          (o.customerName || '').toLowerCase().includes(this.state.searchText.toLowerCase()) || 
          ((o.customerAddress || {}).formatted_address || '').toLowerCase().includes(this.state.searchText.toLowerCase()) 
        ){
          return true
        }else{
          return false
        }
      })
    }

    orders = orders.sort((a, b) => {
      return(a.scheduledFor > b.scheduledFor ? 1 : -1)
    })

    return orders;
  }



}

const styles = {

};

export default OrderDashboardClass;