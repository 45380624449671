import Dinero from 'dinero.js'
Dinero.defaultCurrency='USD';

export default function parsePrice(str){
  let s = 0;
  if(typeof str === 'string'){
    s = str;
    s = s.replace('$', '');
    s = s.trim();
    s = parseInt(s);
  }
  if(typeof str === 'number'){
    s = str;
  }

  if(isNaN(s)) s = 0;
  s = s*100;
  s = Math.round(s);
  return Dinero({amount: s});
}